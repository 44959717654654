import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { newTheme } from "../../../styled/theme";
import ThumbnailViewport from "../../../renderer/thumbnail/thumbnail-viewport";
import { useActiveView, useAddZoom, useChangeStep, useIsAutomatedScreenByPageId, usePushHistory, useScreen, useTourialMedia, } from "../../../../redux/selectors/redux-selectors";
import { getDataForThumbnail } from "./storyboard-functions";
import { useDeletePage, useDeleteZoom, useDeselect, useDuplicatePage, useDuplicateView, useSetPageNameById, } from "../../../../redux/selectors/builder-selectors";
import HotdogMenuIcon from "../../../../../assets/images/v3/vertical-dots.svg";
import PlusIconBlue from "../../../../../assets/images/v3/plus-sign.svg";
import PlusIconGrey from "../../../../../assets/images/v3/add-step-plus-grey.svg";
import InteractiveIcon from "../../../../../assets/images/v3/interactive-screen.svg";
import AutomatedIcon from "../../../../../assets/images/v3/automated-screen.svg";
import DuplicateScreenIcon from "../../../../../assets/images/v3/dropdown-duplicate-screen.svg";
import ChangeScreenIcon from "../../../../../assets/images/v3/dropdown-change-screen.svg";
import DeleteScreenIcon from "../../../../../assets/images/v3/dropdown-delete-screen.svg";
import EditIcon from "../../../../../assets/images/v3/pencil.svg";
import { DropdownContainer, DropdownOption } from "../../home-page/tourial-list";
import { Popover2 } from "@blueprintjs/popover2";
import { useOutsideClick } from "../../../../hooks/use-outside-click";
import { StoryboardPageNameInput } from "./storyboard-page-name-input";
import { Draggable } from "react-beautiful-dnd";
import { ActiveStepButton, AddStepButton, InactiveStepButton } from "./storyboard-mini-step-buttons";
import { StoryboardMiniStepContextMenu } from "./storyboard-mini-step-context-menu";
export function StoryboardMiniScreenGroup({ page, isSelected, setIsSwapScreenModalOpen, draggingId, editorPanelWidth, index, }) {
    const elementId = `screen-group-${index}`;
    const dropdownRef = useRef();
    const screen = useScreen();
    const media = useTourialMedia();
    const tourialData = getDataForThumbnail(screen, page.id, media);
    const changeStep = useChangeStep();
    const deselect = useDeselect();
    const pushHistory = usePushHistory();
    const addZoom = useAddZoom();
    const activeView = useActiveView();
    const duplicatePage = useDuplicatePage();
    const deletePage = useDeletePage();
    const [stepIndex, setStepIndex] = useState(0);
    const [addedStep, setAddedStep] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [pageName, setPageName] = useState(page.name);
    const setTourialPageName = useSetPageNameById(page.id);
    const [isTitleHovered, setIsTitleHovered] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [isAddStepButtonHovered, setIsAddStepButtonHovered] = useState(false);
    const duplicateStep = useDuplicateView();
    const deleteStep = useDeleteZoom();
    const isAutomated = useIsAutomatedScreenByPageId(page.id);
    const isDragging = draggingId === page.id;
    function savePageName(name) {
        setPageName(name);
        setTourialPageName(name);
    }
    // select added step after adding a new step
    useEffect(() => {
        if (addedStep) {
            setStepIndex(page.zooms.length - 1);
            changeStep({ pageId: page.id, zoomId: page.zooms[page.zooms.length - 1].id });
        }
    }, [page.zooms.length]);
    useEffect(() => {
        if (page) {
            setPageName(page.name);
        }
    }, [page.name]);
    // update step index whenever active view changes
    useEffect(() => {
        setStepIndex(page.zooms.findIndex(z => z.id === activeView.zoomId) || 0);
    }, [activeView]);
    useOutsideClick([dropdownRef], () => {
        setIsDropdownOpen(false);
    });
    useEffect(() => {
        if (isSelected) {
            const element = document.getElementById(elementId);
            if (element) {
                //if element is off screen
                if (element.getBoundingClientRect().left < 0 ||
                    element.getBoundingClientRect().right > window.innerWidth - editorPanelWidth) {
                    element.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
                }
            }
        }
    }, [isSelected]);
    const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
    const [contextMenuParams, setContextMenuParams] = useState({
        id: null,
        x: null,
        y: null,
        onDelete: () => { },
        onDuplicate: () => { },
    });
    const openContextMenu = ({ id, x, y, onDelete, onDuplicate }) => {
        setIsContextMenuOpen(true);
        setContextMenuParams({ id, x, y, onDelete, onDuplicate });
    };
    return (React.createElement(Draggable, { draggableId: page.id, index: index, key: page.id }, provided => (React.createElement(Container, Object.assign({}, provided.draggableProps, provided.dragHandleProps, { ref: provided.innerRef, id: elementId, isSelected: isSelected, isAutomated: isAutomated, isHovered: isHovered, isDragging: isDragging, onMouseEnter: () => {
            setIsHovered(true);
        }, onMouseLeave: () => {
            setIsHovered(false);
        }, onMouseDown: () => {
            setIsDropdownOpen(false);
        }, onClick: () => {
            const nextView = {
                pageId: page.id,
                zoomId: page.zooms[0].id,
            };
            deselect();
            changeStep(nextView);
            setStepIndex(0);
        } }),
        React.createElement(TitleContainer, { isDragging: isDragging, isSelected: isSelected, isHovered: isHovered, isAutomated: isAutomated, onMouseEnter: () => {
                setIsTitleHovered(true);
            }, onMouseLeave: () => {
                if (isSelected)
                    setIsTitleHovered(false);
            }, onClick: e => {
                if (isSelected) {
                    const el = document.querySelector("#storyboard-page-name-input");
                    el.focus();
                    el.setSelectionRange(0, pageName.length);
                    e.stopPropagation();
                }
            } },
            React.createElement(StoryboardPageNameInput, { id: "storyboard-page-name-input", name: pageName, setName: savePageName, disabled: !isSelected }),
            isSelected && isTitleHovered && React.createElement("img", { src: EditIcon, width: 18 })),
        React.createElement(RowContainer, null,
            React.createElement(ThumbnailContainer, { isSelected: isSelected, isDragging: isDragging },
                React.createElement(ThumbnailViewport, { tourialData: Object.assign(Object.assign({}, tourialData), { firstPageData: Object.assign(Object.assign({}, tourialData.firstPageData), { zoom: page.zooms[0] }) }), isLink: false, scale: 0.42, style: {
                        margin: 0,
                        transformOrigin: "left top",
                        borderRadius: 0,
                    } }),
                React.createElement(BadgeContainer, { isSelected: isSelected },
                    React.createElement("img", { src: isAutomated ? AutomatedIcon : InteractiveIcon, width: 16 }),
                    ((isHovered && !isSelected) || isDragging) && (React.createElement(StepCountBadge, null, `${page.zooms.length} ${page.zooms.length > 1 ? "steps" : "step"}`)))),
            isSelected &&
                page.zooms.map((z, i) => {
                    if (stepIndex === i)
                        return (React.createElement(ActiveStepButton, { key: i, isAutomated: isAutomated, onClick: e => {
                                e.stopPropagation();
                            }, onContextMenu: e => {
                                e.stopPropagation();
                                e.preventDefault();
                                openContextMenu({
                                    id: z.id,
                                    x: e.pageX,
                                    y: e.pageY,
                                    onDelete: makeOnDelete(page.zooms.length, page.id, z.id),
                                    onDuplicate: () => {
                                        pushHistory();
                                        duplicateStep(page.id, z.id);
                                    },
                                });
                            } }, i + 1));
                    return (React.createElement(InactiveStepButton, { key: i, onContextMenu: e => {
                            e.stopPropagation();
                            e.preventDefault();
                            openContextMenu({
                                id: z.id,
                                x: e.pageX,
                                y: e.pageY,
                                onDelete: makeOnDelete(page.zooms.length, page.id, z.id),
                                onDuplicate: () => {
                                    pushHistory();
                                    duplicateStep(page.id, z.id);
                                },
                            });
                        }, onClick: e => {
                            e.stopPropagation();
                            deselect();
                            pushHistory();
                            changeStep({ pageId: page.id, zoomId: z.id });
                            setStepIndex(i);
                        } }, i + 1));
                }),
            isSelected && (React.createElement(AddStepButton, { onClick: e => {
                    e.stopPropagation();
                    deselect();
                    pushHistory();
                    addZoom(page);
                    setAddedStep(true);
                }, onMouseEnter: () => setIsAddStepButtonHovered(true), onMouseLeave: () => setIsAddStepButtonHovered(false) },
                React.createElement("img", { src: isAddStepButtonHovered ? PlusIconBlue : PlusIconGrey }))),
            isSelected && (React.createElement(HotdogButton, { ref: dropdownRef, onClick: e => {
                    e.stopPropagation();
                    setIsDropdownOpen(!isDropdownOpen);
                } },
                React.createElement(Popover2, { minimal: true, isOpen: isDropdownOpen, position: "bottom-right", popoverClassName: "tourial-card-dropdown", content: React.createElement(DropdownContainer, { className: "_test_tourial-card-dropdown" },
                        React.createElement(Option, { onClick: e => {
                                e.stopPropagation();
                                setIsDropdownOpen(false);
                                setIsSwapScreenModalOpen(true);
                                deselect();
                            } },
                            React.createElement(OptionIcon, { src: ChangeScreenIcon }),
                            " ",
                            "Change screen"),
                        React.createElement(Option, { onClick: e => {
                                e.stopPropagation();
                                setIsDropdownOpen(false);
                                pushHistory();
                                duplicatePage(page.id);
                            } },
                            React.createElement(OptionIcon, { src: DuplicateScreenIcon }),
                            " ",
                            "Duplicate screen"),
                        React.createElement(Option, { onClick: e => {
                                e.stopPropagation();
                                setIsDropdownOpen(false);
                                pushHistory();
                                deletePage(page.id);
                            } },
                            React.createElement(OptionIcon, { src: DeleteScreenIcon }),
                            " ",
                            "Delete screen")) },
                    React.createElement("img", { src: HotdogMenuIcon }))))),
        isContextMenuOpen && (React.createElement(StoryboardMiniStepContextMenu, { onClose: () => setIsContextMenuOpen(false), params: contextMenuParams }))))));
    function makeOnDelete(zoomCount, pageId, zoomId) {
        if (zoomCount > 1) {
            if (zoomId !== "MAIN") {
                return () => {
                    deselect();
                    pushHistory();
                    deleteStep(zoomId);
                };
            }
            else {
                return null;
            }
        }
        else {
            return () => {
                deselect();
                pushHistory();
                deletePage(pageId);
            };
        }
    }
}
const Container = styled.div `
  display: flex;
  flex-direction: column;
  height: 96px;
  justify-content: space-around;
  cursor: pointer;
  padding: 4px;
  border-radius: 4px;
  gap: 4px;
  background-color: ${({ isSelected, isHovered, isAutomated }) => {
    if (isSelected) {
        if (isAutomated)
            return newTheme.colors.yellow200;
        return newTheme.colors.storyboardSelectBlue;
    }
    else if (isHovered) {
        return newTheme.colors.grey200;
    }
    else {
        return newTheme.colors.white;
    }
}};
  transition: width ${newTheme.transitions.layoutShift};
  box-shadow: ${({ isHovered, isDragging }) => {
    if (isHovered) {
        return "box-shadow: inset 0px 0px 1px rgba(33, 34, 41, 0.4)";
    }
    else if (isDragging) {
        return "box-shadow: 2px 2px 10px rgba(33, 34, 41, 0.05), inset 0px 0px 1px rgba(33, 34, 41, 0.4);";
    }
    else {
        return "none";
    }
}};
`;
const TitleContainer = styled.div `
  cursor: pointer;
  display: flex;
  font: ${newTheme.fonts.small};
  text-align: center;
  align-items: center;
  justify-content: ${({ isSelected }) => (isSelected ? "space-between" : "left")};
  padding-left: 8px;
  height: 20px;
  width: 100%;
  border-radius: 4px;
  border: ${({ isSelected, isDragging, isAutomated }) => {
    if (isDragging || isSelected) {
        if (isAutomated)
            return `1px solid ${newTheme.colors.yellow200}`;
        return `1px solid ${newTheme.colors.storyboardSelectBlue}`;
    }
    else {
        return `1px solid ${newTheme.colors.grey300}`;
    }
}};
  background-color: ${({ isSelected, isHovered, isDragging }) => {
    if (isSelected || isHovered || isDragging) {
        return newTheme.colors.white;
    }
    else {
        return newTheme.colors.grey100;
    }
}};
  overflow: hidden;
`;
const ThumbnailContainer = styled.div `
  width: 114px;
  height: 64px;
  border-top-right-radius: ${({ isSelected }) => (isSelected ? "0" : `4px`)};
  border-bottom-right-radius: ${({ isSelected }) => (isSelected ? "0" : `4px`)};
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border: ${({ isSelected, isDragging }) => {
    if (isDragging) {
        return `1px solid ${newTheme.colors.storyboardSelectBlue}`;
    }
    else if (isSelected) {
        return "none";
    }
    else {
        return `1px solid ${newTheme.colors.grey300}`;
    }
}};
  overflow: hidden;
  ${({ isSelected }) => (isSelected ? `border-right: 1px solid ${newTheme.colors.grey300}` : "")};
`;
const RowContainer = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${newTheme.colors.grey100};
  border-radius: 4px;
  gap: 16px;
`;
const HotdogButton = styled.div `
  height: 100%;
  width: 24px;
  border-left: 1px solid ${newTheme.colors.grey300};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  &:hover {
    background-color: ${newTheme.colors.grey200};
  }
`;
export const Option = styled(DropdownOption) ``;
export const OptionIcon = styled.img `
  margin: 8px 8px 8px 16px;
  display: inline;
`;
const StepCountBadge = styled.div `
  border-radius: 4px;
  border: 1px solid ${newTheme.colors.grey300};
  background: ${newTheme.colors.grey100};
  font: ${newTheme.fonts.small};
  padding: 2px;
  width: fit-content;
`;
const BadgeContainer = styled.div `
  display: flex;
  gap: 4px;
  position: relative;
  left: 4px;
  bottom: ${({ isSelected }) => (isSelected ? "112px" : "115px")};
  z-index: 2;
  height: 20px;
`;
