import { TooltipButtonStyle, } from "../../../../../../../shared/types/tool-data-types/tooltip-v2-data";
import React from "react";
import { BubbleButtonIconContainer, bubbleButtonBorderRadii, bubbleButtonSidePadding } from "./bubble-styled";
export function BubbleButtonLeft({ body, button, onClick, onDoubleClick }) {
    return (React.createElement("button", { style: {
            fontFamily: body.font,
            color: button.textColor,
            backgroundColor: button.backgroundColor,
            borderColor: button.borderColor,
            borderRadius: bubbleButtonBorderRadii[button.shape],
            paddingRight: button.style === TooltipButtonStyle.ARROWS ? 6 : 12,
            paddingLeft: bubbleButtonSidePadding[button.style],
        }, onClick: onClick, onDoubleClick: onDoubleClick },
        (button.style === TooltipButtonStyle.ARROWS || button.style === TooltipButtonStyle.TEXT_AND_ARROWS) && (React.createElement(LeftChevronIcon, { style: { marginRight: button.style === TooltipButtonStyle.TEXT_AND_ARROWS ? 2 : 0 } })),
        (button.style === TooltipButtonStyle.TEXT || button.style === TooltipButtonStyle.TEXT_AND_ARROWS) && button.text));
}
const LeftChevronIcon = ({ style }) => {
    return (React.createElement(BubbleButtonIconContainer, { style: style },
        React.createElement("svg", { width: "6", height: "9", viewBox: "0 0 6 9", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M5.16569 0.661805C5.47811 0.974224 5.47811 1.48076 5.16569 1.79318L2.53138 4.42749L5.16569 7.06181C5.47811 7.37422 5.47811 7.88076 5.16569 8.19318C4.85327 8.5056 4.34674 8.5056 4.03432 8.19318L0.834321 4.99318C0.521901 4.68076 0.521901 4.17422 0.834321 3.8618L4.03432 0.661805C4.34674 0.349385 4.85327 0.349385 5.16569 0.661805Z", fill: "currentColor" }))));
};
