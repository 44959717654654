import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { DCControlStrip } from "../components/dc-control-strip";
import { useDCConfig, useDCStyles, useDCTheme } from "../hooks/dc-hooks";
import { useDCGoToNextItem, useDCGoToNextPage, useDCGoToPreviousItem, useDCGoToPreviousPage, } from "../hooks/dc-navigation-hooks";
import { useDCCurrentItem, useDCFirstItemInPlaylist, useDCGetNextItem, useDCItemHasBeenViewed, useDCLastItemInPlaylist, } from "../hooks/dc-item-hooks";
import { useDCGetNextModule, useDCGetTrackSelectionsForModule, useDCModule, useDCModules, } from "../hooks/dc-module-hooks";
import { useDCClickEvent } from "../hooks/dc-event-hooks";
import { DCButtonAction, DCItemType, DCModuleType } from "../../../../../../shared/types/demo-center";
import { useDCActiveItemHasBeenCompleted, useDCActiveItemSubNavigation, useDCActiveModuleSlug, } from "../../../../redux/selectors/demo-center-selectors";
export const DCControls = () => {
    var _a, _b, _c;
    const [itemWillAutoAdvance, setItemWillAutoAdvance] = useState(false);
    const { enabled, currentPage, totalPages } = useDCActiveItemSubNavigation();
    const { controls, autoAdvance } = useDCConfig();
    const theme = useDCTheme();
    const styles = useDCStyles();
    const modules = useDCModules();
    const getNextItem = useDCGetNextItem();
    const currentItem = useDCCurrentItem();
    const activeModuleSlug = useDCActiveModuleSlug();
    const activeModule = useDCModule(activeModuleSlug);
    const getNextModule = useDCGetNextModule();
    const nextItem = getNextItem();
    const nextModule = getNextModule();
    const goToNextItem = useDCGoToNextItem();
    const goToPreviousItem = useDCGoToPreviousItem();
    const goToNextPage = useDCGoToNextPage();
    const goToPreviousPage = useDCGoToPreviousPage();
    const clickEvent = useDCClickEvent();
    const itemHasBeenCompleted = useDCActiveItemHasBeenCompleted();
    const itemHasBeenViewed = useDCItemHasBeenViewed(currentItem === null || currentItem === void 0 ? void 0 : currentItem.id);
    const getTrackSelections = useDCGetTrackSelectionsForModule();
    const hasTracksSelected = ((_a = getTrackSelections(activeModuleSlug)) === null || _a === void 0 ? void 0 : _a.length) > 0;
    const isNoPrompt = (activeModule === null || activeModule === void 0 ? void 0 : activeModule.type) === DCModuleType.NO_PROMPT;
    const firstItem = useDCFirstItemInPlaylist();
    const lastItem = useDCLastItemInPlaylist();
    const subNavigation = useDCActiveItemSubNavigation();
    const isOnFirstModule = (activeModule === null || activeModule === void 0 ? void 0 : activeModule.slug) === ((_b = modules[0]) === null || _b === void 0 ? void 0 : _b.slug);
    const isOnLastModule = (activeModule === null || activeModule === void 0 ? void 0 : activeModule.slug) === ((_c = modules[modules.length - 1]) === null || _c === void 0 ? void 0 : _c.slug);
    const isOnFirstItem = (currentItem === null || currentItem === void 0 ? void 0 : currentItem.id) === (firstItem === null || firstItem === void 0 ? void 0 : firstItem.id);
    const isOnLastItem = (currentItem === null || currentItem === void 0 ? void 0 : currentItem.id) === (lastItem === null || lastItem === void 0 ? void 0 : lastItem.id);
    const previousIsDisabled = (isOnFirstItem && isNoPrompt && !subNavigation.enabled) || (isOnFirstModule && !currentItem);
    const nextIsDisabled = (subNavigation.enabled && subNavigation.currentPage >= subNavigation.totalPages) ||
        (!hasTracksSelected && !isNoPrompt) ||
        (isOnLastItem && isOnLastModule && !subNavigation.enabled);
    const shouldHighlightNextButton = itemHasBeenViewed && [DCItemType.IMAGE, DCItemType.PDF, DCItemType.HTML].includes(currentItem === null || currentItem === void 0 ? void 0 : currentItem.type);
    useEffect(() => {
        let timer = 5;
        let interval;
        const reset = () => {
            window.clearInterval(interval);
            setItemWillAutoAdvance(false);
        };
        if (itemHasBeenCompleted && autoAdvance) {
            setItemWillAutoAdvance(true);
            interval = window.setInterval(() => {
                timer--;
                if (timer === 0) {
                    goToNextItem();
                    reset();
                }
            }, 1000);
        }
        return () => reset();
    }, [itemHasBeenCompleted, autoAdvance]);
    const previous = () => {
        clickEvent({ action: DCButtonAction.PREVIOUS });
        if (enabled && currentPage > 1) {
            goToPreviousPage();
        }
        else {
            goToPreviousItem();
        }
    };
    const next = () => {
        clickEvent({ action: DCButtonAction.NEXT });
        if (enabled && currentPage < totalPages) {
            goToNextPage();
        }
        else {
            goToNextItem();
        }
    };
    return (React.createElement(ControlsContainer, null,
        React.createElement(DCControlStrip, { config: controls, theme: theme, styles: styles, currentItem: currentItem, nextItem: nextItem, nextModule: nextModule, onPrevious: !previousIsDisabled ? previous : null, onNext: !nextIsDisabled ? next : null, showCountdownTimer: itemWillAutoAdvance, highlightNextButton: shouldHighlightNextButton })));
};
const ControlsContainer = styled.div `
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 24px;
`;
