import React, { useEffect, useState } from "react";
import { useAutoUpdateTool, useSetBuilderFocusField, useUpdateTool } from "../../../../redux/selectors/redux-selectors";
import { BorderShape } from "../../../../../../shared/types/tool-data-types/common";
import styled from "styled-components";
import { WrapperChild } from "../../../renderer/wrapper/wrapper-child";
import { BubbleHeadlineText } from "../../tooltip-v2/view/subtool-components/bubble-headline-text";
import { TourialEditorFocusFields } from "../../../../types/definitions";
import { BubbleBodyText } from "../../tooltip-v2/view/subtool-components/bubble-body-text";
import { arrowSizes } from "../../tooltip-v2/view/subtool-components/bubble-arrow";
import { cloneDeep } from "lodash";
import { arrowEdgePaddingPx, getArrowOffsetPx, positionEq, pxToPercent, } from "../../../../helpers/tooltip-v2-size-and-position-helpers";
import { TooltipArrowPosition } from "../../../../../../shared/types/tool-data-types/tooltip-v2-data";
import { withFlexibleToolWrapper } from "../../../renderer/wrapper/with-flexible-tool-wrapper";
import { newTheme } from "../../../styled/theme";
import { useSelectedToolId } from "../../../../redux/selectors/builder-selectors";
import { textAlignments } from "../../../../helpers/text-helpers";
export function AutoTooltip(props) {
    const { toolData: tool, viewportDimensions } = props;
    const updateTool = useUpdateTool();
    const [bubblePosition, setBubblePosition] = useState(getAutoBubblePosition(tool, viewportDimensions));
    function drag(position) {
        if (!positionEq(bubblePosition, position)) {
            const t = cloneDeep(tool);
            setBubblePosition(position);
            t.position = getAutoTriggerPosition(t, viewportDimensions, position);
            updateTool(t);
        }
    }
    return withFlexibleToolWrapper(Inner, {
        isEditing: props.isInEditViewport,
        isSelectable: true,
        isResizable: false,
        isDraggable: true,
        isAspectRatioLocked: false,
        isStaticSize: true,
        shouldShowSelectionTab: true,
    }, {
        bounds: ".tourial-viewport",
        size: tool.bubble.size,
        position: bubblePosition,
    }, {
        onDrag: drag,
        onDragStop: drag,
        onResize: () => { },
        onResizeStop: () => { },
    })(Object.assign(Object.assign({}, props), { bubblePosition, setBubblePosition }));
}
function Inner(props) {
    const { toolData: tool, viewportDimensions, bubblePosition, setBubblePosition, index } = props;
    const { arrow, headline, body, background, logo } = tool.bubble;
    const setBuilderFocusField = useSetBuilderFocusField();
    const selectedToolId = useSelectedToolId();
    const id = `${tool.id}-auto-tooltip`;
    const [hasBubbleInitiallyResized, setHasBubbleInitiallyResized] = useState(false);
    const autoUpdateTool = useAutoUpdateTool();
    useEffect(() => {
        var _a;
        const height = (_a = document === null || document === void 0 ? void 0 : document.getElementById(id)) === null || _a === void 0 ? void 0 : _a.clientHeight;
        if (height && height !== tool.bubble.size.height) {
            const next = cloneDeep(tool);
            next.bubble.size.height = height;
            setBubblePosition(getAutoBubblePosition(next, viewportDimensions));
            autoUpdateTool(next);
        }
    });
    useEffect(() => {
        setBubblePosition(getAutoBubblePosition(tool, viewportDimensions));
    }, [viewportDimensions, tool.position, tool.bubble.arrow]);
    useEffect(() => {
        let isMounted = true;
        const readyHandler = () => {
            setHasBubbleInitiallyResized(true);
        };
        void document.fonts.ready.then(() => {
            if (isMounted)
                readyHandler();
        });
        return () => {
            isMounted = false;
        };
    }, []);
    return (React.createElement(BubbleContainer, { id: id, showLabel: props.isInEditViewport && selectedToolId === "screen", index: index, style: {
            visibility: !hasBubbleInitiallyResized ? "hidden" : "inherit",
            opacity: !props.isInEditViewport ? 0 : 1,
            borderColor: background.borderColor,
            backgroundColor: background.backgroundColor,
            borderRadius: borderRadii[tool.bubble.background.shape],
            boxShadow: background.isShadowEnabled ? boxShadow : "none",
        } },
        arrow.isEnabled && React.createElement(BubbleArrow, Object.assign({}, Object.assign(Object.assign({}, props), { bubblePosition }))),
        React.createElement(HeadlineContainer, { config: headline },
            logo.isEnabled && logo.src && (React.createElement(HeadlineLogo, { shape: logo.shape, style: { marginRight: headline.isEnabled ? 8 : 0 } },
                React.createElement("img", { src: logo.src }))),
            headline.isEnabled && (React.createElement(BubbleHeadlineText, { headline: headline, viewportDimensions: viewportDimensions, onDoubleClick: () => setBuilderFocusField(TourialEditorFocusFields.BUBBLE_HEADLINE_TEXT) }))),
        body.isEnabled && (React.createElement(BubbleBodyText, { body: body, viewportDimensions: viewportDimensions, onDoubleClick: () => setBuilderFocusField(TourialEditorFocusFields.BUBBLE_BODY_TEXT) }))));
}
export function BubbleArrow(props) {
    const { toolData: tool, bubblePosition } = props;
    const [arrowXYR, setArrowXYR] = useState(getArrowOffsetPx(tool));
    useEffect(() => {
        setArrowXYR(getArrowOffsetPx(tool));
    }, [bubblePosition, tool.bubble.size, tool.bubble.arrow.position]);
    return (React.createElement(Arrow, { style: {
            backgroundColor: tool.bubble.background.backgroundColor,
            borderColor: `${tool.bubble.background.borderColor} ${tool.bubble.background.borderColor} ${tool.bubble.background.backgroundColor} ${tool.bubble.background.backgroundColor} `,
            height: arrowSizes[tool.bubble.arrow.size],
            width: arrowSizes[tool.bubble.arrow.size],
            transform: `rotate(${arrowXYR.r}deg)`,
            left: arrowXYR.x,
            top: arrowXYR.y,
        } }));
}
const Arrow = styled.div `
  position: absolute;
  border-width: 1px;
  border-style: solid;
`;
const BubbleContainer = styled(WrapperChild) `
  padding: 16px;
  border-width: 1px;
  border-style: solid;
  height: min-content;
  ${p => p.showLabel
    ? `&:before {
        font: ${newTheme.fonts.small};
        content: "${p.index}:";
        position: absolute;
        line-height: 24px;
        padding: 0px 4px;
        font-size: 14px;
        top: -30px;
        background-color: ${newTheme.colors.white};
        border-radius: 4px;
        border: 1px solid ${newTheme.colors.grey300};
      }`
    : ""}
`;
const HeadlineContainer = styled.div `
  margin-bottom: ${p => (p.config.isEnabled ? "4px" : "0px")};
  display: flex;
  align-items: center;
  justify-content: ${p => (p.config.isEnabled ? textAlignments[p.config.align] : "center")};
`;
const logoBorderRadii = {
    [BorderShape.RECTANGLE]: "0px",
    [BorderShape.ROUNDED]: "8px",
    [BorderShape.SQUIRCLE]: "20px",
};
const HeadlineLogo = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-align: center;
  border-radius: ${p => logoBorderRadii[p.shape]};
  min-width: 40px;
  min-height: 40px;
  > img {
    max-width: 40px;
    max-height: 40px;
  }
`;
const borderRadii = {
    [BorderShape.RECTANGLE]: 4,
    [BorderShape.ROUNDED]: 24,
    [BorderShape.SQUIRCLE]: 56,
};
const boxShadow = "0px 0px 1px rgba(48, 49, 51, 0.05), 0px 4px 8px rgba(48, 49, 51, 0.1), inset 0px 0px 2px rgba(48, 49, 51, 0.5)";
export function getAutoBubblePosition(t, vd) {
    const bubbleWidth = pxToPercent(t.bubble.size.width, vd.width);
    const bubbleHeight = pxToPercent(t.bubble.size.height, vd.height);
    const { x: triggerX, y: triggerY } = t.position;
    const triggerHeight = 0;
    const triggerWidth = 0;
    const arrowSize = arrowSizes[t.bubble.arrow.size] * 1.414;
    const halfArrowSize = arrowSize / 2;
    const edgePaddingX = pxToPercent(arrowEdgePaddingPx + halfArrowSize, vd.width);
    const edgePaddingY = pxToPercent(arrowEdgePaddingPx + halfArrowSize, vd.height);
    const triggerMarginX = pxToPercent(halfArrowSize, vd.width);
    const triggerMarginY = pxToPercent(halfArrowSize, vd.height);
    let x, y;
    switch (t.bubble.arrow.position) {
        case TooltipArrowPosition.BOTTOM_LEFT:
            x = triggerX - edgePaddingX;
            y = triggerY - bubbleHeight - triggerMarginY - triggerHeight / 2;
            break;
        case TooltipArrowPosition.BOTTOM_CENTER:
            x = triggerX - bubbleWidth / 2;
            y = triggerY - bubbleHeight - triggerMarginY - triggerHeight / 2;
            break;
        case TooltipArrowPosition.BOTTOM_RIGHT:
            x = triggerX - bubbleWidth + edgePaddingX;
            y = triggerY - bubbleHeight - triggerMarginY - triggerHeight / 2;
            break;
        case TooltipArrowPosition.TOP_LEFT:
            x = triggerX - edgePaddingX;
            y = triggerY + triggerHeight / 2 + triggerMarginY;
            break;
        case TooltipArrowPosition.TOP_CENTER:
            x = triggerX - bubbleWidth / 2;
            y = triggerY + triggerHeight / 2 + triggerMarginY;
            break;
        case TooltipArrowPosition.TOP_RIGHT:
            x = triggerX - bubbleWidth + edgePaddingX;
            y = triggerY + triggerHeight / 2 + triggerMarginY;
            break;
        case TooltipArrowPosition.RIGHT_TOP:
            x = triggerX - bubbleWidth - triggerMarginX - triggerWidth / 2;
            y = triggerY - edgePaddingY;
            break;
        case TooltipArrowPosition.RIGHT_CENTER:
            x = triggerX - bubbleWidth - triggerMarginX - triggerWidth / 2;
            y = triggerY - bubbleHeight / 2;
            break;
        case TooltipArrowPosition.RIGHT_BOTTOM:
            x = triggerX - bubbleWidth - triggerMarginX - triggerWidth / 2;
            y = triggerY - bubbleHeight + edgePaddingY;
            break;
        case TooltipArrowPosition.LEFT_TOP:
            x = triggerX + triggerMarginX + triggerWidth / 2;
            y = triggerY - edgePaddingY;
            break;
        case TooltipArrowPosition.LEFT_CENTER:
            x = triggerX + triggerMarginX + triggerWidth / 2;
            y = triggerY - bubbleHeight / 2;
            break;
        case TooltipArrowPosition.LEFT_BOTTOM:
            x = triggerX + triggerMarginX + triggerWidth / 2;
            y = triggerY - bubbleHeight + edgePaddingY;
            break;
    }
    if (x > -Infinity && y > -Infinity) {
        return { x, y };
    }
    else {
        return { x: 0, y: 0 };
    }
}
export function getAutoTriggerPosition(t, vd, bubblePosition) {
    const { x: bubbleX, y: bubbleY } = bubblePosition;
    const bubbleWidth = pxToPercent(t.bubble.size.width, vd.width);
    const bubbleHeight = pxToPercent(t.bubble.size.height, vd.height);
    const triggerHeight = 0;
    const triggerWidth = 0;
    const arrowSize = arrowSizes[t.bubble.arrow.size] * 1.414;
    const halfArrowSize = arrowSize / 2;
    const edgePaddingX = pxToPercent(arrowEdgePaddingPx + halfArrowSize, vd.width);
    const edgePaddingY = pxToPercent(arrowEdgePaddingPx + halfArrowSize, vd.height);
    const triggerMarginX = pxToPercent(halfArrowSize, vd.width);
    const triggerMarginY = pxToPercent(halfArrowSize, vd.height);
    let x, y;
    switch (t.bubble.arrow.position) {
        case TooltipArrowPosition.BOTTOM_LEFT:
            x = bubbleX + edgePaddingX;
            y = bubbleY + bubbleHeight + triggerMarginY + triggerHeight / 2;
            break;
        case TooltipArrowPosition.BOTTOM_CENTER:
            x = bubbleX + bubbleWidth / 2;
            y = bubbleY + bubbleHeight + triggerMarginY + triggerHeight / 2;
            break;
        case TooltipArrowPosition.BOTTOM_RIGHT:
            x = bubbleX + bubbleWidth - edgePaddingX;
            y = bubbleY + bubbleHeight + triggerMarginY + triggerHeight / 2;
            break;
        case TooltipArrowPosition.TOP_LEFT:
            x = bubbleX + edgePaddingX;
            y = bubbleY - triggerHeight / 2 - triggerMarginY;
            break;
        case TooltipArrowPosition.TOP_CENTER:
            x = bubbleX + bubbleWidth / 2;
            y = bubbleY - triggerHeight / 2 - triggerMarginY;
            break;
        case TooltipArrowPosition.TOP_RIGHT:
            x = bubbleX + bubbleWidth - edgePaddingX;
            y = bubbleY - triggerHeight / 2 - triggerMarginY;
            break;
        case TooltipArrowPosition.RIGHT_TOP:
            x = bubbleX + bubbleWidth + triggerMarginX + triggerWidth / 2;
            y = bubbleY + edgePaddingY;
            break;
        case TooltipArrowPosition.RIGHT_CENTER:
            x = bubbleX + bubbleWidth + triggerMarginX + triggerWidth / 2;
            y = bubbleY + bubbleHeight / 2;
            break;
        case TooltipArrowPosition.RIGHT_BOTTOM:
            x = bubbleX + bubbleWidth + triggerMarginX + triggerWidth / 2;
            y = bubbleY + bubbleHeight - edgePaddingY;
            break;
        case TooltipArrowPosition.LEFT_TOP:
            x = bubbleX - triggerMarginX - triggerWidth / 2;
            y = bubbleY + edgePaddingY;
            break;
        case TooltipArrowPosition.LEFT_CENTER:
            x = bubbleX - triggerMarginX - triggerWidth / 2;
            y = bubbleY + bubbleHeight / 2;
            break;
        case TooltipArrowPosition.LEFT_BOTTOM:
            x = bubbleX - triggerMarginX - triggerWidth / 2;
            y = bubbleY + bubbleHeight - edgePaddingY;
            break;
    }
    if (x > -Infinity && y > -Infinity) {
        return { x, y };
    }
    else {
        return t.position;
    }
}
