var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useRouteMatch } from "react-router-dom";
import { MainAppPagePaths } from "../../../../../../shared/types/app-events";
import { useCallback, useEffect, useRef, useState } from "react";
import { TourialApiCalls } from "../../../../helpers/tourial-api-calls";
import { EDIT_PANEL_WIDTH, MOBILE_BREAKPOINT, MOBILE_TOOLTIP_HEIGHT, NAV_HEIGHT, PREVIEW_STEPPER_HEIGHT, SCREEN_CONTAINER_ID, SCREEN_MARGIN, STEPS_PANEL_STUB_HEIGHT, } from "../config";
import { useHTDimensions, useHTId, useHTGotoNextStep, useHTGotoPrevStep, useHTMode, useHTSetBaseUrlWithSubdomain, useHTSetIsMobile, useHTSetMode, useHTSetPublishedTour, useHTSetScale, useHTSetTour, useHTStep, useHTSteps, useHTStepIndex, useHTStepCount, useHTViewportPresetSize, useHTScale, useHTStepsPanelHeight, useHTTheme, useHTTour, useHTPublishedTour, useHTLoadedCaptureId, useHTIsMobile, useHTClearRootState, } from "../../../../redux/selectors/ht-selectors";
import { HTMode, DTStepType, } from "../../../../../../shared/types/dom-tour";
import { htFsAndMongoDocsAreEqual } from "../helpers/dom-tour-helpers";
import { NAVBAR_HEIGHT } from "../../builder/builder-page";
import { FSDocument } from "../../../../addons/firebase";
import { useHotkeys } from "react-hotkeys-hook";
import { arrayDeleteAt, getBaseUrlWithSubdomain } from "../../../../helpers/misc";
import { isInLightbox, isInIframe } from "../../../../helpers/live";
import { getDTShareLinkByPathname, usePostEvent } from "../../../../helpers/events";
import { useAddToast } from "../../../../legacy/selectors";
import { ToastModes } from "../../../../types/definitions";
import { cloneDeep } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { HTViewportPresetSizes } from "../../../../redux/reducers/dom-tour-reducer";
import { TriggeredByAction, TriggerDispatchEvent, } from "../../../../../../shared/legacy/legacy-tool-data";
import { useIsSessionStarted } from "../../../../redux/selectors/redux-selectors";
import { HTIconModes } from "../components/atomic/ht-icon";
import tinycolor from "tinycolor2";
import { TourialStatus } from "../../../../../../shared/types/tourial-model";
export function useHTGetTourId() {
    const match = useRouteMatch(MainAppPagePaths.DOM_TOUR_BUILDER);
    let id = null;
    if (match) {
        const params = match.params;
        id = params.id;
    }
    return id;
}
export function useHTGetTourFromSlug() {
    const setTour = useHTSetTour();
    const setMode = useHTSetMode();
    const setBaseUrlWithSubdomain = useHTSetBaseUrlWithSubdomain();
    const [error, setError] = useState(null);
    useEffect(() => {
        const pathname = location.pathname.replace("/dt/", "").split("/")[0];
        TourialApiCalls.DomTours.getDomTourFromSlug(pathname)
            .then(({ tour, account }) => __awaiter(this, void 0, void 0, function* () {
            const shareLinkSlug = location.pathname.replace("/dt/", "").split("/")[1];
            let shareLink;
            if (shareLinkSlug) {
                shareLink = yield getDTShareLinkByPathname(shareLinkSlug, tour._id, account._id);
            }
            setTour(tour, shareLink);
            setMode(HTMode.LIVE);
            setBaseUrlWithSubdomain(getBaseUrlWithSubdomain(account));
        }))
            .catch((e) => {
            console.error("Error getting dom tour by slug", pathname);
            setError(e);
        });
    }, []);
    return { error };
}
export function useHTUpdateScale() {
    const { width, height } = useHTDimensions();
    const setScale = useHTSetScale();
    const mode = useHTMode();
    const isPreviewMode = mode === HTMode.PREVIEW;
    const viewportPresetSize = useHTViewportPresetSize();
    const stepsPanelHeight = useHTStepsPanelHeight();
    // hacky solution to reposition viewport when mobile view is enabled
    const mobileViewScaleAdjusterRef = useRef(0.001);
    const getAndUpdateAdjuster = () => {
        const currentValue = mobileViewScaleAdjusterRef.current;
        mobileViewScaleAdjusterRef.current = currentValue === 0.001 ? 0.002 : 0.001;
        return currentValue;
    };
    const setScaleWithAdjuster = (newValue) => {
        setScale(newValue - getAndUpdateAdjuster());
    };
    useEffect(() => {
        function updateScaleLive() {
            const screenContainer = document.getElementById("tourial-screen-container");
            const isEmbedded = isInLightbox() || isInIframe();
            const browserWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
            const screenWidth = isEmbedded ? browserWidth : screenContainer.getBoundingClientRect().width;
            const isMobile = screenWidth <= MOBILE_BREAKPOINT;
            const adjustedHeight = height + (isMobile ? MOBILE_TOOLTIP_HEIGHT : 0);
            const browserHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
            const browserRatio = browserWidth / browserHeight;
            const viewportRatio = width / adjustedHeight;
            if (browserRatio < viewportRatio || !isEmbedded) {
                setScaleWithAdjuster(browserWidth / width);
            }
            else {
                setScaleWithAdjuster(browserHeight / adjustedHeight);
            }
        }
        function updateScale() {
            function handleSetScale(scaler) {
                const finalWidth = width * scaler;
                if (viewportPresetSize === HTViewportPresetSizes.SMALL && finalWidth > MOBILE_BREAKPOINT) {
                    scaler = MOBILE_BREAKPOINT / width;
                }
                setScaleWithAdjuster(scaler);
            }
            const containerWidth = window.innerWidth - (isPreviewMode ? 0 : EDIT_PANEL_WIDTH) - SCREEN_MARGIN * 2;
            const containerHeight = window.innerHeight -
                NAVBAR_HEIGHT -
                (isPreviewMode ? PREVIEW_STEPPER_HEIGHT : stepsPanelHeight) -
                SCREEN_MARGIN * 2;
            const containerAspectRatio = containerWidth / containerHeight;
            const viewportAspectRatio = width / height;
            if (viewportAspectRatio >= containerAspectRatio) {
                const newWidth = (containerWidth / width) * width;
                const newHeight = (containerWidth / width) * height;
                if (newWidth <= MOBILE_BREAKPOINT && Math.abs(newHeight - containerHeight) < MOBILE_TOOLTIP_HEIGHT) {
                    const scaler = (containerHeight - MOBILE_TOOLTIP_HEIGHT) / height;
                    handleSetScale(scaler);
                }
                else {
                    const scaler = containerWidth / width;
                    handleSetScale(scaler);
                }
            }
            else {
                const newWidth = (containerHeight / height) * width;
                const newHeight = (containerHeight / height) * height;
                if (newWidth <= MOBILE_BREAKPOINT && Math.abs(newHeight - containerHeight) < MOBILE_TOOLTIP_HEIGHT) {
                    const scaler = (containerHeight - MOBILE_TOOLTIP_HEIGHT) / height;
                    handleSetScale(scaler);
                }
                else {
                    const scaler = containerHeight / height;
                    handleSetScale(scaler);
                }
            }
        }
        function updateScaleDC() {
            const containerWidth = document.getElementById("demo-center-dom-tour-container").getBoundingClientRect().width;
            setScaleWithAdjuster(containerWidth / width);
        }
        const isDemoCenter = !!document.getElementById("demo-center-dom-tour-container");
        const scalingFunction = isDemoCenter ? updateScaleDC : mode === HTMode.LIVE ? updateScaleLive : updateScale;
        scalingFunction();
        window.addEventListener("resize", scalingFunction);
        return () => window.removeEventListener("resize", scalingFunction);
    }, [width, height, mode, stepsPanelHeight, viewportPresetSize]);
}
export function useHTUpdateScaleLive() {
    const { width, height } = useHTDimensions();
    const setScale = useHTSetScale();
    const mode = useHTMode();
    const viewportPresetSize = useHTViewportPresetSize();
    const stepsPanelHeight = useHTStepsPanelHeight();
    // hacky solution to reposition viewport when mobile view is enabled
    const mobileViewScaleAdjusterRef = useRef(0.001);
    const getAndUpdateAdjuster = () => {
        const currentValue = mobileViewScaleAdjusterRef.current;
        mobileViewScaleAdjusterRef.current = currentValue === 0.001 ? 0.002 : 0.001;
        return currentValue;
    };
    const setScaleWithAdjuster = (newValue) => {
        setScale(newValue - getAndUpdateAdjuster());
    };
    useEffect(() => {
        function updateScaleLive() {
            const screenContainer = document.getElementById("tourial-screen-container");
            const isEmbedded = isInLightbox() || isInIframe();
            const browserWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
            const screenWidth = isEmbedded ? browserWidth : screenContainer.getBoundingClientRect().width;
            const isMobile = screenWidth <= MOBILE_BREAKPOINT;
            const adjustedHeight = height + (isMobile ? MOBILE_TOOLTIP_HEIGHT : 0);
            const browserHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
            const browserRatio = browserWidth / browserHeight;
            const viewportRatio = width / adjustedHeight;
            if (browserRatio < viewportRatio || !isEmbedded) {
                setScaleWithAdjuster(browserWidth / width);
            }
            else {
                setScaleWithAdjuster(browserHeight / adjustedHeight);
            }
        }
        function updateScaleDC() {
            const containerWidth = document.getElementById("demo-center-dom-tour-container").getBoundingClientRect().width;
            setScaleWithAdjuster(containerWidth / width);
        }
        const isDemoCenter = !!document.getElementById("demo-center-dom-tour-container");
        const scalingFunction = isDemoCenter ? updateScaleDC : updateScaleLive;
        scalingFunction();
        window.addEventListener("resize", scalingFunction);
        return () => window.removeEventListener("resize", scalingFunction);
    }, [width, height, mode, stepsPanelHeight, viewportPresetSize]);
}
export function useHTViewportData() {
    const [screenWidth, setScreenWidth] = useState();
    const [screenHeight, setScreenHeight] = useState();
    const [top, setTop] = useState();
    const [left, setLeft] = useState();
    const scale = useHTScale();
    const mode = useHTMode();
    const isPreviewMode = mode === HTMode.PREVIEW;
    const { width, height } = useHTDimensions();
    const stepsPanelHeight = useHTStepsPanelHeight();
    const isMobile = useHTIsMobile();
    const updateViewportData = () => {
        const screenWidth = width * scale;
        const screenHeight = height * scale + (isMobile ? MOBILE_TOOLTIP_HEIGHT : 0);
        const availableHeight = window.innerHeight -
            NAV_HEIGHT -
            (isPreviewMode ? PREVIEW_STEPPER_HEIGHT : STEPS_PANEL_STUB_HEIGHT + stepsPanelHeight);
        const availableWidth = window.innerWidth - (isPreviewMode ? 0 : EDIT_PANEL_WIDTH);
        setTop((availableHeight - screenHeight) / 2 + NAV_HEIGHT);
        setLeft((availableWidth - screenWidth) / 2);
        setScreenWidth(screenWidth);
        setScreenHeight(screenHeight);
    };
    useEffect(updateViewportData, [scale, height, width, stepsPanelHeight, mode, isMobile]);
    return { screenWidth, screenHeight, top, left };
}
export function htUpdateIsMobile(setIsMobile) {
    const screenContainer = document.getElementById(SCREEN_CONTAINER_ID);
    const screenWidth = screenContainer.getBoundingClientRect().width;
    if (screenWidth <= MOBILE_BREAKPOINT) {
        setIsMobile(true);
    }
    else {
        setIsMobile(false);
    }
}
export function useHTUpdateIsMobile() {
    const setIsMobile = useHTSetIsMobile();
    const scale = useHTScale();
    const handleUpdate = () => htUpdateIsMobile(setIsMobile);
    useEffect(handleUpdate, [scale]);
}
export function useHTFetchCaptureLibrary() {
    const tour = useHTTour();
    const captureIdsConcatenated = tour === null || tour === void 0 ? void 0 : tour.steps.reduce((acc, s) => acc + s.captureId, "");
    const [status, setStatus] = useState({
        data: null,
        error: null,
    });
    useEffect(() => {
        if (!captureIdsConcatenated)
            return;
        let isComponentMounted = true;
        TourialApiCalls.DomCapture.getDomCapturePreviewsByTourId(tour._id)
            .then(data => {
            if (isComponentMounted)
                setStatus(Object.assign(Object.assign({}, status), { data }));
        })
            .catch(error => {
            if (isComponentMounted)
                setStatus(Object.assign(Object.assign({}, status), { error }));
        });
        return () => {
            isComponentMounted = false;
        };
    }, [captureIdsConcatenated]);
    return status;
}
export function useHTFetchCaptureThumbnails() {
    const tour = useHTTour();
    const [status, setStatus] = useState({
        data: null,
        error: null,
    });
    useEffect(() => {
        if (!tour._id)
            return;
        let isComponentMounted = true;
        TourialApiCalls.DomCapture.getDomCaptureThumbnails(tour._id)
            .then(data => {
            if (isComponentMounted)
                setStatus(Object.assign(Object.assign({}, status), { data }));
        })
            .catch(error => {
            if (isComponentMounted)
                setStatus(Object.assign(Object.assign({}, status), { error }));
        });
        return () => {
            isComponentMounted = false;
        };
    }, [tour._id]);
    return status;
}
export function useHTHasIntro() {
    const steps = useHTSteps();
    if (!(steps === null || steps === void 0 ? void 0 : steps.length))
        return false;
    return steps[0].type === DTStepType.INTRO;
}
export function useHTHasOutro() {
    const steps = useHTSteps();
    if (!(steps === null || steps === void 0 ? void 0 : steps.length))
        return false;
    return steps[steps.length - 1].type === DTStepType.OUTRO;
}
export function useHTFetchCaptureThumbnail() {
    const step = useHTStep();
    const [status, setStatus] = useState({
        data: null,
        error: null,
    });
    useEffect(() => {
        if (!step.captureId)
            return;
        let isComponentMounted = true;
        TourialApiCalls.DomCapture.getDomCaptureThumbnail(step.captureId)
            .then(data => {
            if (isComponentMounted)
                setStatus(Object.assign(Object.assign({}, status), { data }));
        })
            .catch(error => {
            if (isComponentMounted)
                setStatus(Object.assign(Object.assign({}, status), { error }));
        });
        return () => {
            isComponentMounted = false;
        };
    }, [step.captureId]);
    return status;
}
export function useHTFetchCapture() {
    const step = useHTStep();
    const [status, setStatus] = useState({
        data: null,
        error: null,
    });
    useEffect(() => {
        TourialApiCalls.DomCapture.getDomCapture(step.captureId)
            .then(data => setStatus(Object.assign(Object.assign({}, status), { data })))
            .catch(error => setStatus(Object.assign(Object.assign({}, status), { error })));
    }, [step.captureId]);
    return status;
}
export function useHTFetchDraftAndSubscribe(id) {
    const [fetchState, setFetchState] = useState({
        data: null,
        error: null,
    });
    const setTour = useHTSetTour();
    const setPublishedTour = useHTSetPublishedTour();
    const draft = new FSDocument("domtours", id);
    useEffect(() => {
        draft
            .fetch()
            .then((draftTour) => __awaiter(this, void 0, void 0, function* () {
            const { tour: publishedTour } = yield TourialApiCalls.DomTours.getDomTourFromId(draftTour._id);
            setPublishedTour(publishedTour);
            setTour(draftTour);
            draft.subscribe((tourSnap) => setTour(tourSnap));
            setFetchState(Object.assign(Object.assign({}, fetchState), { data: draft }));
        }))
            .catch(error => setFetchState(Object.assign(Object.assign({}, fetchState), { error })));
        return () => {
            var _a;
            (_a = draft === null || draft === void 0 ? void 0 : draft.unsubscribe) === null || _a === void 0 ? void 0 : _a.call(draft);
        };
    }, []);
    return fetchState;
}
export function useHTBuilderHotkeys() {
    const gotoPrevStep = useHTGotoPrevStep();
    const gotoNextStep = useHTGotoNextStep();
    useHotkeys("left", () => {
        gotoPrevStep();
    });
    useHotkeys("right", () => {
        gotoNextStep();
    });
}
export function useHTLiveHotkeys() {
    const gotoPrevStep = useHTGotoPrevStep();
    const gotoNextStep = useHTGotoNextStep();
    useHotkeys("left", () => {
        gotoPrevStep();
    });
    useHotkeys("right", () => {
        gotoNextStep();
    });
}
export function useHTUpdateValue(value, setValue) {
    useEffect(() => {
        setValue(value);
    }, [value]);
}
export const useHTUpdatePublishedTour = () => {
    const htId = useHTId();
    const setPublishedDomTour = useHTSetPublishedTour();
    return (updateFields) => __awaiter(void 0, void 0, void 0, function* () {
        yield TourialApiCalls.DomTours.updatePublishedDomTour(Object.assign({ _id: htId }, updateFields))
            .then(updatedDomTour => {
            setPublishedDomTour(updatedDomTour);
        })
            .catch((e) => {
            console.error(`Error updating the published dom tour`);
            throw e;
        });
    });
};
export function useHTLoadEvent() {
    const postEvent = usePostEvent();
    const mode = useHTMode();
    useEffect(() => {
        if (mode === HTMode.LIVE) {
            void postEvent({ eventType: "TOURIAL_LOAD" });
        }
    }, []);
}
export function useHTPostSessionEndOnUnload() {
    const htId = useHTId();
    const postEvent = useHTPostEvent();
    const isSessionStarted = useIsSessionStarted();
    const postSessionEnd = () => {
        if (htId) {
            void postEvent({ eventType: "SESSION_END", triggeredByTimeout: false });
            removeEndEventListeners();
        }
    };
    function removeEndEventListeners() {
        window.removeEventListener("beforeunload", postSessionEnd);
        window.removeEventListener("unload", postSessionEnd);
    }
    useEffect(() => {
        if (isSessionStarted) {
            window.addEventListener("beforeunload", postSessionEnd, { once: true });
            window.addEventListener("unload", postSessionEnd, { once: true });
        }
        return () => {
            removeEndEventListeners();
        };
    }, [htId, isSessionStarted]);
}
export function useHTPostViewEvent() {
    const postEvent = usePostEvent();
    const { id: stepId } = useHTStep();
    useEffect(() => {
        void postEvent({
            eventType: "TOURIAL_VIEW",
            pageId: stepId,
            zoomId: "NOT_APPLICABLE",
            htVersion: 2,
        });
    }, [stepId]);
}
const convertStepTypeToLegacyTriggerListner = ({ clickAction, href, formId }, toolId) => {
    let legacyTriggerListenerDetails;
    if (clickAction === "NEXT_STEP") {
        const triggerListener = {
            toolId,
            triggeredByAction: TriggeredByAction.CLICK,
            dispatchEvent: TriggerDispatchEvent.NEXT_PAGE,
            listenerId: null,
        };
        legacyTriggerListenerDetails = {
            triggerListener,
        };
    }
    if (clickAction === "EXTERNAL_LINK") {
        const triggerListener = {
            toolId,
            triggeredByAction: TriggeredByAction.CLICK,
            dispatchEvent: TriggerDispatchEvent.EXTERNAL_LINK,
            listenerId: null,
            href,
        };
        legacyTriggerListenerDetails = {
            triggerListener,
            targetExternalLink: href,
        };
    }
    if (clickAction === "TRIGGER_FORM") {
        const triggerListener = {
            toolId,
            triggeredByAction: TriggeredByAction.CLICK,
            dispatchEvent: TriggerDispatchEvent.TRIGGER_FORM,
            listenerId: null,
            formId,
        };
        legacyTriggerListenerDetails = {
            triggerListener,
            targetFormId: formId,
        };
    }
    return legacyTriggerListenerDetails;
};
export function useHTHandleClickEvent() {
    const postEvent = usePostEvent();
    const step = useHTStep();
    return (clickAction) => {
        const eventType = "CLICK";
        const triggerListenerInteraction = convertStepTypeToLegacyTriggerListner(clickAction, step.id);
        const e = {
            eventType,
            stepType: step.type,
            pageId: step.id,
            zoomId: "NOT_APPLICABLE",
            triggerListenerInteraction,
            htVersion: 2,
        };
        void postEvent(e);
    };
}
export function useHTPostEvent() {
    const postEvent = usePostEvent();
    const step = useHTStep();
    return (event) => {
        const e = Object.assign({ pageId: step.id, zoomId: "NOT_APPLICABLE", htVersion: 2 }, event);
        return postEvent(e);
    };
}
export const useHTEvents = () => {
    useHTLoadEvent();
    useHTPostSessionEndOnUnload();
};
export const useHTLiveMode = () => {
    const setMode = useHTSetMode();
    useEffect(() => {
        setMode(HTMode.LIVE);
    }, []);
};
export function useHTIsFirstStep(setter) {
    const stepIndex = useHTStepIndex();
    useEffect(() => {
        setter(stepIndex === 0);
    }, [stepIndex]);
}
export function useHTIsLastStep(setter) {
    const stepIndex = useHTStepIndex();
    const stepCount = useHTStepCount();
    useEffect(() => {
        setter(stepIndex + 1 === stepCount);
    }, [stepIndex, stepCount]);
}
export function useHTDeleteStep() {
    const goToPrevStep = useHTGotoPrevStep();
    const steps = useHTSteps();
    const stepIndex = useHTStepIndex();
    const addToast = useAddToast();
    return (draft) => {
        if (steps.length === 1) {
            addToast({
                message: "Tour must contain atleast 1 step.",
                mode: ToastModes.ERROR,
            });
            return;
        }
        const s = arrayDeleteAt(cloneDeep(steps), stepIndex);
        void draft.update({ steps: s });
        goToPrevStep();
    };
}
export function useHTDuplicateStep() {
    const steps = useHTSteps();
    const stepIndex = useHTStepIndex();
    const step = useHTStep();
    return (draft) => {
        const s = cloneDeep(step);
        s.id = uuidv4();
        s.name = s.name + "-copy";
        void draft.update({ steps: [...steps.slice(0, stepIndex + 1), s, ...steps.slice(stepIndex + 1)] });
    };
}
export function useHTTourById(tourId) {
    const [tourial, setTourial] = useState(null);
    useEffect(() => {
        setTourial(null);
        if (tourId) {
            TourialApiCalls.DomTours.getDomTourById(tourId)
                .then(r => {
                setTourial(r.tour);
            })
                .catch(e => {
                console.error("failed to fetch tour", e);
            });
        }
    }, [tourId]);
    return tourial;
}
export function useHTIconMode() {
    const theme = useHTTheme();
    const [mode, setMode] = useState();
    useEffect(() => {
        setMode(tinycolor(theme.tooltip.backgroundColor).isLight() ? HTIconModes.LIGHT : HTIconModes.DARK);
    }, [theme]);
    return mode;
}
export const useHTStatus = () => {
    const htmlTour = useHTTour();
    const savedHtmlTour = useHTPublishedTour();
    if (!htFsAndMongoDocsAreEqual(htmlTour, savedHtmlTour) && savedHtmlTour.status === TourialStatus.LIVE) {
        return TourialStatus.PENDING_CHANGES;
    }
    else {
        return savedHtmlTour.status;
    }
};
/** returns the draft tour but with status accounting for saved tour diffs  */
export const useHTDraft = (savedHtmlTour) => {
    const isHtmlTour = savedHtmlTour === null || savedHtmlTour === void 0 ? void 0 : savedHtmlTour.steps;
    const id = savedHtmlTour === null || savedHtmlTour === void 0 ? void 0 : savedHtmlTour._id;
    const [htmlTour, setHtmlTour] = useState(savedHtmlTour);
    const draft = isHtmlTour ? new FSDocument("domtours", id) : undefined;
    useEffect(() => {
        if (isHtmlTour) {
            const setupDraftAndSavedStates = () => {
                try {
                    void draft.fetch().then(ogHtmlTourSnap => {
                        if (!htFsAndMongoDocsAreEqual(ogHtmlTourSnap, savedHtmlTour) &&
                            savedHtmlTour.status === TourialStatus.LIVE) {
                            setHtmlTour(Object.assign(Object.assign({}, ogHtmlTourSnap), { status: TourialStatus.PENDING_CHANGES }));
                        }
                        draft.subscribe((htmlTourSnap) => {
                            if (!htFsAndMongoDocsAreEqual(htmlTourSnap, savedHtmlTour) &&
                                savedHtmlTour.status === TourialStatus.LIVE) {
                                setHtmlTour(Object.assign(Object.assign({}, htmlTourSnap), { status: TourialStatus.PENDING_CHANGES }));
                            }
                            else {
                                setHtmlTour(Object.assign(Object.assign({}, htmlTourSnap), { status: savedHtmlTour.status }));
                            }
                        });
                    });
                }
                catch (error) {
                    console.error("issue with FS draft status");
                }
            };
            void setupDraftAndSavedStates();
            return () => {
                var _a;
                (_a = draft === null || draft === void 0 ? void 0 : draft.unsubscribe) === null || _a === void 0 ? void 0 : _a.call(draft);
            };
        }
    }, []);
    return htmlTour;
};
export const useHTUpdateDraft = (id) => {
    const [htmlTour, setHtmlTour] = useState(null);
    const draft = new FSDocument("domtours", id);
    useEffect(() => {
        const setupInitialHtmlTour = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const initialHtmlTour = yield draft.fetch();
                setHtmlTour(initialHtmlTour);
            }
            catch (error) {
                console.error("Issue with fetching initial FS draft:", error);
            }
        });
        void setupInitialHtmlTour();
        return () => {
            var _a;
            (_a = draft === null || draft === void 0 ? void 0 : draft.unsubscribe) === null || _a === void 0 ? void 0 : _a.call(draft);
        };
    }, []);
    const updateDraft = useCallback((update) => __awaiter(void 0, void 0, void 0, function* () {
        if (!htmlTour) {
            console.error("htmlTour is not initialized.");
            return;
        }
        if (update._id && update._id !== htmlTour._id) {
            console.error("htmlTour id does not match update id");
            return;
        }
        try {
            const updatedHtmlTour = Object.assign(Object.assign({}, htmlTour), update);
            setHtmlTour(updatedHtmlTour);
            yield draft.update(updatedHtmlTour);
        }
        catch (error) {
            console.error("Issue with updating FS draft:", error);
        }
    }), [htmlTour, draft]);
    return updateDraft;
};
export function useHTStepIframeLoadedStatus() {
    const step = useHTStep();
    const loadedCaptureId = useHTLoadedCaptureId();
    const [loadedStatus, setLoadedStatus] = useState(false);
    useEffect(() => {
        setLoadedStatus(step.captureId === loadedCaptureId);
    }, [loadedCaptureId]);
    return loadedStatus;
}
export function useHTResetOnExit() {
    const reset = useHTClearRootState();
    useEffect(() => {
        return () => {
            reset();
        };
    }, []);
}
