import axios from "axios";
import { cloneDeep, isEqual } from "lodash";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import store from "../../legacy/store";
import { getPostEventDataForTriggerListener, usePostEvent } from "../../helpers/events";
import { ActionType } from "../actions/redux-actions";
import { MediaType, ViewportMode, ViewportPresets, } from "../../types/definitions";
import { checkIsChildOfSelectedGroup, checkIsSiblingOfSelectedChild, getActiveVariant, getActiveVariantName, getAllLinkedCloudinaryUrls, getPreviousActiveView, getSortedViewTools, getSortedViewToolsInView, getStepToolTipIconData, getViewTools, getViewToolsAndToolsInActiveView, getViewToolsInPage, getViewToolsInView, pruneTourial, submitFormV2, } from "../../helpers/redux";
import { getListenersToHandle } from "../reducers/tourial-root-reducer";
import { isFieldValid } from "../../legacy/components/tool-views/form-inputs-view";
import { SET_IS_DEMOCENTER_SESSION_STARTED, SET_IS_MICROSITE_SESSION_STARTED, SET_IS_SESSION_STARTED, SET_LOAD_EVENT, } from "../../legacy/actions/legacy-action-types";
import { TimerTrigger, TriggerDispatchEvent, TriggeredByAction, } from "../../../../shared/legacy/legacy-tool-data";
import { objectToArray } from "../../../../shared/functions/helpers";
import { startTimers } from "../../helpers/timers";
import { ToolType } from "../../../../shared/types/tool-data-types/common";
import { getBackgroundTemplate, getDefaultZoom, getScreenTemplate } from "../../../../shared/functions/tourial";
import { isImageFileExtension } from "../../helpers/media";
import { getBaseMedia, getVideoDuration } from "../../helpers/video-trimming";
import { TourialStatus } from "../../../../shared/types/tourial-model";
export const useClearTourialRootState = () => {
    const dispatch = useDispatch();
    return () => dispatch({
        type: ActionType.CLEAR_TOURIAL_ROOT_STATE,
    });
};
export const useTourial = () => useSelector(state => state.tourialRootReducer.tourial, shallowEqual);
export const useTourialProductTourPage = () => useSelector(state => { var _a; return (_a = state.tourialRootReducer.tourial) === null || _a === void 0 ? void 0 : _a.productTourPage; }, shallowEqual);
export const useTourialName = () => useSelector(state => { var _a; return (_a = state.tourialRootReducer.tourial) === null || _a === void 0 ? void 0 : _a.name; });
export const useTourialSlug = () => useSelector(state => { var _a; return (_a = state.tourialRootReducer.tourial) === null || _a === void 0 ? void 0 : _a.slug; });
export const useTourialAccountId = () => useSelector(state => state.tourialRootReducer.tourial.accountId);
export const useLiveModeAccountId = () => useSelector(state => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    return (((_b = (_a = state.tourialRootReducer) === null || _a === void 0 ? void 0 : _a.tourial) === null || _b === void 0 ? void 0 : _b.accountId) ||
        ((_d = (_c = state.micrositeReducer) === null || _c === void 0 ? void 0 : _c.microsite) === null || _d === void 0 ? void 0 : _d.accountId) ||
        ((_f = (_e = state.demoCenterReducer) === null || _e === void 0 ? void 0 : _e.savedDemoCenter) === null || _f === void 0 ? void 0 : _f.accountId) ||
        ((_h = (_g = state.domTourBuilderReducer) === null || _g === void 0 ? void 0 : _g.tour) === null || _h === void 0 ? void 0 : _h.accountId));
});
export const useBackground = () => useSelector(state => {
    const activeVariantName = getActiveVariantName(state.tourialRootReducer);
    return state.tourialRootReducer.tourial[activeVariantName].tools.background;
}, shallowEqual);
export const useScreen = () => useSelector(state => {
    const activeVariantName = getActiveVariantName(state.tourialRootReducer);
    return state.tourialRootReducer.tourial[activeVariantName].tools.screen;
}, shallowEqual);
export const useViewport = () => useSelector(state => state.tourialRootReducer.viewport, shallowEqual);
export const useViewportMode = () => useSelector(state => state.tourialRootReducer.viewport.mode, shallowEqual);
export const useSetViewportMode = () => {
    const dispatch = useDispatch();
    return (mode) => {
        dispatch({ type: ActionType.SET_VIEWPORT_MODE, mode });
    };
};
// TODO move to legacy
export const useBuilderStyle = (toolId, toolType, showOnLoad) => useSelector(state => {
    const { builder } = state.tourialRootReducer;
    const { mode } = state.tourialRootReducer.viewport;
    const isExcludedType = toolType === ToolType.Screen || toolType === ToolType.Background;
    const isChildOfSelectedGroup = checkIsChildOfSelectedGroup(state.tourialRootReducer, toolId, builder.editDetailsId);
    const isSiblingOfSelectedChild = checkIsSiblingOfSelectedChild(state.tourialRootReducer, toolId, builder.editDetailsId);
    const isRelatedToSelectedTool = isChildOfSelectedGroup || isSiblingOfSelectedChild;
    const hoverAndSelectedStyle = {
        boxShadow: "0 0 0 1px white",
        outline: `1px ${toolType === ToolType.Group ? "solid" : "dashed"} ${toolType === ToolType.Group ? "magenta" : "black"}`,
        outlineWidth: "thin",
    };
    const multiselectToolStyle = {
        outline: "1px solid #2a73e8",
        cursor: "default !important",
    };
    if (!isExcludedType && (toolId === builder.editDetailsId || isRelatedToSelectedTool))
        hoverAndSelectedStyle.zIndex = 1000;
    if (!(toolId === builder.editDetailsId || isRelatedToSelectedTool) &&
        toolId === builder.hoveredToolId &&
        !showOnLoad &&
        !isExcludedType)
        hoverAndSelectedStyle.opacity = 0.4;
    if (mode === ViewportMode.EDIT) {
        const isSelected = toolId === builder.editDetailsId;
        const isSelectedOrHovered = toolId === builder.hoveredToolId || isSelected;
        if (builder.multiselectToolIds.includes(toolId))
            return multiselectToolStyle;
        if (toolType === ToolType.FormV2 && !isSelected)
            return { opacity: 0, zIndex: -1 };
        if (isSelectedOrHovered)
            return hoverAndSelectedStyle;
        if (!isExcludedType && isRelatedToSelectedTool)
            return { zIndex: 1000 };
        if (!isExcludedType && !showOnLoad && !isRelatedToSelectedTool)
            return { opacity: ".4" };
    }
    return {};
}, (x, y) => isEqual(x, y));
export const useIsToolHidden = (toolId) => useSelector(state => state.tourialRootReducer.builder.hiddenGroupId === toolId);
export const useIsGroupHidden = (groupId) => useSelector(state => state.tourialRootReducer.builder.hiddenGroupId === groupId);
export const useIsAnyToolDragging = () => useSelector(state => state.tourialRootReducer.builder.isAnyToolDragging);
export const useResizeDetails = () => useSelector(state => state.tourialRootReducer.builder.resizeDetails);
export const useToolData = (toolId) => useSelector(state => {
    const activeVariantName = getActiveVariantName(state.tourialRootReducer);
    return state.tourialRootReducer.tourial[activeVariantName].tools[toolId];
}, (x, y) => isEqual(x, y));
export const useBuilder = () => useSelector(state => state.tourialRootReducer.builder, shallowEqual);
export const useUpdateBuilder = () => {
    const dispatch = useDispatch();
    return (newFields) => {
        dispatch({ type: ActionType.UPDATE_BUILDER, newFields });
    };
};
export const useEditDetailsId = () => useSelector(state => state.tourialRootReducer.builder.editDetailsId);
/** converts viewport shirt size to width */
export const useViewportPresetAsNumber = () => useSelector(state => ViewportPresets[state.tourialRootReducer.builder.viewportPresetShirtSize]);
export const useViewportPresetShirtSize = () => useSelector(state => state.tourialRootReducer.builder.viewportPresetShirtSize);
export const useHoveredToolId = () => useSelector(state => state.tourialRootReducer.builder.hoveredToolId);
export const useStoryboardHeight = () => useSelector(state => state.tourialRootReducer.builder.storyboardHeight);
export const useBuilderFocusField = () => useSelector(state => state.tourialRootReducer.builder.focusField);
export const useSetStoryboardHeight = () => {
    const dispatch = useDispatch();
    return (storyboardHeight) => {
        dispatch({
            type: ActionType.UPDATE_BUILDER,
            newFields: { storyboardHeight },
        });
    };
};
export const useVideoProgress = () => useSelector(state => state.tourialRootReducer.builder.videoProgress);
export const useSetVideoProgress = () => {
    const dispatch = useDispatch();
    return (videoProgress) => {
        dispatch({
            type: ActionType.UPDATE_BUILDER,
            newFields: { videoProgress },
        });
    };
};
export const useIsPublishModalOpen = () => useSelector(state => state.tourialRootReducer.builder.isPublishModalOpen);
export const useSetIsPublishModalOpen = () => {
    const dispatch = useDispatch();
    return (isPublishModalOpen) => {
        dispatch({
            type: ActionType.UPDATE_BUILDER,
            newFields: { isPublishModalOpen },
        });
    };
};
export const useSetBuilderFocusField = () => {
    const dispatch = useDispatch();
    return (focusField) => {
        var _a, _b, _c;
        const isEditMode = ((_c = (_b = (_a = store === null || store === void 0 ? void 0 : store.getState()) === null || _a === void 0 ? void 0 : _a.tourialRootReducer) === null || _b === void 0 ? void 0 : _b.viewport) === null || _c === void 0 ? void 0 : _c.mode) === ViewportMode.EDIT;
        if (!isEditMode)
            return;
        dispatch({
            type: ActionType.UPDATE_BUILDER,
            newFields: { focusField },
        });
    };
};
export const useSetEditDetailsId = () => {
    const dispatch = useDispatch();
    return (editDetailsId) => {
        dispatch({
            type: ActionType.UPDATE_BUILDER,
            newFields: { editDetailsId },
        });
    };
};
export const useActiveZoom = () => useSelector(state => {
    const { viewport } = state.tourialRootReducer;
    const activeVariant = getActiveVariant(state.tourialRootReducer);
    const currentPage = activeVariant.pages.find(p => p.id === viewport.activeView.pageId);
    return currentPage.zooms.find(z => z.id === viewport.activeView.zoomId);
});
export const usePreviousZoom = () => useSelector(state => {
    const { viewport: { previousView }, } = state.tourialRootReducer;
    if (!previousView)
        return null;
    const activeVariant = getActiveVariant(state.tourialRootReducer);
    const prevPage = activeVariant.pages.find(p => p.id === previousView.pageId);
    if (!prevPage)
        return null;
    return prevPage.zooms.find(z => z.id === previousView.zoomId);
});
export const useActiveZoomId = () => useSelector(state => {
    const { viewport } = state.tourialRootReducer;
    return viewport.activeView.zoomId;
});
export const useCurrentPage = () => useSelector(state => {
    const { tourial, viewport } = state.tourialRootReducer;
    const activeVariantName = getActiveVariantName(state.tourialRootReducer);
    return tourial[activeVariantName].pages.find(p => p.id === viewport.activeView.pageId);
}, (a, b) => isEqual(a, b));
export const useAddZoom = () => {
    const dispatch = useDispatch();
    return (page) => dispatch({ type: ActionType.ADD_ZOOM, page });
};
export const useLegacyChangeView = () => {
    const dispatch = useDispatch();
    return (activeView, forceIsTransitioning) => dispatch({
        type: ActionType.LEGACY_CHANGE_VIEW,
        activeView,
        triggeredFromAutostart: forceIsTransitioning,
    });
};
export const useViewToolsInActiveView = () => useSelector(state => getViewToolsInView(state.tourialRootReducer), shallowEqual);
export const useViewToolsInActivePage = () => useSelector(state => getViewToolsInPage(state.tourialRootReducer), shallowEqual);
export const useViewTools = () => useSelector(state => getViewTools(state.tourialRootReducer), shallowEqual);
export const useSortedViewToolsInActiveView = () => useSelector(state => getSortedViewToolsInView(state.tourialRootReducer), shallowEqual);
export const useSortedViewTools = () => useSelector(state => getSortedViewTools(state.tourialRootReducer), shallowEqual);
export const useViewToolsAndToolsInActiveView = () => useSelector(state => getViewToolsAndToolsInActiveView(state.tourialRootReducer), shallowEqual);
export const useStepToolTipIconData = () => useSelector(state => getStepToolTipIconData(state.tourialRootReducer), shallowEqual);
export const usePageList = () => useSelector(state => {
    var _a, _b;
    const activeVariantName = getActiveVariantName(state.tourialRootReducer);
    return (_b = (_a = state.tourialRootReducer.tourial[activeVariantName]) === null || _a === void 0 ? void 0 : _a.pages) === null || _b === void 0 ? void 0 : _b.map(p => ({
        pageId: p.id,
        pageName: p.name,
    }));
}, (a, b) => isEqual(a, b));
export const useActiveView = () => useSelector(state => {
    var _a, _b, _c;
    const { viewport } = state.tourialRootReducer;
    const { pageId, zoomId } = viewport.activeView;
    const activeVariantName = getActiveVariantName(state.tourialRootReducer);
    const activePage = (_c = (_b = (_a = state.tourialRootReducer.tourial) === null || _a === void 0 ? void 0 : _a[activeVariantName]) === null || _b === void 0 ? void 0 : _b.pages) === null || _c === void 0 ? void 0 : _c.find(p => p.id === pageId);
    const activeZoom = activePage === null || activePage === void 0 ? void 0 : activePage.zooms.find(z => z.id === zoomId);
    return {
        pageId,
        zoomId,
        pageName: activePage === null || activePage === void 0 ? void 0 : activePage.name,
        zoomName: activeZoom === null || activeZoom === void 0 ? void 0 : activeZoom.name,
    };
});
export const useActivePageId = () => useSelector(state => {
    const { viewport } = state.tourialRootReducer;
    return viewport.activeView.pageId;
});
export const useActivePageIndex = () => useSelector(state => {
    const { viewport, tourial } = state.tourialRootReducer;
    const activeVariantName = getActiveVariantName(state.tourialRootReducer);
    return tourial[activeVariantName].pages.findIndex(p => p.id === viewport.activeView.pageId);
});
export const useActiveZoomIndex = () => useSelector(state => {
    const { viewport, tourial } = state.tourialRootReducer;
    const activeVariantName = getActiveVariantName(state.tourialRootReducer);
    return tourial[activeVariantName].pages
        .find(p => p.id === viewport.activeView.pageId)
        .zooms.findIndex(z => z.id === viewport.activeView.zoomId);
});
export const useNextPageId = () => useSelector(state => {
    var _a, _b;
    const { viewport } = state.tourialRootReducer;
    const activePageId = (_a = viewport.activeView) === null || _a === void 0 ? void 0 : _a.pageId;
    const activeVariantName = state.tourialRootReducer.viewport.activeVariantName;
    const pages = (_b = state.tourialRootReducer.tourial[activeVariantName]) === null || _b === void 0 ? void 0 : _b.pages;
    const activePageIndex = pages === null || pages === void 0 ? void 0 : pages.findIndex(p => p.id === activePageId);
    const nextPage = pages && pages[activePageIndex + 1];
    return nextPage === null || nextPage === void 0 ? void 0 : nextPage.id;
});
export const useNextZoomId = () => useSelector(state => {
    var _a, _b, _c, _d, _e, _f, _g;
    const { viewport } = state.tourialRootReducer;
    const activePageId = (_a = viewport.activeView) === null || _a === void 0 ? void 0 : _a.pageId;
    const activeZoomId = (_b = viewport.activeView) === null || _b === void 0 ? void 0 : _b.zoomId;
    const activeVariantName = state.tourialRootReducer.viewport.activeVariantName;
    const pages = (_c = state.tourialRootReducer.tourial[activeVariantName]) === null || _c === void 0 ? void 0 : _c.pages;
    const activePageIndex = pages === null || pages === void 0 ? void 0 : pages.findIndex(p => p.id === activePageId);
    const activeZoomIndex = (_e = (_d = pages === null || pages === void 0 ? void 0 : pages[activePageIndex]) === null || _d === void 0 ? void 0 : _d.zooms) === null || _e === void 0 ? void 0 : _e.findIndex(z => z.id === activeZoomId);
    const nextZoom = (_g = (_f = pages === null || pages === void 0 ? void 0 : pages[activePageIndex]) === null || _f === void 0 ? void 0 : _f.zooms) === null || _g === void 0 ? void 0 : _g[activeZoomIndex + 1];
    return nextZoom === null || nextZoom === void 0 ? void 0 : nextZoom.id;
});
export const usePreviousActiveView = () => useSelector(state => {
    const { viewport, tourial } = state.tourialRootReducer;
    const activeVariantName = getActiveVariantName(state.tourialRootReducer);
    return getPreviousActiveView(viewport, tourial, activeVariantName);
});
export const useViewToolLayers = () => useSelector(state => {
    const vt = getViewToolsAndToolsInActiveView(state.tourialRootReducer);
    return vt.map(v => {
        var _a, _b, _c;
        return ({
            toolId: (_a = v.tool) === null || _a === void 0 ? void 0 : _a.id,
            toolType: (_b = v.tool) === null || _b === void 0 ? void 0 : _b.type,
            groupToolId: (_c = v.tool) === null || _c === void 0 ? void 0 : _c.groupToolId,
        });
    });
}, (x, y) => isEqual(x, y));
export const usePushHistory = () => {
    const dispatch = useDispatch();
    return () => dispatch({ type: ActionType.PUSH_HISTORY });
};
export const useAddViewTool = () => {
    const dispatch = useDispatch();
    return (viewTool) => dispatch({ type: ActionType.ADD_VIEW_TOOL, viewTool });
};
export const useAddViewToolsByToolId = () => {
    const dispatch = useDispatch();
    return ({ toolId, pageId, zoomId }) => dispatch({
        type: ActionType.ADD_VIEW_TOOL_BY_TOOL_ID,
        toolId,
        pageId,
        zoomId,
    });
};
export const useEditViewToolsByToolId = () => {
    const dispatch = useDispatch();
    return ({ toolId, pageId, zoomId, visibilityOption, }) => dispatch({
        type: ActionType.EDIT_VIEW_TOOL_BY_TOOL_ID,
        toolId,
        pageId,
        zoomId,
        visibilityOption,
    });
};
export const useDeleteViewToolsByToolId = () => {
    const dispatch = useDispatch();
    return ({ toolId, pageId, zoomId }) => dispatch({
        type: ActionType.DELETE_VIEW_TOOL_BY_TOOL_ID,
        toolId,
        pageId,
        zoomId,
    });
};
export const useToggleShowOnMultipleViews = toolId => {
    const dispatch = useDispatch();
    return (isGlobal) => {
        dispatch({
            type: ActionType.TOGGLE_SHOW_ON_MULTIPLE_VIEWS,
            isGlobal,
            toolId,
        });
    };
};
export const useDeleteViewTool = () => {
    const dispatch = useDispatch();
    return (viewTool, hasConfirmation = false) => dispatch({ type: ActionType.DELETE_VIEW_TOOL, viewTool, hasConfirmation });
};
/** this is the "selected" tool in v2 and the "tool that has an open edit panel" in v3 */
export const useIsToolEditingDetails = (toolId) => useSelector(state => { var _a; return ((_a = state.tourialRootReducer.builder) === null || _a === void 0 ? void 0 : _a.editDetailsId) === toolId; });
export const useIsToolHovered = (toolId) => useSelector(state => { var _a; return ((_a = state.tourialRootReducer.builder) === null || _a === void 0 ? void 0 : _a.hoveredToolId) === toolId; });
export const useIsSettingsOpen = () => useSelector(state => { var _a; return (_a = state.tourialRootReducer.builder) === null || _a === void 0 ? void 0 : _a.isSettingsOpen; });
export const useIsEmptyTourial = () => useSelector(state => {
    const activeVariantName = getActiveVariantName(state.tourialRootReducer);
    const emptyTourial = {
        pages: [
            {
                name: "Page 1",
                zooms: [getDefaultZoom(3)],
                autoStartZoomId: "",
            },
        ],
        tools: {
            background: getBackgroundTemplate(),
            screen: getScreenTemplate(),
        },
        viewTools: [],
        triggerListeners: [],
    };
    const tourialClone = cloneDeep(state.tourialRootReducer.tourial[activeVariantName]);
    delete tourialClone.pages[0].id;
    delete tourialClone.timers;
    return isEqual(tourialClone, emptyTourial);
});
export const useAddPage = () => {
    const dispatch = useDispatch();
    return () => dispatch({ type: ActionType.ADD_PAGE });
};
export const useAddPageWithScreen = () => {
    const dispatch = useDispatch();
    return (src) => dispatch({ type: ActionType.ADD_PAGE_WITH_SCREEN, src });
};
export const useAddStarterScreens = () => {
    const dispatch = useDispatch();
    return (srcs, theme) => dispatch({ type: ActionType.ADD_STARTER_SCREENS, srcs, theme });
};
export const useTourId = () => useSelector(state => { var _a; return (_a = state.tourialRootReducer.tourial) === null || _a === void 0 ? void 0 : _a._id; });
export const useTriggerListeners = () => useSelector(state => {
    const activeVariantName = getActiveVariantName(state.tourialRootReducer);
    return state.tourialRootReducer.tourial[activeVariantName].triggerListeners;
});
export const useTourialId = () => useSelector(state => { var _a; return (_a = state.tourialRootReducer.tourial) === null || _a === void 0 ? void 0 : _a._id; });
export const useBuilderToolOnClick = (toolId) => {
    const dispatch = useDispatch();
    return () => {
        dispatch({
            type: ActionType.TOOL_ON_DISPATCH_EVENT,
            triggeredByAction: TriggeredByAction.CLICK,
            toolId,
        });
    };
};
export const useLiveToolOnClick = (toolId) => {
    const dispatch = useDispatch();
    const pageId = useActivePageId();
    const zoomId = useActiveZoomId();
    const triggerListeners = useTriggerListeners();
    const formSubmission = findFormSubmission(toolId, triggerListeners);
    const tool = useToolData((formSubmission === null || formSubmission === void 0 ? void 0 : formSubmission.formId) || toolId);
    const viewportMode = useViewportMode();
    const tourialId = useTourialId();
    const tourialName = useTourialName();
    const tourialSlug = useTourialSlug();
    const tourialAccountId = useTourialAccountId();
    const activeVariantName = useActiveVariantName();
    const nextPageId = useNextPageId();
    const nextZoomId = useNextZoomId();
    const previousActiveView = usePreviousActiveView();
    const isLiveToolVisible = useIsLiveToolVisible();
    const getToolTypeById = useGetToolTypeById();
    const groupHasFormInputs = useGroupHasFormInputs();
    const sessionId = useSessionId();
    const userId = useVisitorId();
    const integrations = useIntegrations();
    const isSessionStarted = useIsSessionStarted();
    const setIsSessionStarted = useSetIsSessionStarted();
    const timers = useTimers();
    const setTimer = useSetTimer();
    const showTool = useShowTool();
    const postEvent = usePostEvent();
    function findFormSubmission(toolId, triggerListeners) {
        return triggerListeners.find(tl => tl.toolId === toolId && tl.dispatchEvent === TriggerDispatchEvent.SUBMIT_FORM);
    }
    return () => {
        const eventData = {
            toolId,
            eventType: "CLICK",
            toolType: tool.type,
        };
        const dispatchAction = {
            type: ActionType.TOOL_ON_DISPATCH_EVENT,
            triggeredByAction: TriggeredByAction.CLICK,
            toolId,
        };
        if (viewportMode === ViewportMode.LIVE) {
            void postEvent(eventData);
            const listenersToHandle = getListenersToHandle(dispatchAction, triggerListeners);
            const listenerEventsToPost = listenersToHandle.map(triggerListener => getPostEventDataForTriggerListener(eventData, triggerListener, triggerListener.dispatchEvent === TriggerDispatchEvent.PREVIOUS_ZOOM ? previousActiveView.pageId : nextPageId, triggerListener.dispatchEvent === TriggerDispatchEvent.PREVIOUS_ZOOM ? previousActiveView.zoomId : nextZoomId, isLiveToolVisible, getToolTypeById, groupHasFormInputs));
            const checkAndPostSessionStart = () => {
                if ((tool.type === ToolType.Breadcrumbs || listenerEventsToPost.length) &&
                    eventData.tourId &&
                    !isSessionStarted) {
                    startTimers(TimerTrigger.SessionStart, timers, setTimer, pageId, zoomId, showTool, postEvent);
                }
            };
            checkAndPostSessionStart();
            listenerEventsToPost.forEach(listenerEvent => void postEvent(listenerEvent));
            if (formSubmission && (tool.type === ToolType.FormV2 || tool.type === ToolType.FormInputs)) {
                const isValid = submitFormV2(isFieldValid, tourialId, tourialName, tourialSlug, tourialAccountId, tool, pageId, zoomId, viewportMode, formSubmission.formId, activeVariantName, sessionId, userId, isSessionStarted, setIsSessionStarted, timers, setTimer, showTool, postEvent, integrations === null || integrations === void 0 ? void 0 : integrations.hutk, integrations === null || integrations === void 0 ? void 0 : integrations.munchkinCookie);
                if (!isValid)
                    return;
            }
        }
        else {
            console.log("Not LIVE mode. Would have tracked:", eventData);
        }
        dispatch(dispatchAction);
    };
};
export const useLiveToolOnHover = (toolId) => {
    const dispatch = useDispatch();
    const pageId = useActivePageId();
    const zoomId = useActiveZoomId();
    const tool = useToolData(toolId);
    const tourId = useTourialId();
    const viewportMode = useViewportMode();
    const tourialAccountId = useTourialAccountId();
    const activeVariantName = useActiveVariantName();
    const nextPageId = useNextPageId();
    const nextZoomId = useNextZoomId();
    const previousActiveView = usePreviousActiveView();
    const triggerListeners = useTriggerListeners();
    const isLiveToolVisible = useIsLiveToolVisible();
    const getToolTypeById = useGetToolTypeById();
    const groupHasFormInputs = useGroupHasFormInputs();
    const sessionId = useSessionId();
    const userId = useVisitorId();
    const isSessionStarted = useIsSessionStarted();
    const timers = useTimers();
    const setTimer = useSetTimer();
    const showTool = useShowTool();
    const postEvent = usePostEvent();
    return (direction) => {
        const eventData = {
            pageId,
            zoomId,
            toolId,
            tourId,
            version: 2,
            sessionId,
            userId,
            eventType: direction,
            toolType: tool.type,
            activeVariantName,
            accountId: tourialAccountId,
        };
        const dispatchAction = {
            type: ActionType.TOOL_ON_DISPATCH_EVENT,
            triggeredByAction: TriggeredByAction.HOVER,
            toolId,
            direction,
        };
        const listenersToHandle = getListenersToHandle(dispatchAction, triggerListeners);
        // Only handle & track hover events if there are
        // triggerListeners (interactions) associated with tool
        if (listenersToHandle.length) {
            if (viewportMode === ViewportMode.LIVE) {
                void postEvent(eventData);
                const listenerEventsToPost = listenersToHandle.map(triggerListener => getPostEventDataForTriggerListener(eventData, triggerListener, triggerListener.dispatchEvent === TriggerDispatchEvent.PREVIOUS_ZOOM
                    ? previousActiveView.pageId
                    : nextPageId, triggerListener.dispatchEvent === TriggerDispatchEvent.PREVIOUS_ZOOM
                    ? previousActiveView.zoomId
                    : nextZoomId, isLiveToolVisible, getToolTypeById, groupHasFormInputs));
                if (listenerEventsToPost.length && eventData.tourId && !isSessionStarted) {
                    startTimers(TimerTrigger.SessionStart, timers, setTimer, pageId, zoomId, showTool, postEvent);
                }
                listenerEventsToPost.forEach(listenerEvent => void postEvent(listenerEvent));
            }
            else {
                console.log("Not LIVE mode. Would have tracked:", eventData);
            }
            dispatch(dispatchAction);
        }
    };
};
export const useToolOnMouseEnter = () => {
    const dispatch = useDispatch();
    return (toolId) => dispatch({
        type: ActionType.TOOL_ON_DISPATCH_EVENT,
        triggeredByAction: TriggeredByAction.HOVER_IN,
        toolId,
    });
};
export const useToolOnHover = (toolId) => {
    const dispatch = useDispatch();
    return (direction) => dispatch({
        type: ActionType.TOOL_ON_DISPATCH_EVENT,
        triggeredByAction: TriggeredByAction.HOVER,
        toolId,
        direction,
    });
};
export const useToolOnMouseLeave = () => {
    const dispatch = useDispatch();
    return (toolId) => dispatch({
        type: ActionType.TOOL_ON_DISPATCH_EVENT,
        triggeredByAction: TriggeredByAction.HOVER_OUT,
        toolId,
    });
};
export const useTools = () => useSelector(state => {
    const activeVariantName = getActiveVariantName(state.tourialRootReducer);
    return state.tourialRootReducer.tourial[activeVariantName].tools;
});
export const useTool = (toolId) => useSelector(state => {
    const activeVariantName = getActiveVariantName(state.tourialRootReducer);
    return state.tourialRootReducer.tourial[activeVariantName].tools[toolId];
});
export const hasSizeAndPositionChanged = (a, b) => {
    var _a;
    if (!((_a = a === null || a === void 0 ? void 0 : a.width) === null || _a === void 0 ? void 0 : _a.toFixed))
        return true;
    return !(a.x.toFixed(3) === b.x.toFixed(3) &&
        a.y.toFixed(3) === b.y.toFixed(3) &&
        a.width.toFixed(3) === b.width.toFixed(3) &&
        a.height.toFixed(3) === b.height.toFixed(3));
};
export const useUpdateToolSizeAndPosition = (toolId) => {
    const dispatch = useDispatch();
    const tool = useToolData(toolId);
    return (sizeAndPosition, updateDeltaDetails) => {
        const prevSizeAndPosition = tool.sizeAndPosition;
        const sizeAndPositionChanged = hasSizeAndPositionChanged(prevSizeAndPosition, sizeAndPosition);
        if (sizeAndPositionChanged) {
            dispatch({
                type: ActionType.PUSH_HISTORY,
            });
            if (tool.type === ToolType.Group) {
                dispatch({
                    type: ActionType.UPDATE_GROUP_SIZE_AND_POSITION,
                    groupId: toolId,
                    updateDeltaDetails,
                });
            }
            else {
                dispatch({
                    type: ActionType.UPDATE_TOOL_SIZE_AND_POSITION,
                    toolId,
                    sizeAndPosition,
                });
            }
        }
    };
};
export const useUpdateMultitoolPositions = () => {
    const dispatch = useDispatch();
    const multiselectToolIds = useMultiselectToolIds();
    const tools = useTools();
    return (updateDeltaDetails) => {
        const { viewportDimensions: { width, height }, draggableData, draggableData: { x, y }, } = updateDeltaDetails;
        dispatch({
            type: ActionType.PUSH_HISTORY,
        });
        multiselectToolIds.forEach(toolId => {
            const tool = tools[toolId];
            const prevSizeAndPosition = tool.sizeAndPosition;
            const newPosition = Object.assign(Object.assign({}, prevSizeAndPosition), { x: prevSizeAndPosition.x + (x / width) * 100, y: prevSizeAndPosition.y + (y / height) * 100 });
            if (tool.type === ToolType.Group) {
                // draggableData x and y for groups is a relative coordinate from top-left of viewport
                const prevX = (prevSizeAndPosition.x / 100) * width;
                const prevY = (prevSizeAndPosition.y / 100) * height;
                dispatch({
                    type: ActionType.UPDATE_GROUP_SIZE_AND_POSITION,
                    groupId: toolId,
                    updateDeltaDetails: Object.assign(Object.assign({}, updateDeltaDetails), { draggableData: Object.assign(Object.assign({}, draggableData), { x: prevX + x, y: prevY + y }) }),
                });
            }
            else {
                dispatch({
                    type: ActionType.UPDATE_TOOL_SIZE_AND_POSITION,
                    toolId,
                    sizeAndPosition: newPosition,
                });
            }
        });
    };
};
export const useUpdatePage = () => {
    const dispatch = useDispatch();
    return (pageId, patch) => dispatch({ type: ActionType.UPDATE_PAGE, pageId, patch });
};
export const useUpdatePages = () => {
    const dispatch = useDispatch();
    return (pages) => dispatch({ type: ActionType.UPDATE_PAGES, pages });
};
export const useDuplicatePage = () => {
    const dispatch = useDispatch();
    return (pageId) => dispatch({ type: ActionType.LEGACY_DUPLICATE_PAGE, pageId });
};
export const useImportPage = () => {
    const dispatch = useDispatch();
    return (addFromPageId, addToPageId, addFromTourial) => dispatch({ type: ActionType.IMPORT_PAGE, addFromPageId, addToPageId, addFromTourial });
};
export const useImportPagesV3 = () => {
    const dispatch = useDispatch();
    return (addFromPageIds, addToPageId, addFromTourial, isEmptyTourial = false) => dispatch({
        type: ActionType.IMPORT_PAGES_V3,
        addFromPageIds,
        addToPageId,
        addFromTourial,
        isEmptyTourial,
    });
};
export const useDuplicateView = () => {
    const dispatch = useDispatch();
    return (pageId, zoomId) => dispatch({ type: ActionType.LEGACY_DUPLICATE_VIEW, pageId, zoomId });
};
export const useLegacyDeletePage = () => {
    const dispatch = useDispatch();
    return (pageId) => dispatch({ type: ActionType.LEGACY_DELETE_PAGE, pageId });
};
function getLegacyLiveAndPreviewVisibleToolIds(activeVariant, viewport) {
    return activeVariant.viewTools
        .filter(vt => vt.pageId === viewport.activeView.pageId && vt.zoomId === viewport.activeView.zoomId)
        .filter(vt => {
        var _a, _b;
        const groupOrToolId = (_b = (_a = activeVariant.tools[vt.toolId]) === null || _a === void 0 ? void 0 : _a.groupToolId) !== null && _b !== void 0 ? _b : vt.toolId;
        return viewport.visibleToolIds[groupOrToolId];
    })
        .sort((vtA, vtB) => {
        // Forms always on top
        const toolA = activeVariant.tools[vtA.toolId];
        const toolB = activeVariant.tools[vtB.toolId];
        if (
        // One of the tools is a form
        (toolA.type === ToolType.FormV2 || toolB.type === ToolType.FormV2) &&
            toolA.type !== toolB.type) {
            return toolA.type === ToolType.FormV2 ? 1 : -1;
        }
        return 0;
    })
        .map(v => v.toolId);
}
export const useLiveAndPreviewVisibleToolIds = () => useSelector(state => {
    const activeVariant = getActiveVariant(state.tourialRootReducer);
    const { viewport } = state.tourialRootReducer;
    return getLegacyLiveAndPreviewVisibleToolIds(activeVariant, viewport);
}, (a, b) => isEqual(a, b));
export const useIsLiveToolVisible = () => {
    const liveAndPreviewVisibleToolIds = useLiveAndPreviewVisibleToolIds();
    return (toolId) => {
        return liveAndPreviewVisibleToolIds.includes(toolId);
    };
};
export const useGetToolTypeById = () => {
    const tools = useTools();
    return (toolId) => {
        return tools[toolId].type;
    };
};
export const usePreviousView = () => useSelector(state => state.tourialRootReducer.viewport.previousView);
/** ensures shared tools between steps remain visible during transition */
export const useTransitioningVisibleToolIds = () => {
    const liveAndPreviewVisibleToolIds = useLiveAndPreviewVisibleToolIds();
    const previousView = usePreviousView();
    return useSelector(state => {
        if (!previousView)
            return liveAndPreviewVisibleToolIds;
        const activeVariant = getActiveVariant(state.tourialRootReducer);
        const { viewTools } = activeVariant;
        const visibleToolIds = [];
        for (const id of liveAndPreviewVisibleToolIds) {
            if (viewTools.findIndex(vt => vt.toolId === id && vt.pageId === previousView.pageId && vt.zoomId === previousView.zoomId) !== -1) {
                visibleToolIds.push(id);
            }
        }
        return visibleToolIds;
    }, (a, b) => isEqual(a, b));
};
export const useViewportIsTransitioning = () => useSelector(state => state.tourialRootReducer.viewport.isTransitioning);
export const useActiveViewSpotlights = (isThumbnail) => useSelector(state => {
    var _a, _b;
    if (isThumbnail)
        return [];
    const activeVariantName = getActiveVariantName(state.tourialRootReducer);
    const spotlights = [];
    const mode = (_a = state.tourialRootReducer) === null || _a === void 0 ? void 0 : _a.viewport.mode;
    const tools = (_b = state.tourialRootReducer) === null || _b === void 0 ? void 0 : _b.tourial[activeVariantName].tools;
    const viewTools = state.tourialRootReducer.tourial[activeVariantName].viewTools;
    const { visibleToolIds, activeView } = state.tourialRootReducer.viewport;
    const { zoomId, pageId } = activeView;
    const toolIds = Object.keys(tools);
    toolIds.forEach(toolId => {
        const tool = tools[toolId];
        if (tool.type === ToolType.Spotlight)
            spotlights.push(tool);
    });
    const activeToolIds = {};
    viewTools.forEach(vt => {
        if (vt.pageId === pageId && vt.zoomId === zoomId) {
            activeToolIds[vt.toolId] = true;
        }
    });
    return spotlights.filter(s => (mode === ViewportMode.EDIT ? activeToolIds[s.id] : visibleToolIds[s.id]));
});
export const useIsActiveViewSpotlightSelected = () => {
    const activeViewSpotlights = useActiveViewSpotlights(false);
    const editDetailsId = useEditDetailsId();
    return !!activeViewSpotlights.find(spotlight => spotlight.id === editDetailsId);
};
export const useSaveColor = () => {
    const dispatch = useDispatch();
    return (color) => dispatch({ type: ActionType.SAVE_COLOR, color });
};
export const useDeleteColor = () => {
    const dispatch = useDispatch();
    return (title) => dispatch({ type: ActionType.DELETE_COLOR, title });
};
export const useDefaultFontFamily = () => useSelector(state => { var _a; return (_a = state.tourialRootReducer.tourial) === null || _a === void 0 ? void 0 : _a.defaultFontFamily; });
export const useSetDefaultFontFamily = () => {
    const dispatch = useDispatch();
    return (fontFamily) => dispatch({ type: ActionType.SET_DEFAULT_FONT_FAMILY, fontFamily });
};
export const useLegacyDeleteZoom = () => {
    const dispatch = useDispatch();
    return (zoomId) => dispatch({ type: ActionType.LEGACY_DELETE_ZOOM, zoomId });
};
export const useDeleteCurrentEditDetailsViewTool = () => {
    const dispatch = useDispatch();
    return (hasConfirmation = false) => {
        dispatch({
            type: ActionType.DELETE_CURRENT_EDIT_DETAILS_VIEW_TOOL,
            hasConfirmation,
        });
    };
};
export const useUpdateTool = () => {
    const dispatch = useDispatch();
    return (tool) => dispatch({ type: ActionType.UPDATE_TOOL, tool });
};
/** use this for tool updates based on viewport changes to avoid spamming toasts in archived builders */
export const useAutoUpdateTool = () => {
    const dispatch = useDispatch();
    return (tool) => dispatch({ type: ActionType.AUTO_UPDATE_TOOL, tool });
};
export const useUpdateToolMediaSource = () => {
    const dispatch = useDispatch();
    return (tool, src, isLoading = false) => {
        dispatch({
            type: ActionType.UPDATE_TOOL_MEDIA_SOURCE,
            tool,
            src,
            isLoading,
        });
    };
};
export const useReorderPage = () => {
    const dispatch = useDispatch();
    return (direction, pageIndex) => dispatch({
        type: ActionType.REORDER_PAGE,
        direction,
        pageIndex,
    });
};
export const useUpdatePageIndex = () => {
    const dispatch = useDispatch();
    return (pageIndex, updatedPageIndex) => dispatch({
        type: ActionType.UPDATE_PAGE_INDEX,
        pageIndex,
        updatedPageIndex,
    });
};
export const useReorderZoom = () => {
    const dispatch = useDispatch();
    return (pageIndex, zoomIndex, updatedZoomIndex) => dispatch({
        type: ActionType.REORDER_ZOOM,
        pageIndex,
        zoomIndex,
        updatedZoomIndex,
    });
};
export const usePages = () => useSelector(state => {
    var _a, _b, _c;
    const activeVariantName = getActiveVariantName(state.tourialRootReducer);
    return (_c = (_b = (_a = state.tourialRootReducer) === null || _a === void 0 ? void 0 : _a.tourial) === null || _b === void 0 ? void 0 : _b[activeVariantName]) === null || _c === void 0 ? void 0 : _c.pages;
});
export const useSavedColors = () => useSelector(state => {
    var _a;
    return (_a = state.tourialRootReducer) === null || _a === void 0 ? void 0 : _a.tourial.savedColors;
});
export const useChildTools = (groupToolId) => useSelector(state => {
    const activeVariantName = getActiveVariantName(state.tourialRootReducer);
    const { tools } = state.tourialRootReducer.tourial[activeVariantName];
    return Object.keys(tools)
        .map(toolId => tools[toolId])
        .filter((tool) => tool.groupToolId === groupToolId);
});
export const useEditDetailsPrimaryTool = (toolId) => {
    const parentTool = useToolData(toolId);
    const childTool = useChildTools(toolId);
    if ((parentTool === null || parentTool === void 0 ? void 0 : parentTool.type) !== ToolType.Group)
        return parentTool;
    return childTool.find(t => (t === null || t === void 0 ? void 0 : t.type) === ToolType.Tooltip || (t === null || t === void 0 ? void 0 : t.type) === ToolType.FormInputs);
};
export const useGroupHasFormInputs = () => {
    const toolMap = useTools();
    const tools = Object.values(toolMap);
    return (groupToolId) => {
        var _a;
        if (((_a = toolMap[groupToolId]) === null || _a === void 0 ? void 0 : _a.type) === ToolType.FormV2)
            return true;
        const childTools = tools.filter(t => t.groupToolId === groupToolId);
        if (!(childTools === null || childTools === void 0 ? void 0 : childTools.length))
            return false;
        return !!childTools.find(t => (t === null || t === void 0 ? void 0 : t.type) === ToolType.FormInputs);
    };
};
const tooltipDirectionToAnimation = {
    top: "fadeInUp",
    bottom: "fadeInDown",
    left: "fadeInLeft",
    right: "fadeInRight",
};
export const useUpdateTooltipDirection = (toolId) => {
    const tool = useToolData(toolId);
    const siblingTools = useChildTools(tool.groupToolId);
    const updateTool = useUpdateTool();
    return (tooltipDirection) => {
        const animation = tooltipDirectionToAnimation[tooltipDirection];
        siblingTools.forEach(siblingTool => {
            if (siblingTool.type === ToolType.Tooltip) {
                updateTool(Object.assign(Object.assign({}, siblingTool), { animation, tooltipDirection }));
            }
            else {
                updateTool(Object.assign(Object.assign({}, siblingTool), { animation }));
            }
        });
    };
};
export const useUpdateZoom = () => {
    const dispatch = useDispatch();
    return (zoom, pageId) => dispatch({ type: ActionType.UPDATE_ZOOM, zoom, pageId });
};
export const useUpdateZoomById = () => {
    const dispatch = useDispatch();
    return (zoomId, patch, pageId) => dispatch({ type: ActionType.UPDATE_ZOOM_BY_ID, zoomId, patch, pageId });
};
export const useGroupTools = () => useViewToolsAndToolsInActiveView().filter(vt => { var _a; return ((_a = vt.tool) === null || _a === void 0 ? void 0 : _a.type) === ToolType.Group; });
export const useAddToolToGroup = () => {
    const dispatch = useDispatch();
    return (toolId, groupToolId) => dispatch({ type: ActionType.ADD_TOOL_TO_GROUP, toolId, groupToolId });
};
export const useToolColor = ({ toolId, property, propertyParentName, propertyGrandParentName, backgroundPageId, }) => useSelector(state => {
    var _a, _b, _c, _d;
    const activeVariantName = getActiveVariantName(state.tourialRootReducer);
    const { tourial } = state.tourialRootReducer;
    const tools = tourial[activeVariantName].tools;
    const tool = tools[toolId];
    let toolColor;
    if (propertyGrandParentName) {
        toolColor = (_b = (_a = tool[propertyGrandParentName]) === null || _a === void 0 ? void 0 : _a[propertyParentName]) === null || _b === void 0 ? void 0 : _b[property];
    }
    else if (propertyParentName) {
        toolColor = (_c = tool[propertyParentName]) === null || _c === void 0 ? void 0 : _c[property];
    }
    else if (backgroundPageId) {
        toolColor = (_d = tourial[activeVariantName].tools.background.backgrounds[backgroundPageId]) === null || _d === void 0 ? void 0 : _d[property];
    }
    else {
        toolColor = tourial[activeVariantName].tools[toolId][property];
    }
    return toolColor;
});
export const useToolVisibility = (toolId) => useSelector(state => {
    const tourial = state.tourialRootReducer.tourial;
    const activeVariantName = getActiveVariantName(state.tourialRootReducer);
    const tool = tourial[activeVariantName].tools[toolId];
    let viewToolCount = 0;
    const toolVisiblityPage = [];
    tourial[activeVariantName].pages.forEach(p => {
        const ToolVisibilityPage = {
            pageId: p.id,
            pageName: p.name,
            pageZooms: [],
        };
        p.zooms.forEach(z => {
            const viewTool = tourial[activeVariantName].viewTools.find(vt => vt.pageId === p.id && vt.zoomId === z.id && vt.toolId === toolId);
            if (viewTool)
                viewToolCount++;
            ToolVisibilityPage.pageZooms.push({
                pageId: p.id,
                pageName: p.name,
                zoomId: z.id,
                zoomName: z.name,
                toolId,
                viewTool,
                toolType: tool.type,
            });
        });
        return toolVisiblityPage.push(ToolVisibilityPage);
    });
    return {
        viewToolCount,
        pageSections: toolVisiblityPage,
    };
});
export const useUndo = () => {
    const dispatch = useDispatch();
    return () => {
        dispatch({ type: ActionType.UNDO });
    };
};
export const useRedo = () => {
    const dispatch = useDispatch();
    return () => {
        dispatch({ type: ActionType.REDO });
    };
};
export const useCopyTool = () => {
    const dispatch = useDispatch();
    return () => {
        dispatch({ type: ActionType.COPY_TOOL });
    };
};
export const usePasteTool = () => {
    const dispatch = useDispatch();
    return () => {
        dispatch({ type: ActionType.PASTE_TOOL });
    };
};
export const useIsDrawerOpen = () => useSelector(state => state.tourialRootReducer.builder.isDrawerOpen);
export const useTourialHasChangesToSave = () => useSelector(state => {
    const { tourial, savedTourial } = state.tourialRootReducer;
    return !isEqual(tourial, savedTourial);
});
export const useVersionWasModified = () => useSelector(state => {
    const { tourial, version } = state.tourialRootReducer;
    if (version) {
        return !isEqual(tourial, version.tour);
    }
    return undefined;
});
export const useIsDeleteConfirmationModalOpen = () => useSelector(state => state.tourialRootReducer.builder.isDeleteConfirmationModalOpen);
export const useToggleShowOnLoad = () => {
    const dispatch = useDispatch();
    return (toolId) => dispatch({ type: ActionType.TOGGLE_TOOL_SHOW_ON_LOAD, toolId });
};
export const useAddMedia = () => {
    const dispatch = useDispatch();
    return (media) => dispatch({ type: ActionType.ADD_MEDIA, media });
};
export const useSetScreenImage = () => {
    const dispatch = useDispatch();
    return (src) => dispatch({ type: ActionType.LEGACY_SET_SCREEN_IMAGE, src });
};
export const useSetScreenImageById = () => {
    const dispatch = useDispatch();
    return (src, pageId) => dispatch({ type: ActionType.SET_SCREEN_IMAGE_BY_ID, src, pageId });
};
export const useUpdateTourial = () => {
    const dispatch = useDispatch();
    return (tourial) => dispatch({
        type: ActionType.SET_LOCAL_TOURIAL,
        tourial,
    });
};
export const useUpdateTourialPartial = () => {
    const dispatch = useDispatch();
    return (newFields) => dispatch({ type: ActionType.UPDATE_TOURIAL_PARTIAL, newFields });
};
export const useEditingTextToolId = (toolId) => useSelector(state => state.tourialRootReducer.builder.editingTextToolId === toolId, (a, b) => isEqual(a, b));
export const useActiveNavModal = () => useSelector(state => state.tourialRootReducer.builder.activeNavModal);
export const useSaveTourial = () => {
    const dispatch = useDispatch();
    return () => {
        var _a, _b, _c;
        const isPreviewMode = ((_c = (_b = (_a = store === null || store === void 0 ? void 0 : store.getState()) === null || _a === void 0 ? void 0 : _a.tourialRootReducer) === null || _b === void 0 ? void 0 : _b.viewport) === null || _c === void 0 ? void 0 : _c.mode) === ViewportMode.PREVIEW;
        if (isPreviewMode)
            return;
        let tourial = store.getState().tourialRootReducer.tourial;
        tourial = pruneTourial(tourial);
        axios
            .put("/v2", tourial)
            .then(() => {
            // Update saved and draft tourials to new validatedTourial version
            dispatch({
                type: ActionType.UPDATE_LOCAL_SAVED_TOURIAL,
                tourial,
            });
            dispatch({
                type: ActionType.SET_LOCAL_TOURIAL,
                tourial,
            });
        })
            .catch(err => console.error("save error", err));
    };
};
export const useIsScreenTypeUnassigned = () => {
    const viewTools = useViewToolsInActivePage();
    const isAutomated = useIsAutomatedScreen();
    const hasNoTools = !viewTools.length;
    const isAutomatedExists = typeof isAutomated === "boolean";
    return hasNoTools && !isAutomatedExists;
};
export const useIsAutomatedScreen = () => useSelector(state => {
    const activeVariantName = getActiveVariantName(state.tourialRootReducer);
    const activePageId = state.tourialRootReducer.viewport.activeView.pageId;
    return state.tourialRootReducer.tourial[activeVariantName].pages.find(p => p.id === activePageId).isAutomated;
});
export const useIsAutomatedScreenByPageId = (id) => useSelector(state => {
    const activeVariantName = getActiveVariantName(state.tourialRootReducer);
    return state.tourialRootReducer.tourial[activeVariantName].pages.find(p => p.id === id).isAutomated;
});
export const useUpdateViewportMode = () => {
    const dispatch = useDispatch();
    return (mode) => dispatch({ type: ActionType.UPDATE_VIEWPORT_MODE, mode });
};
export const useAddToolGlobally = () => {
    const dispatch = useDispatch();
    return (tool, visibleOnLoad) => dispatch({
        type: ActionType.ADD_TOOL_GLOBALLY,
        tool,
        visibleOnLoad,
    });
};
export const useListenerById = (listenerId) => useSelector(state => {
    const activeVariantName = getActiveVariantName(state.tourialRootReducer);
    return state.tourialRootReducer.tourial[activeVariantName].triggerListeners.find(listener => listener.listenerId === listenerId);
});
export const useListenersByToolId = (toolId) => useSelector(state => {
    const activeVariantName = getActiveVariantName(state.tourialRootReducer);
    return state.tourialRootReducer.tourial[activeVariantName].triggerListeners.filter(listener => listener.toolId === toolId);
});
export const useUpdateListener = () => {
    const dispatch = useDispatch();
    return (updatedListener) => dispatch({ type: ActionType.UPDATE_LISTENER, updatedListener });
};
export const useDeleteListenerById = () => {
    const dispatch = useDispatch();
    return (listenerId) => dispatch({ type: ActionType.DELETE_LISTENER, listenerId });
};
export const useAddClickListener = () => {
    const dispatch = useDispatch();
    return (toolId) => dispatch({ type: ActionType.ADD_CLICK_LISTENER, toolId });
};
export const useAddHoverListener = () => {
    const dispatch = useDispatch();
    return (toolId) => dispatch({ type: ActionType.ADD_HOVER_LISTENER, toolId });
};
export const useApplyBackgroundToAllPages = () => {
    const dispatch = useDispatch();
    return (sourcePageId) => dispatch({
        type: ActionType.APPLY_BACKGROUND_TO_ALL_PAGES,
        sourcePageId,
    });
};
export const useUpdateViewport = () => {
    const dispatch = useDispatch();
    return (patch) => dispatch({ type: ActionType.UPDATE_VIEWPORT, patch });
};
export const useGoToFirstPage = () => {
    const dispatch = useDispatch();
    const firstPageId = useFirstPageId();
    return () => dispatch({
        type: ActionType.LEGACY_CHANGE_VIEW,
        activeView: {
            pageId: firstPageId,
            zoomId: "MAIN",
        },
    });
};
export const useScreenMedia = () => useSelector(state => {
    const activeVariantName = getActiveVariantName(state.tourialRootReducer);
    return state.tourialRootReducer.tourial[activeVariantName].tools.screen.media;
});
export const useMediaSize = () => useSelector(state => {
    const activeVariantName = getActiveVariantName(state.tourialRootReducer);
    const media = state.tourialRootReducer.tourial.media;
    const screenMedia = state.tourialRootReducer.tourial[activeVariantName].tools.screen.media;
    const { pageId } = state.tourialRootReducer.viewport.activeView;
    try {
        const srcUrl = screenMedia[pageId];
        return getBaseMedia(media, srcUrl).size;
    }
    catch (_a) {
        return;
    }
});
export const useSetVideoDuration = () => {
    const dispatch = useDispatch();
    return (duration, src) => {
        dispatch({
            type: ActionType.SET_VIDEO_DURATION,
            duration,
            src,
        });
    };
};
export const useTourialMediaDurationAndType = () => useSelector(state => {
    const activeVariantName = getActiveVariantName(state.tourialRootReducer);
    const activeVariant = state.tourialRootReducer.tourial[activeVariantName];
    const media = state.tourialRootReducer.tourial.media;
    const screenMedia = activeVariant.tools.screen.media;
    const { pageId, zoomId } = state.tourialRootReducer.viewport.activeView;
    const currentPage = activeVariant.pages.find(p => p.id === pageId);
    const currentZoom = currentPage.zooms.find(z => z.id === zoomId);
    try {
        const srcUrl = screenMedia[pageId];
        const videoDuration = getVideoDuration(media, srcUrl);
        const srcDuration = videoDuration || currentZoom.automationDuration;
        const isImage = isImageFileExtension(srcUrl);
        return {
            duration: isImage ? currentZoom.automationDuration : srcDuration,
            type: isImage ? "image" : "video",
            hasVideoDuration: !!videoDuration,
        };
    }
    catch (_a) {
        return { duration: 0, type: "image" };
    }
});
export const useTourialMedia = () => useSelector(state => { var _a; return (_a = state.tourialRootReducer.tourial) === null || _a === void 0 ? void 0 : _a.media; });
export const useFirstPageId = () => useSelector(state => {
    const activeVariantName = getActiveVariantName(state.tourialRootReducer);
    return state.tourialRootReducer.tourial[activeVariantName].pages[0].id;
});
export const useActiveVariantName = () => useSelector(state => getActiveVariantName(state.tourialRootReducer));
export const useHasActiveVariantNameBeenSet = () => useSelector(state => !!state.tourialRootReducer.viewport.activeVariantName);
export const useActiveVariant = () => useSelector(state => getActiveVariant(state.tourialRootReducer));
export const useHasVariantMobile = () => useSelector(state => { var _a, _b; return !!((_b = (_a = state.tourialRootReducer) === null || _a === void 0 ? void 0 : _a.tourial) === null || _b === void 0 ? void 0 : _b.variantMobile); });
export const useCreateVariantMobileTemplate = () => {
    const dispatch = useDispatch();
    return () => dispatch({ type: ActionType.CREATE_VARIANT_MOBILE_TEMPLATE });
};
export const useSetActiveVariant = () => {
    const dispatch = useDispatch();
    const currentActiveVariant = useActiveVariantName();
    const hasActiveVariantNameBeenSet = useHasActiveVariantNameBeenSet();
    return (activeVariantName) => {
        if (activeVariantName !== currentActiveVariant || !hasActiveVariantNameBeenSet) {
            dispatch({
                type: ActionType.SET_ACTIVE_VARIANT,
                activeVariantName,
            });
        }
    };
};
export const useIsFullyAutomated = () => {
    const activeVariantName = useActiveVariantName();
    return useSelector(state => {
        const isFullyAutomated = state.tourialRootReducer.tourial[activeVariantName].pages.reduce((fullyAutomated, page) => !!page.isAutomated && fullyAutomated, true);
        return isFullyAutomated;
    });
};
export const useTourialLoop = () => {
    const activeVariantName = useActiveVariantName();
    return useSelector(state => state.tourialRootReducer.tourial[activeVariantName].loop);
};
export const useSetTourialLoop = () => {
    const dispatch = useDispatch();
    return (loop) => {
        dispatch({
            type: ActionType.SET_TOURIAL_LOOP,
            loop,
        });
    };
};
export const useIsMobileVariantEnabled = () => useSelector(state => !!state.tourialRootReducer.tourial.isVariantMobileEnabled);
export const useIsEmbedHeaderEnabled = () => useSelector(state => !!state.tourialRootReducer.tourial.isEmbedHeaderEnabled);
export const useIsToolHoveredOrSelected = (toolId) => useSelector(state => state.tourialRootReducer.builder.editDetailsId === toolId ||
    state.tourialRootReducer.builder.hoveredToolId === toolId);
export const useMobileEmbedButton = () => useSelector(state => state.tourialRootReducer.tourial.mobileEmbedButton);
export const useAddClickZoneAndButtonDefaultTriggerListener = () => {
    const dispatch = useDispatch();
    return (triggerToolId) => dispatch({
        type: ActionType.ADD_CLICKZONE_AND_BUTTON_DEFAULT_TRIGGER_LISTENER,
        triggerToolId,
    });
};
export const useAddTooltipButtonDefaultTriggerListener = () => {
    const dispatch = useDispatch();
    return (triggerToolId) => dispatch({
        type: ActionType.ADD_TOOLTIP_BUTTON_DEFAULT_TRIGGER_LISTENER,
        triggerToolId,
    });
};
export const useIsSuperAdmin = () => useSelector(state => state.loginReducer.userAccess === "superAdmin" || state.loginReducer.userAccess === "engineer");
export const useUserAccess = () => useSelector(state => state.loginReducer.userAccess);
export const useIsEngineer = () => useSelector(state => state.loginReducer.userAccess === "engineer");
export const useLoggedInAccountName = () => useSelector(state => state.loginReducer.loggedInAccount);
export const useUserId = () => useSelector(state => state.loginReducer.userId);
export const usePrefetchAndSetCloudinaryUrls = (viewportMode) => {
    const activeVariant = useActiveVariant();
    const viewTools = useViewToolsInActiveView();
    return (activeView, prefetchedCloudinaryUrls, setPrefetchedCloudinaryUrls, isInit) => {
        if (viewportMode === ViewportMode.LIVE) {
            const allLinkedCloudinaryUrls = getAllLinkedCloudinaryUrls(activeView, activeVariant, viewTools, isInit);
            const newPrefetchedCloudinaryUrls = cloneDeep(prefetchedCloudinaryUrls);
            for (const url in allLinkedCloudinaryUrls) {
                // url not cached yet
                if (!prefetchedCloudinaryUrls[url]) {
                    const mediaType = allLinkedCloudinaryUrls[url];
                    newPrefetchedCloudinaryUrls[url] = mediaType;
                    // preload in browser
                    if (mediaType === MediaType.VIDEO) {
                        const videoElement = document.createElement(MediaType.VIDEO);
                        videoElement.src = url;
                        videoElement.hidden = true;
                        videoElement.preload = "auto";
                        document.body.appendChild(videoElement);
                        videoElement.remove();
                    }
                    else if (mediaType === MediaType.IMG) {
                        // see top rated answer here: https://stackoverflow.com/questions/42615556/how-to-preload-images-in-react-js
                        const imgElement = new Image();
                        imgElement.src = url;
                    }
                }
            }
            setPrefetchedCloudinaryUrls(newPrefetchedCloudinaryUrls);
        }
    };
};
export const useAccounts = () => useSelector(r => r.superAdminReducer.allAccounts);
export const useAddMultiselectToolId = () => {
    const dispatch = useDispatch();
    return (multiselectToolIds) => dispatch({
        type: ActionType.SET_TOOL_IS_MULTISELECTED,
        toolIds: multiselectToolIds,
        isSelected: true,
    });
};
export const useRemoveMultiselectToolId = () => {
    const dispatch = useDispatch();
    return (multiselectToolIds = ["CLEAR_MULTISELECT"]) => dispatch({
        type: ActionType.SET_TOOL_IS_MULTISELECTED,
        toolIds: multiselectToolIds,
        isSelected: false,
    });
};
export const useIsToolMultiselected = (toolId) => useSelector(state => state.tourialRootReducer.builder.multiselectToolIds.includes(toolId));
export const useMultiselectToolIds = () => useSelector(state => state.tourialRootReducer.builder.multiselectToolIds);
export const useIsSessionStarted = () => useSelector(state => state.loginReducer.isSessionStarted);
export const useSetIsSessionStarted = () => {
    const dispatch = useDispatch();
    return (isSessionStarted) => dispatch({
        type: SET_IS_SESSION_STARTED,
        isSessionStarted,
    });
};
export const useIsMicrositeSessionStarted = () => useSelector(state => state.loginReducer.isMicrositeSessionStarted);
export const useIsMicrositePublished = () => useSelector(state => { var _a; return (_a = state.micrositeReducer.microsite) === null || _a === void 0 ? void 0 : _a.isPublished; });
export const useIsDCSessionStarted = () => useSelector(state => state.loginReducer.isDemocenterSessionStarted);
export const useSetIsMicrositeSessionStarted = () => {
    const dispatch = useDispatch();
    return (isMicrositeSessionStarted) => dispatch({
        type: SET_IS_MICROSITE_SESSION_STARTED,
        isMicrositeSessionStarted,
    });
};
export const useSetIsDCSessionStarted = () => {
    const dispatch = useDispatch();
    return (isDemocenterSessionStarted) => dispatch({
        type: SET_IS_DEMOCENTER_SESSION_STARTED,
        isDemocenterSessionStarted,
    });
};
export const useLegacyHideTool = () => {
    const dispatch = useDispatch();
    return (toolId) => dispatch({
        type: ActionType.LEGACY_HIDE_TOOL,
        toolId,
    });
};
export const useShowTool = () => {
    const dispatch = useDispatch();
    return (toolId) => dispatch({
        type: ActionType.LEGACY_SHOW_TOOL,
        toolId,
    });
};
export const useSessionId = () => useSelector(state => state.loginReducer.sessionId);
export const useVisitorId = () => useSelector(state => state.loginReducer.visitorId);
export const useShareLinkId = () => useSelector(state => { var _a; return (_a = state.demoCenterReducer.shareLink) === null || _a === void 0 ? void 0 : _a._id; });
export const useShareLink = () => useSelector(state => state.tourialRootReducer.shareLink || state.domTourBuilderReducer.shareLink || state.demoCenterReducer.shareLink);
export const useIntegrations = () => useSelector(state => state.tourialRootReducer.integrations);
export const useSetHubspotIntegration = () => {
    const dispatch = useDispatch();
    return (hutk) => dispatch({
        type: ActionType.SET_HUBSPOT_INTEGRATION,
        hutk,
    });
};
export const useSetMarketoIntegration = () => {
    const dispatch = useDispatch();
    return (munchkinCookie) => dispatch({
        type: ActionType.SET_MARKETO_INTEGRATION,
        munchkinCookie,
    });
};
export const useAllFormV2Ids = () => {
    const activeVariantName = useActiveVariantName();
    return useSelector(state => objectToArray(state.tourialRootReducer.tourial[activeVariantName].tools)
        .filter(t => t.value.type === ToolType.FormV2)
        .map(t => { var _a; return (_a = t === null || t === void 0 ? void 0 : t.value) === null || _a === void 0 ? void 0 : _a.id; }));
};
export const useTimer = (toolId, view) => {
    const activeVariantName = useActiveVariantName();
    return useSelector(state => {
        var _a, _b, _c, _d;
        const timer = (_d = (_c = (_b = (_a = state.tourialRootReducer) === null || _a === void 0 ? void 0 : _a.tourial) === null || _b === void 0 ? void 0 : _b[activeVariantName]) === null || _c === void 0 ? void 0 : _c.timers) === null || _d === void 0 ? void 0 : _d.find(t => t.toolId === toolId);
        if (timer)
            return timer;
        return {
            trigger: TimerTrigger.SessionStart,
            toolId: toolId,
            view,
            isEnabled: false,
            isStarted: false,
            isCompleted: false,
            durationSecs: 5,
        };
    });
};
export const useSetTimer = () => {
    const dispatch = useDispatch();
    return (timer) => dispatch({
        type: ActionType.SET_TIMER,
        timer,
    });
};
export const useTimers = () => {
    const activeVariantName = useActiveVariantName();
    return useSelector(state => {
        var _a, _b, _c;
        return (_c = (_b = (_a = state.tourialRootReducer) === null || _a === void 0 ? void 0 : _a.tourial) === null || _b === void 0 ? void 0 : _b[activeVariantName]) === null || _c === void 0 ? void 0 : _c.timers;
    });
};
export const useLoginReducerData = () => useSelector(state => state.loginReducer);
export const useFeatureFlags = () => {
    return useSelector(state => { var _a, _b, _c; return ((_b = (_a = state.tourialRootReducer) === null || _a === void 0 ? void 0 : _a.liveModeAccount) === null || _b === void 0 ? void 0 : _b.featureFlags) || ((_c = state.loginReducer) === null || _c === void 0 ? void 0 : _c.featureFlags) || {}; });
};
export const useBrandColors = () => {
    return useSelector(state => { var _a; return ((_a = state.loginReducer) === null || _a === void 0 ? void 0 : _a.brandColors) || []; });
};
export const useLoadEvent = () => {
    return useSelector(state => { var _a; return (_a = state.loginReducer) === null || _a === void 0 ? void 0 : _a.loadEvent; });
};
export const useSetLoadEvent = () => {
    const dispatch = useDispatch();
    return (loadEvent) => dispatch({
        type: SET_LOAD_EVENT,
        loadEvent,
    });
};
export const useUpdateBrandColors = () => {
    const dispatch = useDispatch();
    return (brandColors) => dispatch({
        type: ActionType.UPDATE_BRAND_COLORS,
        brandColors: brandColors,
    });
};
export const useHtmlMetadata = () => {
    return useSelector(state => { var _a, _b; return (_b = (_a = state.tourialRootReducer) === null || _a === void 0 ? void 0 : _a.tourial) === null || _b === void 0 ? void 0 : _b.htmlMetadata; });
};
export const useIsPublished = () => {
    return useSelector(state => { var _a, _b; return (_b = (_a = state.tourialRootReducer) === null || _a === void 0 ? void 0 : _a.tourial) === null || _b === void 0 ? void 0 : _b.isPublished; });
};
export const useTourialStatus = () => {
    return useSelector(state => {
        var _a, _b, _c, _d, _e, _f;
        if ((_b = (_a = state.tourialRootReducer) === null || _a === void 0 ? void 0 : _a.tourial) === null || _b === void 0 ? void 0 : _b.isPublished)
            return TourialStatus.LIVE;
        if ((_d = (_c = state.tourialRootReducer) === null || _c === void 0 ? void 0 : _c.tourial) === null || _d === void 0 ? void 0 : _d.archivedAt)
            return TourialStatus.ARCHIVED;
        if ((_f = (_e = state.tourialRootReducer) === null || _e === void 0 ? void 0 : _e.tourial) === null || _f === void 0 ? void 0 : _f.interactedAt)
            return TourialStatus.UNPUBLISHED;
        return TourialStatus.DRAFT;
    });
};
export const useSetIsPublished = () => {
    const dispatch = useDispatch();
    return (isPublished) => {
        dispatch({
            type: ActionType.SET_IS_PUBLISHED,
            isPublished,
        });
    };
};
export const useRevertToPublishedTour = () => {
    const dispatch = useDispatch();
    return () => {
        dispatch({
            type: ActionType.REVERT_TO_PUBLISHED_TOUR,
        });
    };
};
export const useSetInitialTourialRootState = () => {
    const dispatch = useDispatch();
    return (state) => {
        dispatch({
            type: ActionType.SET_INITIAL_TOURIAL_ROOT_STATE,
            initialProviderState: state,
        });
    };
};
export const useSavedTourialId = () => {
    return useSelector(state => { var _a; return (_a = state.tourialRootReducer.savedTourial) === null || _a === void 0 ? void 0 : _a._id; });
};
export const useSetTourialName = () => {
    const dispatch = useDispatch();
    return (name) => {
        dispatch({
            type: ActionType.SET_TOURIAL_NAME,
            name,
        });
    };
};
export const useSetIsTransitioning = () => {
    const dispatch = useDispatch();
    return (isTransitioning) => {
        dispatch({
            type: ActionType.SET_IS_TRANSITIONING,
            isTransitioning,
        });
    };
};
export const useChangeStep = () => {
    const dispatch = useDispatch();
    return (nextStep) => {
        dispatch({
            type: ActionType.CHANGE_STEP,
            nextStep,
        });
    };
};
export const useSetShouldAutoStart = () => {
    const dispatch = useDispatch();
    return (shouldAutoStart) => {
        dispatch({
            type: ActionType.SET_SHOULD_AUTO_START,
            shouldAutoStart: shouldAutoStart,
        });
    };
};
export const useShouldAutoStart = () => useSelector(state => state.tourialRootReducer.viewport.shouldAutoStart);
export const useSetInitialTransitionAndAutoStartState = () => {
    const dispatch = useDispatch();
    return () => {
        dispatch({
            type: ActionType.SET_INITIAL_TRANSITION_AND_AUTO_START_STATE,
        });
    };
};
export const useHiddenTools = () => useSelector(state => state.tourialRootReducer.viewport.hiddenTools);
export const useHideTools = () => {
    const dispatch = useDispatch();
    return (toolIds) => {
        dispatch({
            type: ActionType.HIDE_TOOLS,
            hiddenToolIds: toolIds,
        });
    };
};
export const useShowTools = () => {
    const dispatch = useDispatch();
    return (toolIds) => {
        dispatch({
            type: ActionType.SHOW_TOOLS,
            hiddenToolIds: toolIds,
        });
    };
};
