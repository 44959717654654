import React, { useState } from "react";
import styled from "styled-components";
import { useActiveView, usePushHistory } from "../../../../redux/selectors/redux-selectors";
import { useDeselect, useGotoNextStep, useGotoPreviousStep } from "../../../../redux/selectors/builder-selectors";
import ArrowLeftIcon from "../../../../../assets/images/v3/arrow-left.svg";
import ArrowRightIcon from "../../../../../assets/images/v3/arrow-right.svg";
import { useIsFirstStep } from "../../../../hooks/use-is-first-step";
import { useIsLastStep } from "../../../../hooks/use-is-last-step";
import { newTheme } from "../../../styled/theme";
import { StubPanelButton, StubPanelIcon } from "./storyboard-mini-stub";
export function StoryboardMiniStubStepPanel() {
    const activeView = useActiveView();
    const goToPreviousStep = useGotoPreviousStep();
    const goToNextStep = useGotoNextStep();
    const pushHistory = usePushHistory();
    const deselect = useDeselect();
    const [isFirstStep, setIsFirstStep] = useState(true);
    const [isLastStep, setIsLastStep] = useState(false);
    useIsFirstStep(setIsFirstStep);
    useIsLastStep(setIsLastStep);
    return (React.createElement(CurrentStepPanelContainer, { id: "_test_current_step_panel" },
        React.createElement(StubPanelButton, { id: "_test_goto-previous-step-button", onClick: () => {
                pushHistory();
                deselect();
                goToPreviousStep();
            }, disabled: isFirstStep },
            React.createElement(StubPanelIcon, { src: ArrowLeftIcon })),
        React.createElement(Span, null, `${activeView.pageName} / ${activeView.zoomName}`),
        React.createElement(StubPanelButton, { id: "_test_goto-next-step-button", onClick: () => {
                pushHistory();
                deselect();
                goToNextStep();
            }, disabled: isLastStep },
            React.createElement(StubPanelIcon, { src: ArrowRightIcon }))));
}
const CurrentStepPanelContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  height: 100%;
  min-width: 280px;
  gap: 10px;
`;
const Span = styled.h4 `
  font: ${newTheme.fonts.small};
  text-align: center;
  user-select: none;
`;
