var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useRef, useState } from "react";
import { WrapperChild } from "../../../renderer/wrapper/wrapper-child";
import { useTourialId, useViewportIsTransitioning, useViewportMode } from "../../../../redux/selectors/redux-selectors";
import styled from "styled-components";
import { GetField } from "./fields/get-field";
import { Description, FormContainer, FormFieldContainer, FormTab, FormWrapper, Headline, InnerWrapper, Logo, LogoContainer, SubmitButton, SubmitButtonContainer, } from "./subcomponents/styled-main-view";
import { newTheme } from "../../../styled/theme";
import { TourialApiCalls } from "../../../../helpers/tourial-api-calls";
import { FormV3CloseButton } from "./subcomponents/form-v3-close-button";
import { FormConfirmationView } from "./form-v3-confirmation-view";
import { useHiddenFieldData, useHiddenFieldDataDT, useIntegrationsCookies } from "../../../../hooks/form-hooks";
import { formHasMoreThanSixVisibleFields, initLocalStorageFormState, setFormWasDismissed, setFormWasSubmitted, wasFormSubmitted, } from "../../../../helpers/form-helpers";
import { useInteraction } from "../../../../redux/selectors/interaction-selectors";
import { InteractionName, InteractionType, makeInteraction, } from "../../../../../../shared/types/tool-data-types/interactions";
import { ToolType } from "../../../../../../shared/types/tool-data-types/common";
import { ViewportMode } from "../../../../types/definitions";
import { usePageForm, useSetPageForm } from "../../../../redux/selectors/product-page-selectors";
import { TLoadingSpinner } from "../../../styled/t-loading-spinner";
import { useRefSize } from "../../../../hooks/viewport-hooks";
import { usePostDCEvent, usePostEvent } from "../../../../helpers/events";
import { TourialEvent } from "../../../../../../shared/types/events";
import { useMicrositeSessionId } from "../../../../redux/selectors/microsite-selectors";
import { useDCConfigGatingFormID, useDCSessionId } from "../../../../redux/selectors/demo-center-selectors";
import { useHTGetTourId, useHTPostEvent } from "../../../pages/dom-tour/hooks/ht-hooks";
import { useHTMode, useHTPageForm, useHTSetPageForm } from "../../../../redux/selectors/ht-selectors";
import { useDCGoToSelfDemo } from "../../../pages/demo-center/hooks/dc-navigation-hooks";
export function FormV3TourView(props) {
    const isViewportTransitioning = useViewportIsTransitioning();
    const [isHidden, setIsHidden] = useState(isViewportTransitioning);
    const [isReallyHidden, setIsReallyHidden] = useState(false);
    const { isEnabled, id } = props.toolData;
    useEffect(() => {
        if (isViewportTransitioning) {
            setIsHidden(true);
        }
        else {
            setIsHidden(false);
        }
    }, [isViewportTransitioning, id, isEnabled]);
    if (isHidden || isReallyHidden)
        return null;
    return (React.createElement(FormViewFullscreenContainer, null,
        React.createElement(TourialFormWrapper, Object.assign({}, Object.assign(Object.assign({}, props), { setIsHidden: setIsReallyHidden })))));
}
export function FormV3PageView(props) {
    const isViewportTransitioning = useViewportIsTransitioning();
    const [isHidden, setIsHidden] = useState(isViewportTransitioning);
    if (isHidden)
        return null;
    return (React.createElement(FormOverlay, null,
        React.createElement(FormViewFullscreenContainer, null,
            React.createElement(PageFormWrapper, Object.assign({}, Object.assign(Object.assign({}, props), { setIsHidden }))))));
}
export function FormV3PageViewDT(props) {
    const [isHidden, setIsHidden] = useState(false);
    const tourialId = useHTGetTourId();
    const viewportMode = useHTMode();
    const hiddenFieldData = useHiddenFieldDataDT(); // TODO: requires load event
    const htPostEvent = useHTPostEvent();
    if (isHidden)
        return null;
    return (React.createElement(FormOverlay, null,
        React.createElement(FormViewFullscreenContainer, null,
            React.createElement(InnerForm, Object.assign({}, Object.assign({}, Object.assign(Object.assign({}, props), { setIsHidden, viewportMode: viewportMode, tourialId,
                hiddenFieldData, postEvent: () => {
                    return new Promise(resolve => resolve());
                }, postSubmitEvent: () => {
                    void htPostEvent({
                        eventType: "FORM_SUBMIT",
                        formId: props.toolData.id,
                    });
                } })))))));
}
function InnerForm(props) {
    const { viewportMode, tourialId, hiddenFieldData, postSubmitEvent, postEvent, postCloseFormEvent, postOpenFormOnStepEnter, } = props;
    const formWrapperRef = useRef(null);
    const integrationsCookies = useIntegrationsCookies();
    const pageForm = usePageForm();
    const setPageForm = useSetPageForm();
    const DTPageForm = useHTPageForm();
    const DCConfigGatingFormId = useDCConfigGatingFormID();
    const setDTPageForm = useHTSetPageForm();
    const { setIsHidden, isPageForm, viewportDimensions } = props;
    const { layout, theme, text, fields, logo, isGated, enableConfirmation, id, chiliPiper, captchaEnabled, honeyPotEnabled, optIn, } = props.toolData;
    const { headerText, bodyText, buttonText, labelText } = text;
    const { backgroundTheme, buttonTheme, inputsTheme } = theme;
    const { width, height } = useRefSize(formWrapperRef);
    const [formState, setFormState] = useState(fields.map(f => ({ role: f.role, value: f.value, isValid: true })));
    const [isSubmitAwaiting, setIsSubmitAwaiting] = useState(false);
    const [isSubmitErrored, setIsSubmitErrored] = useState(false);
    const [formScale, setFormScale] = useState(1);
    const dCGoToSelfDemo = useDCGoToSelfDemo();
    const [tab, setTab] = useState(FormTab.MAIN);
    useEffect(() => {
        initLocalStorageFormState(id);
        if (!isPageForm) {
            postOpenFormOnStepEnter();
        }
        if (pageForm === null || pageForm === void 0 ? void 0 : pageForm.isCTA) {
            return;
        }
        if (wasFormSubmitted(id)) {
            if (isGated) {
                // if gated, and previously Submitted, don't show thanks => THis should probably just check if it was triggered by a CTA
                setIsHidden(true);
            }
            else if (enableConfirmation) {
                setTab(FormTab.CONFIRMATION);
            }
            else {
                setIsHidden(true);
            }
        }
    }, []);
    useEffect(() => {
        let scale = 1;
        if (width > (viewportDimensions === null || viewportDimensions === void 0 ? void 0 : viewportDimensions.width)) {
            scale = (viewportDimensions === null || viewportDimensions === void 0 ? void 0 : viewportDimensions.width) / width;
        }
        if (height > (viewportDimensions === null || viewportDimensions === void 0 ? void 0 : viewportDimensions.height)) {
            scale = (viewportDimensions === null || viewportDimensions === void 0 ? void 0 : viewportDimensions.height) / height;
        }
        setFormScale(scale);
    }, [viewportDimensions, width, height]);
    const onClose = () => {
        if (props.disableInteractions)
            return;
        if (tab === FormTab.MAIN) {
            setFormWasDismissed(id, true);
        }
        postCloseFormEvent === null || postCloseFormEvent === void 0 ? void 0 : postCloseFormEvent();
        setIsHidden(true);
        if (pageForm) {
            setPageForm(null);
        }
        if (DTPageForm) {
            setDTPageForm(null);
        }
        if (DCConfigGatingFormId === id && !wasFormSubmitted(id)) {
            if (!window.location.pathname.includes("gallery")) {
                dCGoToSelfDemo(); // Go to DC Welcome if Gating Form is closed without submitting
            }
        }
    };
    const canSubmit = (formState, fields) => {
        if (isSubmitAwaiting)
            return false;
        let hasAnyData = false;
        for (const fs of formState) {
            if (!fs.isValid)
                return false;
            if (fs.value) {
                hasAnyData = true;
            }
        }
        if (!hasAnyData)
            return false;
        for (let i = 0; i < fields.length; i++) {
            if (fields[i].isRequired && !formState[i].value)
                return false;
        }
        return true;
    };
    const onSubmit = () => {
        if (props.disableInteractions)
            return;
        if (viewportMode === ViewportMode.PREVIEW) {
            if (enableConfirmation) {
                setTab(FormTab.CONFIRMATION);
            }
            else {
                setIsHidden(true);
            }
            return;
        }
        const submission = {
            tourialId,
            formId: id,
            fields: formState.map(f => ({ role: f.role, value: f.value })),
            hiddenFields: hiddenFieldData,
            integrations: integrationsCookies,
        };
        setIsSubmitAwaiting(true);
        if (chiliPiper === null || chiliPiper === void 0 ? void 0 : chiliPiper.domain) {
            const lead = formState.reduce((l, kv) => {
                l[kv.role] = kv.value;
                return l;
            }, {});
            // @ts-expect-error
            ChiliPiper.submit(chiliPiper.domain, chiliPiper.selectedRouter, {
                map: true,
                lead,
                onSuccess: () => {
                    void postEvent({
                        eventType: TourialEvent.SCHEDULING_EVENT_SCHEDULED,
                        integrationName: "CHILIPIPER",
                    });
                },
            });
        }
        if (captchaEnabled) {
            // @ts-expect-error -> loaded into global scope in <head>
            grecaptcha.enterprise.ready(() => __awaiter(this, void 0, void 0, function* () {
                // @ts-expect-error
                submission.captchaToken = yield grecaptcha.enterprise.execute("6LdSNAsqAAAAACw4vc863CXcGsh4SKNaz0eabNoq", {
                    action: "LOGIN",
                });
                void submit();
            }));
        }
        else {
            void submit();
        }
        function submit() {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    const r = yield TourialApiCalls.Forms.submitForm(submission);
                    console.log("response from submitForm", r);
                    postSubmitEvent();
                    setIsSubmitAwaiting(false);
                    setFormWasSubmitted(id, true);
                    if (enableConfirmation) {
                        setTab(FormTab.CONFIRMATION);
                    }
                    else {
                        setIsHidden(true);
                    }
                }
                catch (e) {
                    setIsSubmitAwaiting(false);
                    setIsSubmitErrored(true);
                    console.error("error from submitForm", e);
                }
            });
        }
    };
    if (isSubmitAwaiting) {
        return React.createElement(TLoadingSpinner, null);
    }
    else if (isSubmitErrored) {
        console.error("failed to submit form");
    }
    return (React.createElement(FormWrapper, { theme: backgroundTheme, layout: layout, tab: tab, twoColumn: formHasMoreThanSixVisibleFields(fields), ref: formWrapperRef, style: { transform: `scale(${formScale})`, zIndex: 100 } },
        (!isGated || tab === FormTab.CONFIRMATION) && React.createElement(FormV3CloseButton, { onClick: onClose }),
        tab === FormTab.MAIN && (React.createElement(InnerWrapper, { layout: layout },
            React.createElement(LogoContainer, { layout: layout },
                logo.src && (React.createElement(Logo, { shape: logo.shape, size: logo.size },
                    React.createElement("img", { src: logo.src, alt: "logo" }))),
                React.createElement(Headline, { font: headerText.font, textStyle: headerText.style, align: headerText.align, size: headerText.size, color: headerText.color }, headerText.text),
                React.createElement(Description, { font: bodyText.font, textStyle: bodyText.style, align: bodyText.align, size: bodyText.size, color: bodyText.color }, bodyText.text)),
            React.createElement(FormContainer, { layout: layout },
                React.createElement("form", { onSubmit: e => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (canSubmit(formState, fields)) {
                            onSubmit();
                        }
                    } },
                    React.createElement(FormFieldContainer, { twoColumn: formHasMoreThanSixVisibleFields(fields) }, fields.map((f, i) => GetField({
                        field: f,
                        fieldIndex: i,
                        theme: inputsTheme,
                        labelText,
                        formState,
                        setFormState,
                    }))),
                    honeyPotEnabled && React.createElement("input", { type: "text", name: "_tourial_new_question", style: { display: "none" } }),
                    (optIn === null || optIn === void 0 ? void 0 : optIn.enabled) && (optIn === null || optIn === void 0 ? void 0 : optIn.location) === "above" && (React.createElement(OptInText, { dangerouslySetInnerHTML: { __html: optIn.text } })),
                    React.createElement(SubmitButtonContainer, { align: buttonText.align },
                        React.createElement(SubmitButton, { theme: buttonTheme, color: buttonText.color, font: buttonText.font, textStyle: buttonText.style, size: buttonText.size, disabled: !canSubmit(formState, fields), style: canSubmit(formState, fields) ? {} : { cursor: "not-allowed" }, type: "submit" }, buttonText.text)),
                    (optIn === null || optIn === void 0 ? void 0 : optIn.enabled) && (optIn === null || optIn === void 0 ? void 0 : optIn.location) === "below" && (React.createElement(OptInText, { dangerouslySetInnerHTML: { __html: optIn.text } })))))),
        tab === FormTab.CONFIRMATION && React.createElement(FormConfirmationView, Object.assign({}, props))));
}
function TourialFormWrapper(props) {
    const viewportMode = useViewportMode();
    const tourialId = useTourialId();
    const hiddenFieldData = useHiddenFieldData();
    const postEvent = usePostEvent();
    const baseTarget = {
        targetIsAForm: true,
        targetFormId: props.toolData.id,
        targetToolType: ToolType.FormV3,
        targetToolIsVisibleAfterTrigger: false,
    };
    const postSubmitEvent = useInteraction(props, makeInteraction(InteractionType.FORM_SUBMIT, InteractionName.SUBMIT_FORM, baseTarget));
    const postCloseFormEvent = useInteraction(props, makeInteraction(InteractionType.CLICK, InteractionName.CLOSE_FORM, baseTarget));
    const postOpenFormOnStepEnter = useInteraction(props, makeInteraction(InteractionType.STEP_ENTER, InteractionName.OPEN_FORM, Object.assign(Object.assign({}, baseTarget), { targetToolIsVisibleAfterTrigger: true })));
    return (React.createElement(InnerForm, Object.assign({}, Object.assign(Object.assign({}, props), {
        viewportMode,
        tourialId,
        hiddenFieldData,
        postEvent,
        postSubmitEvent,
        postCloseFormEvent,
        postOpenFormOnStepEnter,
    }))));
}
function PageFormWrapper(props) {
    const viewportMode = useViewportMode();
    const dcSessionId = useDCSessionId();
    const msSessionId = useMicrositeSessionId();
    const tourialId = useTourialId();
    const hiddenFieldData = useHiddenFieldData();
    const postEvent = usePostEvent();
    const postDCEvent = usePostDCEvent();
    const postSubmitEvent = () => {
        if (dcSessionId) {
            void postDCEvent({
                eventType: TourialEvent.DEMOCENTER_FORM_SUBMIT,
            });
        }
        else {
            void postEvent({
                eventType: msSessionId ? TourialEvent.MICROSITE_FORM_SUBMIT : TourialEvent.FORM_SUBMIT,
            });
        }
    };
    return (React.createElement(InnerForm, Object.assign({}, Object.assign(Object.assign({}, props), {
        viewportMode,
        tourialId,
        hiddenFieldData,
        postEvent,
        postSubmitEvent,
    }))));
}
export const FormViewFullscreenContainer = styled(WrapperChild) `
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${newTheme.animations.fadeIn} 0.2s ease-in;
`;
const FormOverlay = styled.div `
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
`;
export const OptInText = styled.div `
  color: ${newTheme.colors.grey900};
  font-family: Lato;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
