import styled from "styled-components";
import { DTStepType } from "../../../../../../../shared/types/dom-tour";
import { useHTStepIndex, useHTGotoStep } from "../../../../../redux/selectors/ht-selectors";
import { newTheme } from "../../../../styled/theme";
import React, { useContext, useEffect, useRef, useState } from "react";
import { StoryboardPageNameInput } from "../../../builder/storyboard-mini/storyboard-page-name-input";
import { EDIT_PANEL_WIDTH, STEP_PANEL_INPUT_HEIGHT } from "../../config";
import VerticalDots from "../../../../../../assets/images/v3/vertical-dots.svg";
import { useOutsideClick } from "../../../../../hooks/use-outside-click";
import DuplicateScreenIcon from "../../../../../../assets/images/v3/dropdown-duplicate-screen.svg";
import DeleteScreenIcon from "../../../../../../assets/images/v3/dropdown-delete-screen.svg";
import AddScreenIcon from "../../../../../../assets/images/v3/plus-sign.svg";
import { Popover2 } from "@blueprintjs/popover2";
import { Option, OptionIcon } from "../../../builder/storyboard-mini/storyboard-mini-screen-group";
import { DropdownContainer } from "../../../home-page/tourial-list";
import { useHTDeleteStep, useHTDuplicateStep } from "../../hooks/ht-hooks";
import { DraftContext } from "../dom-tour-builder-page";
export function HTStepPreview({ data, index, setName, step, setIsAddScreenModalOpen }) {
    const draft = useContext(DraftContext);
    const [isHovered, setIsHovered] = useState(false);
    const [isActionsMenuOpen, setIsActionsMenuOpen] = useState(false);
    const currentStepIndex = useHTStepIndex();
    const gotoStep = useHTGotoStep();
    const deleteStep = useHTDeleteStep();
    const duplicateStep = useHTDuplicateStep();
    const elementId = `screen-group-${index}`;
    const isActive = currentStepIndex === index;
    const actionsMenuRef = useRef();
    useOutsideClick([actionsMenuRef], () => {
        setIsActionsMenuOpen(false);
    });
    useEffect(() => {
        if (isActive) {
            const element = document.getElementById(elementId);
            if (element) {
                if (element.getBoundingClientRect().left < 0 ||
                    element.getBoundingClientRect().right > window.innerWidth - EDIT_PANEL_WIDTH) {
                    element.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
                }
            }
        }
    }, [isActive]);
    return (React.createElement(PreviewContainer, { onClick: handleClick, isActive: isActive, id: elementId, onMouseEnter: () => {
            setIsHovered(true);
        }, onMouseLeave: () => {
            setIsHovered(false);
        } },
        React.createElement(InputContainer, null,
            React.createElement(StoryboardPageNameInput, { name: step.name, setName: name => setName === null || setName === void 0 ? void 0 : setName(name) })),
        React.createElement(Img, { src: data === null || data === void 0 ? void 0 : data.thumbnail }),
        React.createElement(Number, { isActive: isActive }, index + 1),
        ((isHovered && isActive) || isActionsMenuOpen) && (React.createElement(ActionsButton, { ref: actionsMenuRef, onClick: e => {
                e.stopPropagation();
                setIsActionsMenuOpen(!isActionsMenuOpen);
            } },
            React.createElement(Popover2, { minimal: true, isOpen: isActionsMenuOpen, position: "bottom-right", popoverClassName: "tourial-card-dropdown", content: React.createElement(DropdownContainer, null,
                    step.type === DTStepType.TOOLTIP && (React.createElement(Option, { onClick: e => {
                            e.stopPropagation();
                            setIsActionsMenuOpen(false);
                            duplicateStep(draft);
                        } },
                        React.createElement(OptionIcon, { src: DuplicateScreenIcon }),
                        " ",
                        "Duplicate")),
                    step.type !== DTStepType.OUTRO && (React.createElement(Option, { onClick: e => {
                            e.stopPropagation();
                            setIsActionsMenuOpen(false);
                            setIsAddScreenModalOpen === null || setIsAddScreenModalOpen === void 0 ? void 0 : setIsAddScreenModalOpen(true);
                        } },
                        React.createElement(OptionIcon, { src: AddScreenIcon }),
                        " ",
                        "Add screen")),
                    React.createElement(Option, { onClick: e => {
                            e.stopPropagation();
                            setIsActionsMenuOpen(false);
                            deleteStep(draft);
                        } },
                        React.createElement(OptionIcon, { src: DeleteScreenIcon }),
                        " ",
                        "Delete")) },
                React.createElement("img", { style: { minWidth: 24, height: 24, cursor: "pointer" }, src: VerticalDots }))))));
    function handleClick() {
        if (isActive)
            return;
        gotoStep(index);
    }
}
const ActionsButton = styled.div `
  border-radius: 4px;
  border: 1px solid ${newTheme.colors.grey200};
  background: ${newTheme.colors.grey100};
  box-shadow:
    0px 0px 1px 0px rgba(33, 34, 41, 0.4) inset,
    2px 2px 4px 0px rgba(33, 34, 41, 0.05),
    0px 0px 8px 0px rgba(48, 49, 51, 0.1);
  top: 60px;
  right: 7px;
  height: 25px;
  width: 26px;
  position: absolute;
`;
const InputContainer = styled.div `
  background-color: white;
  border-radius: 4px;
  border: 1px solid ${newTheme.colors.grey300};
  padding-left: 4px;
  height: ${STEP_PANEL_INPUT_HEIGHT};
  display: flex;
`;
const PreviewContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${({ isActive }) => (isActive ? newTheme.colors.blue200 : "transparent")};
  padding: 4px;
  border-radius: 4px;
  height: 100%;
  overflow: hidden;
  box-shadow: ${({ isActive }) => isActive ? "4px 4px 12px 0px rgba(48, 49, 51, 0.07)" : "0px 0px 1px 0px rgba(33, 34, 41, 0.4) inset"};
  &:hover {
    background-color: ${({ isActive }) => (isActive ? newTheme.colors.blue200 : newTheme.colors.grey400)};
  }
  flex-direction: column;
  gap: 4px;
`;
const Img = styled.img `
  border-radius: 4px;
  width: 105px;
  height: 59px;
  object-fit: contain;
  background: ${newTheme.colors.grey500};
`;
const Number = styled.div `
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ isActive }) => (isActive ? newTheme.colors.blue500 : newTheme.colors.white)};
  color: ${({ isActive }) => (isActive ? newTheme.colors.white : newTheme.colors.grey600)};
  font: ${newTheme.fonts.smallBold};
  border-radius: 100px;
  width: 25px;
  height: 25px;
  padding: 5px;
  box-shadow: 4px 4px 12px 0px rgba(48, 49, 51, 0.07);
  top: 60px;
  left: 7px;
  border: 1px solid ${({ isActive }) => (isActive ? newTheme.colors.blue500 : newTheme.colors.grey200)};
`;
