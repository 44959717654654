import React from "react";
import { cloneDeep } from "lodash";
import { useUpdateTool } from "../../../../redux/selectors/redux-selectors";
import { withFlexibleToolWrapper } from "../../../renderer/wrapper/with-flexible-tool-wrapper";
import { WrapperChild } from "../../../renderer/wrapper/wrapper-child";
import styled from "styled-components";
import { percentToPx, pxToPercent } from "../../../../helpers/tooltip-v2-size-and-position-helpers";
import { ShirtSize } from "../../../../../../shared/types/tool-data-types/common";
import { MouseShape } from "../../../../../../shared/types/tool-data-types/auto-mouse-data";
import { newTheme } from "../../../styled/theme";
import { useSelectedToolId } from "../../../../redux/selectors/builder-selectors";
var MouseType;
(function (MouseType) {
    MouseType["START"] = "positionStart";
    MouseType["END"] = "positionEnd";
})(MouseType || (MouseType = {}));
export function getPositionInPx(position, viewportSize) {
    return {
        x: percentToPx(position.x, viewportSize.width),
        y: percentToPx(position.y, viewportSize.height),
    };
}
function getLineBetweenTwoPoints(start, end, viewportSize) {
    const endX = percentToPx(end.x, viewportSize.width);
    const endY = percentToPx(end.y, viewportSize.height);
    const startX = percentToPx(start.x, viewportSize.width);
    const startY = percentToPx(start.y, viewportSize.height);
    const xDiff = endX - startX;
    const yDiff = endY - startY;
    const deltaX = Math.pow(xDiff, 2);
    const deltaY = Math.pow(yDiff, 2);
    const distance = Math.sqrt(deltaY + deltaX);
    const radians = Math.atan2(yDiff, xDiff);
    const angle = radians * (180 / Math.PI);
    return { distance, angle };
}
export function AutoMouse(props) {
    const { toolData: tool, viewportDimensions, isInEditViewport } = props;
    const updateTool = useUpdateTool();
    const containerSize = dotContainerSizes[tool.size];
    function drag(position, type) {
        const t = cloneDeep(tool);
        position = {
            x: position.x + pxToPercent(containerSize, viewportDimensions.width) / 2,
            y: position.y + pxToPercent(containerSize, viewportDimensions.height) / 2,
        };
        t[type] = position;
        updateTool(t);
    }
    const positionStart = {
        x: tool.positionStart.x - pxToPercent(containerSize, viewportDimensions.width) / 2,
        y: tool.positionStart.y - pxToPercent(containerSize, viewportDimensions.height) / 2,
    };
    const positionEnd = {
        x: tool.positionEnd.x - pxToPercent(containerSize, viewportDimensions.width) / 2,
        y: tool.positionEnd.y - pxToPercent(containerSize, viewportDimensions.height) / 2,
    };
    const { x, y } = getPositionInPx(tool.positionStart, viewportDimensions);
    const { distance, angle } = getLineBetweenTwoPoints(tool.positionStart, tool.positionEnd, viewportDimensions);
    return (React.createElement(React.Fragment, null,
        withFlexibleToolWrapper(InnerStart, {
            isEditing: props.isInEditViewport,
            isSelectable: true,
            isResizable: false,
            isDraggable: true,
            isAspectRatioLocked: true,
            isStaticSize: true,
            shouldShowSelectionTab: false,
        }, {
            bounds: ".tourial-viewport",
            size: { width: containerSize, height: containerSize },
            position: positionStart,
        }, {
            onDrag: position => drag(position, MouseType.START),
            onDragStop: position => drag(position, MouseType.START),
            onResize: () => { },
            onResizeStop: () => { },
        })(props),
        isInEditViewport && (React.createElement(Connector, { style: { transform: `translate(${x}px,${y}px) rotate(${angle}deg)`, width: distance } })),
        withFlexibleToolWrapper(Inner, {
            isEditing: props.isInEditViewport,
            isSelectable: true,
            isResizable: false,
            isDraggable: true,
            isAspectRatioLocked: true,
            isStaticSize: true,
            shouldShowSelectionTab: false,
        }, {
            bounds: ".tourial-viewport",
            size: { width: containerSize, height: containerSize },
            position: positionEnd,
        }, {
            onDrag: position => drag(position, MouseType.END),
            onDragStop: position => drag(position, MouseType.END),
            onResize: () => { },
            onResizeStop: () => { },
        })(props)));
}
function InnerStart(props) {
    return React.createElement(Inner, Object.assign({ isStart: true }, props));
}
function Inner(props) {
    const { toolData: tool, isInEditViewport, isStart, index } = props;
    const selectedToolId = useSelectedToolId();
    return (React.createElement(Div, { id: isStart ? `${tool.id}-auto-mouse-start` : `${tool.id}-auto-mouse-end`, index: index, isStart: isStart, isInEditViewport: isInEditViewport, showLabel: isInEditViewport && (selectedToolId === "screen" || selectedToolId === tool.id) },
        tool.shape === MouseShape.CIRCLE && (React.createElement(AutoMouseCircle, { opacity: tool.opacity, color: tool.color, borderColor: tool.borderColor, size: tool.size })),
        tool.shape === MouseShape.ARROW && (React.createElement(AutoMouseArrow, { opacity: tool.opacity, color: tool.color, borderColor: tool.borderColor, size: tool.size })),
        tool.shape === MouseShape.POINTER && (React.createElement(AutoMousePointer, { opacity: tool.opacity, color: tool.color, borderColor: tool.borderColor, size: tool.size }))));
}
export const AutoMouseCircle = ({ opacity, color, borderColor, size }) => {
    return (React.createElement(Dot, { style: {
            opacity: opacity,
            border: `2px solid ${borderColor}`,
            backgroundColor: color,
            boxShadow: `0 0 3px 1px ${borderColor}`,
        }, size: size }));
};
export const AutoMouseArrow = ({ opacity, color, borderColor, size }) => {
    return (React.createElement("svg", { width: dotSizes[size], height: dotSizes[size], viewBox: "0 0 17 20", fill: "none", opacity: opacity, xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M8.54728 14.2304L8.03721 13.4614L7.67156 14.3086L5.83911 18.5541C5.55351 19.2158 4.54664 19.107 4.39441 18.4363L0.518543 1.35917C0.448117 1.04887 0.588153 0.77728 0.845131 0.620054C1.10402 0.461659 1.44278 0.44687 1.72724 0.654948L16.2061 11.2463C16.499 11.4606 16.5549 11.771 16.4541 12.0387C16.3522 12.3092 16.091 12.5274 15.7054 12.5114L10.9206 12.3129L9.94156 12.2722L10.4833 13.0888L13.7563 18.0229C13.9683 18.3425 13.8785 18.773 13.5269 18.9881L12.8797 19.3841C12.5167 19.6063 12.0407 19.4967 11.8203 19.1644L8.54728 14.2304Z", fill: color, stroke: borderColor })));
};
export const AutoMousePointer = ({ opacity, color, borderColor, size }) => {
    return (React.createElement("svg", { width: dotSizes[size], height: dotSizes[size], viewBox: "0 0 17 21", fill: "none", opacity: opacity, xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M13.5956 20.2H14.1956V19.6V19.3499C14.1956 18.8804 14.2674 18.4149 14.4074 17.9733L15.6247 14.1332L15.6356 14.099L15.6422 14.0637L16.0853 11.7261C16.1207 11.5396 16.1385 11.3499 16.1385 11.1598V10.446C16.1385 9.84326 15.8294 9.26014 15.2909 8.96362C14.8372 8.71383 14.2969 8.71383 13.8432 8.96362L13.4241 9.1944C13.3675 9.22552 13.315 9.26166 13.2667 9.30198C13.178 9.01674 12.9856 8.76409 12.7124 8.61364L12.4734 8.48208L12.184 9.00767L12.4734 8.48208C11.9588 8.19871 11.3467 8.19871 10.832 8.48208L10.5098 8.65953C10.4532 8.69064 10.4007 8.72678 10.3524 8.7671C10.2637 8.48187 10.0713 8.22921 9.79807 8.07877L9.55912 7.9472C9.04449 7.66384 8.43238 7.66384 7.91774 7.9472L7.555 8.14693C7.49856 8.178 7.4453 8.2137 7.39557 8.2534V3.06975C7.39557 2.46698 7.08649 1.88386 6.54797 1.58734C6.09431 1.33755 5.55397 1.33755 5.1003 1.58734C4.56178 1.88386 4.25271 2.46698 4.25271 3.06975L4.25271 9.39367C4.25271 9.62406 4.23542 9.85396 4.20106 10.081L4.05526 11.0443L3.42178 10.1529C2.90418 9.42463 1.86182 9.57203 1.50876 10.3496C1.36375 10.669 1.36375 11.0403 1.50876 11.3597L1.8778 12.1725L1.87769 12.1725L1.88303 12.1837L2.8537 14.2096L2.85446 14.2112L4.80609 18.3155C4.94221 18.6018 5.04792 18.9055 5.12067 19.2204L5.23953 19.735L5.34693 20.2H5.82414H13.5956Z", fill: color, stroke: borderColor, strokeWidth: "1.2", strokeLinecap: "round" })));
};
const dotContainerSizes = {
    [ShirtSize.SMALL]: 25,
    [ShirtSize.MEDIUM]: 37.5,
    [ShirtSize.LARGE]: 50,
};
const dotSizes = {
    [ShirtSize.SMALL]: "20px",
    [ShirtSize.MEDIUM]: "30px",
    [ShirtSize.LARGE]: "40px",
};
const Dot = styled.div `
  border-radius: 50%;
  width: ${props => dotSizes[props.size]};
  height: ${props => dotSizes[props.size]};
`;
const Div = styled(WrapperChild) `
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  ${p => `
    opacity: ${p.isInEditViewport ? 1 : 0};
    ${p.showLabel
    ? `&:before {
          font: ${newTheme.fonts.small};
          content: "${p.index}:${p.isStart ? "Start" : "End"}";
          position: absolute;
          line-height: 24px;
          padding: 0px 4px;
          font-size: 14px;
          top: -30px;
          background-color: ${newTheme.colors.white};
          border-radius: 4px;
          border: 1px solid ${newTheme.colors.grey300};
        }`
    : ""}
  `}
`;
const Connector = styled.div `
  transform-origin: top left;
  height: 0px;
  border-top: 2px dashed #90939d;
  position: absolute;
  top: 0px;
  left: 0px;
`;
