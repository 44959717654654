import { useDispatch, useSelector } from "react-redux";
import store from "../../legacy/store";
import { ActionType } from "../actions/redux-actions";
import { Direction } from "../../../../shared/types/tool-data-types/common";
import { usePushHistory, useUpdateBuilder } from "./redux-selectors";
import { getActiveVariant, getActiveVariantName, getLiveAndPreviewVisibleToolIds } from "../../helpers/redux";
import { getNextScreen, getNextStep, getPreviousStep } from "../../helpers/tourial-helpers/tourial-viewport-helpers";
import { ViewportMode } from "../../types/definitions";
import { isImageFileExtension } from "../../helpers/media";
import { roundToHundredths } from "../../../../shared/functions/helpers";
import { getVideoDuration } from "../../helpers/video-trimming";
export const useAddTool = () => {
    const dispatch = useDispatch();
    const pushHistory = usePushHistory();
    return (tool) => {
        pushHistory();
        dispatch({ type: ActionType.ADD_TOOL, tool });
    };
};
export const useEditModeVisibleToolIds = () => useSelector(state => {
    const { pageId, zoomId } = state.tourialRootReducer.viewport.activeView;
    const activeVariant = getActiveVariant(state.tourialRootReducer);
    return activeVariant.viewTools
        .filter(vt => vt.pageId === pageId && vt.zoomId === zoomId && vt.toolId)
        .map(vt => vt.toolId);
});
export const useDeselect = () => {
    const updateBuilder = useUpdateBuilder();
    return () => {
        updateBuilder({ hoveredToolId: "", editDetailsId: "", selectedToolId: "" });
    };
};
export const useDeleteSelectedTool = () => {
    const dispatch = useDispatch();
    const pushHistory = usePushHistory();
    return () => {
        const { selectedToolId } = store.getState().tourialRootReducer.builder;
        if (selectedToolId) {
            pushHistory();
            dispatch({ type: ActionType.DELETE_TOOL, toolId: selectedToolId });
        }
    };
};
export const useHandleArrowHotkeysInEditMode = () => {
    const dispatch = useDispatch();
    const pushHistory = usePushHistory();
    const goToPreviousStep = useGotoPreviousStep();
    const goToNextStep = useGotoNextStep();
    return (direction) => {
        const { selectedToolId } = store.getState().tourialRootReducer.builder;
        if (selectedToolId) {
            pushHistory();
            dispatch({ type: ActionType.NUDGE_TOOL, toolId: selectedToolId, direction });
        }
        else {
            if (direction === Direction.LEFT) {
                goToPreviousStep();
            }
            else if (direction === Direction.RIGHT) {
                goToNextStep();
            }
        }
    };
};
export const useIsShownOnLoad = (toolId) => useSelector(state => {
    const activeVariant = getActiveVariant(state.tourialRootReducer);
    const { viewport } = state.tourialRootReducer;
    return getLiveAndPreviewVisibleToolIds(activeVariant, viewport).includes(toolId);
});
export const useDeleteTool = () => {
    const dispatch = useDispatch();
    return (toolId) => {
        dispatch({ type: ActionType.DELETE_TOOL, toolId });
    };
};
export const useIsToolSelected = (toolId) => useSelector(state => { var _a; return ((_a = state.tourialRootReducer.builder) === null || _a === void 0 ? void 0 : _a.selectedToolId) === toolId; });
export const useSelectedToolId = () => useSelector(state => { var _a; return (_a = state.tourialRootReducer.builder) === null || _a === void 0 ? void 0 : _a.selectedToolId; });
export const useSelectTool = () => {
    const dispatch = useDispatch();
    return (toolId) => {
        dispatch({
            type: ActionType.UPDATE_BUILDER,
            newFields: { selectedToolId: toolId },
        });
    };
};
export const useGotoPreviousStep = () => {
    const dispatch = useDispatch();
    return () => {
        const avn = getActiveVariantName(store.getState().tourialRootReducer);
        const { pages } = store.getState().tourialRootReducer.tourial[avn];
        const { activeView } = store.getState().tourialRootReducer.viewport;
        dispatch({
            type: ActionType.CHANGE_STEP,
            nextStep: getPreviousStep(pages, activeView),
        });
    };
};
export const useGotoNextStep = () => {
    const dispatch = useDispatch();
    return () => {
        const avn = getActiveVariantName(store.getState().tourialRootReducer);
        const { pages } = store.getState().tourialRootReducer.tourial[avn];
        const { activeView } = store.getState().tourialRootReducer.viewport;
        dispatch({
            type: ActionType.CHANGE_STEP,
            nextStep: getNextStep(pages, activeView),
        });
    };
};
export const useGotoNextScreen = () => {
    const dispatch = useDispatch();
    return () => {
        const avn = getActiveVariantName(store.getState().tourialRootReducer);
        const { pages } = store.getState().tourialRootReducer.tourial[avn];
        const { activeView } = store.getState().tourialRootReducer.viewport;
        dispatch({
            type: ActionType.CHANGE_STEP,
            nextStep: getNextScreen(pages, activeView),
        });
    };
};
export const useGoToFirstStep = () => {
    const dispatch = useDispatch();
    return () => {
        const avn = getActiveVariantName(store.getState().tourialRootReducer);
        const { pages: [firstPage], } = store.getState().tourialRootReducer.tourial[avn];
        dispatch({
            type: ActionType.CHANGE_STEP,
            nextStep: { pageId: firstPage.id, zoomId: firstPage.zooms[0].id },
        });
    };
};
export const useCopySelectedTool = () => {
    const dispatch = useDispatch();
    return () => {
        dispatch({
            type: ActionType.COPY_SELECTED_TOOL,
        });
    };
};
export const usePasteToolFromCopyBuffer = () => {
    const dispatch = useDispatch();
    const pushHistory = usePushHistory();
    return () => {
        if (store.getState().tourialRootReducer.builder.copyBuffer) {
            pushHistory();
            dispatch({
                type: ActionType.PASTE_TOOL_FROM_COPY_BUFFER_TO_CURRENT_STEP,
            });
        }
    };
};
export const useSetFormOnStep = () => {
    const dispatch = useDispatch();
    return (formId, isGated, isEnabled) => {
        dispatch({
            type: ActionType.SET_FORM_ON_STEP,
            formId,
            isGated,
            isEnabled,
        });
    };
};
export const useFormOnStep = (step) => {
    return useSelector(state => {
        var _a;
        const activeVariant = getActiveVariant(state.tourialRootReducer);
        return (_a = activeVariant === null || activeVariant === void 0 ? void 0 : activeVariant.stepForms) === null || _a === void 0 ? void 0 : _a[makeStepFormInteractionKey(step)];
    });
};
export const useShouldRenderForm = () => {
    return useSelector(state => {
        return state.tourialRootReducer.builder.shouldRenderForm;
    });
};
export const useSetShouldRenderForm = () => {
    const dispatch = useDispatch();
    return (shouldRender) => {
        dispatch({
            type: ActionType.SET_SHOULD_RENDER_FORM,
            shouldRender,
        });
    };
};
export function makeStepFormInteractionKey(step) {
    return `${step.pageId}->${step.zoomId}`;
}
export const useToggleViewportMode = () => {
    const dispatch = useDispatch();
    return () => {
        const { mode } = store.getState().tourialRootReducer.viewport;
        if (mode === ViewportMode.LIVE)
            return console.error("cannot toggle viewport mode from live");
        dispatch({
            type: ActionType.SET_VIEWPORT_MODE,
            mode: mode === ViewportMode.EDIT ? ViewportMode.PREVIEW : ViewportMode.EDIT,
        });
    };
};
export const useScreenDidLoad = () => useSelector(state => state.tourialRootReducer.viewport.screenDidLoad);
export const useSetScreenDidLoad = () => {
    const dispatch = useDispatch();
    return (screenDidLoad) => {
        dispatch({ type: ActionType.SET_SCREEN_DID_LOAD, screenDidLoad });
    };
};
export const useDeletePage = () => {
    const dispatch = useDispatch();
    return (pageId) => dispatch({ type: ActionType.DELETE_PAGE, pageId });
};
export const useDeleteZoom = () => {
    const dispatch = useDispatch();
    return (zoomId) => dispatch({ type: ActionType.DELETE_ZOOM, zoomId });
};
export const useDuplicatePage = () => {
    const dispatch = useDispatch();
    return (pageId) => dispatch({ type: ActionType.DUPLICATE_PAGE, pageId });
};
export const useDuplicateView = () => {
    const dispatch = useDispatch();
    return (pageId, zoomId) => dispatch({ type: ActionType.DUPLICATE_VIEW, pageId, zoomId });
};
export const useTotalScreenDuration = (pageId) => {
    return useSelector(state => {
        const activeVariant = getActiveVariant(state.tourialRootReducer);
        const page = activeVariant.pages.find(p => p.id === pageId);
        const media = state.tourialRootReducer.tourial.media;
        const screenMedia = activeVariant.tools.screen.media;
        const srcUrl = screenMedia[pageId];
        const isImage = isImageFileExtension(srcUrl);
        const videoDuration = getVideoDuration(media, srcUrl);
        if (!isImage && videoDuration)
            return roundToHundredths(videoDuration);
        return roundToHundredths(page.zooms.reduce((totalDuration, zoom) => totalDuration + zoom.automationDuration, 0));
    });
};
export const useIsScreenTypeUnassignedForPage = (page) => {
    return useSelector(state => {
        const activeVariant = getActiveVariant(state.tourialRootReducer);
        const viewTools = activeVariant === null || activeVariant === void 0 ? void 0 : activeVariant.viewTools.filter(tool => tool.pageId === page.id);
        const isAutomated = page.isAutomated;
        const hasNoTools = !viewTools.length;
        const isAutomatedExists = typeof isAutomated === "boolean";
        return hasNoTools && !isAutomatedExists;
    });
};
export const useSetCheckedOutVersion = () => {
    const dispatch = useDispatch();
    return (version) => dispatch({ type: ActionType.CHECK_OUT_VERSION, version });
};
export const useCheckedOutVersion = () => useSelector(state => state.tourialRootReducer.version);
export const useSetCurrentPageName = () => {
    const dispatch = useDispatch();
    return (name) => {
        dispatch({
            type: ActionType.SET_CURRENT_PAGE_NAME,
            name,
        });
    };
};
export const useSetPageNameById = (id) => {
    const dispatch = useDispatch();
    return (name) => {
        dispatch({
            type: ActionType.SET_PAGE_NAME_BY_ID,
            name,
            id,
        });
    };
};
export const useSetCurrentStepName = () => {
    const dispatch = useDispatch();
    return (name) => {
        dispatch({
            type: ActionType.SET_CURRENT_STEP_NAME,
            name,
        });
    };
};
export const useIsMobileButtonDisabled = () => useSelector(state => state.tourialRootReducer.tourial.isMobileButtonDisabled || false);
export const useSetIsMobileButtonDisabled = () => {
    const dispatch = useDispatch();
    return (isDisabled) => {
        dispatch({
            type: ActionType.SET_IS_MOBILE_BUTTON_DISABLED,
            isDisabled,
        });
    };
};
export const useCreateMobileVariantFromDesktop = () => {
    const dispatch = useDispatch();
    return () => {
        dispatch({
            type: ActionType.CREATE_MOBILE_VARIANT_FROM_DESKTOP,
        });
    };
};
export const useSetIsVideoTrimModalOpen = () => {
    const dispatch = useDispatch();
    return (isOpen) => dispatch({ type: ActionType.SET_IS_VIDEO_TRIM_MODAL_OPEN, isOpen });
};
export const useIsVideoTrimModalOpen = () => useSelector(state => state.tourialRootReducer.builder.isVideoTrimModalOpen);
export const useSetScreenMedia = () => {
    const dispatch = useDispatch();
    return (pageId, src) => dispatch({ type: ActionType.SET_SCREEN_MEDIA, pageId, src });
};
export const useSetTrimTimer = () => {
    const dispatch = useDispatch();
    return (src, timer) => dispatch({ type: ActionType.SET_TRIM_TIMER, src, timer });
};
export const useTrimTimers = () => useSelector(state => state.tourialRootReducer.builder.trimTimers);
