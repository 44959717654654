import { useActiveVariantName, usePages, useUpdateTool } from "../../../../../redux/selectors/redux-selectors";
import { EditorAccordion } from "../../../../pages/builder/editor/editor-accordion";
import { cloneDeep } from "lodash";
import { LabelledTextInput } from "../../../../pages/builder/editor/atomic/labelled-text-input";
import React, { useEffect, useRef, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { ArrowPositionSelector } from "../../../../pages/builder/editor/atomic/arrow-position-selector";
import { TooltipArrowPosition, TooltipButtonStyle, TooltipTriggerComponent, } from "../../../../../../../shared/types/tool-data-types/tooltip-v2-data";
import styled from "styled-components";
import { newTheme } from "../../../../styled/theme";
import { getTertiaryInteractionSelectorComponentType, getTertiarySelectorOptions, TriggerInteractionSelector, } from "./trigger-interaction-selector";
import TooltipIcon from "../../../../../../assets/images/v3/editor-accordian-tooltip-icon.svg";
import LogoIcon from "../../../../../../assets/images/v3/editor-accordian-logo-icon.svg";
import HeadlineIcon from "../../../../../../assets/images/v3/editor-accordian-headline-icon.svg";
import BodyIcon from "../../../../../../assets/images/v3/editor-accordian-body-icon.svg";
import NextButtonIcon from "../../../../../../assets/images/v3/editor-accordian-next-button-icon.svg";
import PreviousButtonIcon from "../../../../../../assets/images/v3/editor-accordian-previous-button-icon.svg";
import HotspotIcon from "../../../../../../assets/images/hotspot-icon.svg";
import ClickzoneIcon from "../../../../../../assets/images/clickzone-icon.svg";
import SpotlightIcon from "../../../../../../assets/images/v3/editor-accordian-spotlight-icon.svg";
import StepCounterIcon from "../../../../../../assets/images/v3/step-counter-icon.svg";
import { ShapeSelectorLarge } from "../../../../pages/builder/editor/atomic/shape-selector-large";
import { ColorPicker } from "../../../../pages/builder/editor/atomic/color-picker";
import { TCheckbox } from "../../../../styled/t-checkbox";
import { ShirtSizeSelector } from "../../../../pages/builder/editor/atomic/shirt-size-selector";
import { LogoUploadSection } from "../../../modal/editor/components/logo-upload-section";
import { getBodyFontSizeDropdownOptions, getHeadlineFontSizeDropdownOptions, } from "../../../../../helpers/dropdown-options";
import { FontSelector } from "./font-selector";
import { TDropdownSelect } from "../../../../styled/t-dropdown-select";
import { TextInput } from "../../../../pages/builder/editor/atomic/text-input";
import { ShapeSelector } from "../../../../pages/builder/editor/atomic/shape-selector";
import { InteractionName } from "../../../../../../../shared/types/tool-data-types/interactions";
import { OpacitySlider } from "../../../../pages/builder/editor/atomic/opacity-slider";
import { useFocusTourialEditorField } from "../../../../../hooks/use-focus-field";
import { TourialEditorFocusFields } from "../../../../../types/definitions";
import { THorizontalSlider } from "../../../../styled/t-horizontal-slider";
import { ControlContainer, ControlLabel } from "../../../../styled/editor-components";
import { AlignmentSelector } from "../../../../pages/builder/editor/atomic/alignment-selector";
import { RichTextEditor } from "../../../../../helpers/rich-text-editor";
export function StyleSection(props) {
    var _a, _b;
    const activeVariantName = useActiveVariantName();
    const pages = usePages();
    const { toolData: tool } = props;
    const updateTool = useUpdateTool();
    const [openId, setOpenId] = useState("bubble-accordion");
    useFocusTourialEditorField(setOpenId);
    const reactQuillRef = useRef(null);
    const [stepOptions, setStepOptions] = useState(getTertiarySelectorOptions(tool.bubble.nextButton.interaction.name, pages));
    const [prevStepOptions, setPrevStepOptions] = useState(getTertiarySelectorOptions(tool.bubble.previousButton.interaction.name, pages));
    useEffect(() => {
        setStepOptions(getTertiarySelectorOptions(tool.bubble.nextButton.interaction.name, pages));
    }, [tool.bubble.nextButton.interaction.name]);
    useEffect(() => {
        setPrevStepOptions(getTertiarySelectorOptions(tool.bubble.previousButton.interaction.name, pages));
    }, [tool.bubble.previousButton.interaction.name]);
    return (React.createElement(Section, null,
        React.createElement(EditorAccordion, { id: "bubble-accordion", exclusiveOpenId: openId, setExclusiveOpenId: setOpenId, icon: TooltipIcon, title: "Tooltip", isEnabled: tool.bubble.isEnabled, isInitiallyOpen: true, isHidden: !tool.bubble.isEnabled },
            React.createElement(ShapeSelectorLarge, { shape: tool.bubble.background.shape, onChange: (shape) => {
                    const t = cloneDeep(tool);
                    t.bubble.background.shape = shape;
                    updateTool(t);
                }, disableSquircle: true }),
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Background Color"),
                React.createElement(ColorPicker, { color: tool.bubble.background.backgroundColor, onChange: (color) => {
                        const t = cloneDeep(tool);
                        t.bubble.background.backgroundColor = color;
                        updateTool(t);
                    } })),
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Border Color"),
                React.createElement(ColorPicker, { color: tool.bubble.background.borderColor, onChange: (color) => {
                        const t = cloneDeep(tool);
                        t.bubble.background.borderColor = color;
                        updateTool(t);
                    } })),
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Shadow"),
                React.createElement(TCheckbox, { checked: tool.bubble.background.isShadowEnabled, onChange: (isChecked) => {
                        const t = cloneDeep(tool);
                        t.bubble.background.isShadowEnabled = isChecked;
                        updateTool(t);
                    } })),
            React.createElement(LineSpacer, { style: { marginTop: 8 } }),
            React.createElement(ArrowPositionSelector, { selected: tool.bubble.arrow.position, arrowEnabled: tool.bubble.arrow.isEnabled, onChange: (pos) => {
                    const t = cloneDeep(tool);
                    if (pos === TooltipArrowPosition.NONE) {
                        t.bubble.arrow.isEnabled = false;
                    }
                    else {
                        t.bubble.arrow.isEnabled = true;
                        t.bubble.arrow.position = pos;
                    }
                    updateTool(t);
                } }),
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Arrow Size"),
                React.createElement(ShirtSizeSelector, { size: tool.bubble.arrow.size, onChange: (size) => {
                        const t = cloneDeep(tool);
                        t.bubble.arrow.size = size;
                        updateTool(t);
                    } })),
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Tooltip Width"),
                React.createElement(THorizontalSlider, { min: 208, max: 410, value: tool.bubble.size.width, onChange: e => {
                        const t = cloneDeep(tool);
                        t.bubble.size.width = e;
                        updateTool(t);
                    } })),
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Button Alignment"),
                React.createElement(AlignmentSelector, { align: tool.bubble.buttonAlign, onChange: e => {
                        const t = cloneDeep(tool);
                        t.bubble.buttonAlign = e;
                        updateTool(t);
                    } }))),
        React.createElement(EditorAccordion, { id: "bubble-logo-accordion", exclusiveOpenId: openId, setExclusiveOpenId: setOpenId, icon: LogoIcon, title: "Logo", isEnabled: true, isHidden: !tool.bubble.logo.isEnabled },
            React.createElement(LogoUploadSection, { logo: tool.bubble.logo, updateFn: (e) => {
                    const t = cloneDeep(tool);
                    t.bubble.logo = e;
                    updateTool(t);
                } })),
        tool.bubble.headline.isEnabled && (React.createElement(EditorAccordion, { id: TourialEditorFocusFields.BUBBLE_HEADLINE_TEXT, exclusiveOpenId: openId, setExclusiveOpenId: setOpenId, icon: HeadlineIcon, title: "Headline", isEnabled: tool.bubble.headline.isEnabled, isHidden: !tool.bubble.isEnabled },
            React.createElement(LabelledTextInput, { name: "Headline text", value: tool.bubble.headline.text, style: { marginBottom: 8 }, onChange: (e) => {
                    const t = cloneDeep(tool);
                    t.bubble.headline.text = e.target.value;
                    updateTool(t);
                } }),
            React.createElement(FontSelector, { text: tool.bubble.headline, sizeOptions: getHeadlineFontSizeDropdownOptions(activeVariantName), updateFn: (e) => {
                    const t = cloneDeep(tool);
                    t.bubble.headline = e;
                    updateTool(t);
                } }))),
        tool.bubble.body.isEnabled && (React.createElement(EditorAccordion, { id: TourialEditorFocusFields.BUBBLE_BODY_TEXT, exclusiveOpenId: openId, setExclusiveOpenId: setOpenId, icon: BodyIcon, title: "Body", isEnabled: tool.bubble.body.isEnabled, isHidden: !tool.bubble.isEnabled },
            React.createElement(RichTextEditorContainer, null,
                React.createElement(ControlLabel, null, "Body text"),
                React.createElement(RichTextEditor, { id: tool.id, value: tool.bubble.body.text, onChange: value => {
                        const t = cloneDeep(tool);
                        t.bubble.body.text = value;
                        updateTool(t);
                    }, ref: reactQuillRef })),
            React.createElement(FontSelector, { text: tool.bubble.body, sizeOptions: getBodyFontSizeDropdownOptions(activeVariantName), hideTextStyle: true, updateFn: (e) => {
                    const t = cloneDeep(tool);
                    t.bubble.body = e;
                    updateTool(t);
                } }))),
        React.createElement(EditorAccordion, { id: TourialEditorFocusFields.BUBBLE_NEXT_BUTTON_TEXT, exclusiveOpenId: openId, setExclusiveOpenId: setOpenId, icon: NextButtonIcon, title: "Next Button", isEnabled: true, isHidden: !tool.bubble.nextButton.isEnabled },
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Style"),
                React.createElement(TDropdownSelect, { isSearchable: false, selectedValue: tool.bubble.nextButton.style, style: { width: "178px" }, options: [
                        { value: TooltipButtonStyle.TEXT, label: "Text" },
                        { value: TooltipButtonStyle.TEXT_AND_ARROWS, label: "Text + arrows" },
                        { value: TooltipButtonStyle.ARROWS, label: "Arrows" },
                    ], onChange: (e) => {
                        const t = cloneDeep(tool);
                        t.bubble.nextButton.style = e.value;
                        updateTool(t);
                    } })),
            tool.bubble.nextButton.style !== TooltipButtonStyle.ARROWS && (React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Button Text"),
                React.createElement(TextInput, { value: tool.bubble.nextButton.text, style: { width: "178px" }, onChange: (e) => {
                        const t = cloneDeep(tool);
                        t.bubble.nextButton.text = e.target.value;
                        updateTool(t);
                    } }))),
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "On Click"),
                React.createElement(TDropdownSelect, { isSearchable: false, style: { width: "178px" }, selectedValue: tool.bubble.nextButton.interaction.name, onChange: e => {
                        const t = cloneDeep(tool);
                        t.bubble.nextButton.interaction.name = e.value;
                        updateTool(t);
                    }, options: [
                        { value: InteractionName.NEXT_STEP, label: "Go to next step" },
                        { value: InteractionName.NEXT_PAGE, label: "Go to next page" },
                        { value: InteractionName.JUMP_TO_STEP, label: "Jump to step" },
                        { value: InteractionName.JUMP_TO_PAGE, label: "Jump to page" },
                    ] })),
            getTertiaryInteractionSelectorComponentType(tool.bubble.nextButton.interaction.name) === "selector" && (React.createElement(TDropdownSelect, { isSearchable: true, style: { marginTop: "16px" }, options: stepOptions, selectedValue: (_a = stepOptions.find(so => {
                    var _a, _b, _c, _d;
                    return so.value.pageId === ((_b = (_a = tool.bubble.nextButton.interaction.target) === null || _a === void 0 ? void 0 : _a.targetView) === null || _b === void 0 ? void 0 : _b.pageId) &&
                        so.value.zoomId === ((_d = (_c = tool.bubble.nextButton.interaction.target) === null || _c === void 0 ? void 0 : _c.targetView) === null || _d === void 0 ? void 0 : _d.zoomId);
                })) === null || _a === void 0 ? void 0 : _a.value, onChange: (e) => {
                    const t = cloneDeep(tool);
                    t.bubble.nextButton.interaction.target = { targetView: e.value };
                    updateTool(t);
                } })),
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Shape"),
                React.createElement(ShapeSelector, { shape: tool.bubble.nextButton.shape, onChange: (shape) => {
                        const t = cloneDeep(tool);
                        t.bubble.nextButton.shape = shape;
                        updateTool(t);
                    } })),
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Text Color"),
                React.createElement(ColorPicker, { color: tool.bubble.nextButton.textColor, onChange: (color) => {
                        const t = cloneDeep(tool);
                        t.bubble.nextButton.textColor = color;
                        updateTool(t);
                    } })),
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Background Color"),
                React.createElement(ColorPicker, { color: tool.bubble.nextButton.backgroundColor, onChange: (color) => {
                        const t = cloneDeep(tool);
                        t.bubble.nextButton.backgroundColor = color;
                        updateTool(t);
                    } })),
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Border Color"),
                React.createElement(ColorPicker, { color: tool.bubble.nextButton.borderColor, onChange: (color) => {
                        const t = cloneDeep(tool);
                        t.bubble.nextButton.borderColor = color;
                        updateTool(t);
                    } }))),
        React.createElement(EditorAccordion, { id: TourialEditorFocusFields.BUBBLE_PREVIOUS_BUTTON_TEXT, exclusiveOpenId: openId, setExclusiveOpenId: setOpenId, icon: PreviousButtonIcon, title: "Previous button", isEnabled: true, isHidden: !tool.bubble.previousButton.isEnabled },
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Style"),
                React.createElement(TDropdownSelect, { isSearchable: false, selectedValue: tool.bubble.previousButton.style, options: [
                        { value: TooltipButtonStyle.TEXT, label: "Text" },
                        { value: TooltipButtonStyle.TEXT_AND_ARROWS, label: "Text + arrows" },
                        { value: TooltipButtonStyle.ARROWS, label: "Arrows" },
                    ], onChange: (e) => {
                        const t = cloneDeep(tool);
                        t.bubble.previousButton.style = e.value;
                        updateTool(t);
                    } })),
            tool.bubble.previousButton.style !== TooltipButtonStyle.ARROWS && (React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Button Text"),
                React.createElement(TextInput, { value: tool.bubble.previousButton.text, style: { width: "178px" }, onChange: (e) => {
                        const t = cloneDeep(tool);
                        t.bubble.previousButton.text = e.target.value;
                        updateTool(t);
                    } }))),
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "On Click"),
                React.createElement(TDropdownSelect, { isSearchable: false, style: { width: "178px" }, selectedValue: tool.bubble.previousButton.interaction.name, onChange: e => {
                        const t = cloneDeep(tool);
                        t.bubble.previousButton.interaction.name = e.value;
                        updateTool(t);
                    }, options: [
                        { value: InteractionName.PREVIOUS_STEP, label: "Go to previous step" },
                        { value: InteractionName.PREVIOUS_PAGE, label: "Go to previous page" },
                        { value: InteractionName.JUMP_TO_STEP, label: "Jump to step" },
                        { value: InteractionName.JUMP_TO_PAGE, label: "Jump to page" },
                    ] })),
            getTertiaryInteractionSelectorComponentType(tool.bubble.previousButton.interaction.name) === "selector" && (React.createElement(TDropdownSelect, { isSearchable: true, style: { marginTop: "16px" }, options: prevStepOptions, selectedValue: (_b = prevStepOptions.find(so => {
                    var _a, _b, _c, _d;
                    return so.value.pageId === ((_b = (_a = tool.bubble.previousButton.interaction.target) === null || _a === void 0 ? void 0 : _a.targetView) === null || _b === void 0 ? void 0 : _b.pageId) &&
                        so.value.zoomId === ((_d = (_c = tool.bubble.previousButton.interaction.target) === null || _c === void 0 ? void 0 : _c.targetView) === null || _d === void 0 ? void 0 : _d.zoomId);
                })) === null || _b === void 0 ? void 0 : _b.value, onChange: (e) => {
                    const t = cloneDeep(tool);
                    t.bubble.previousButton.interaction.target = { targetView: e.value };
                    updateTool(t);
                } })),
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Shape"),
                React.createElement(ShapeSelector, { shape: tool.bubble.previousButton.shape, onChange: (shape) => {
                        const t = cloneDeep(tool);
                        t.bubble.previousButton.shape = shape;
                        updateTool(t);
                    } })),
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Text Color"),
                React.createElement(ColorPicker, { color: tool.bubble.previousButton.textColor, onChange: (color) => {
                        const t = cloneDeep(tool);
                        t.bubble.previousButton.textColor = color;
                        updateTool(t);
                    } })),
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Background Color"),
                React.createElement(ColorPicker, { color: tool.bubble.previousButton.backgroundColor, onChange: (color) => {
                        const t = cloneDeep(tool);
                        t.bubble.previousButton.backgroundColor = color;
                        updateTool(t);
                    } })),
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Border Color"),
                React.createElement(ColorPicker, { color: tool.bubble.previousButton.borderColor, onChange: (color) => {
                        const t = cloneDeep(tool);
                        t.bubble.previousButton.borderColor = color;
                        updateTool(t);
                    } })),
            React.createElement(ControlContainer, null,
                React.createElement("span", { style: { font: newTheme.fonts.small } }, "On click the viewer will be taken to the previous step or screen."))),
        React.createElement(EditorAccordion, { id: "bubble-step-counter-accordion", exclusiveOpenId: openId, setExclusiveOpenId: setOpenId, icon: StepCounterIcon, title: "Step counter", isEnabled: true, isHidden: !tool.bubble.stepCounter.isEnabled },
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Text Color"),
                React.createElement(ColorPicker, { color: tool.bubble.stepCounter.color, onChange: (color) => {
                        const t = cloneDeep(tool);
                        t.bubble.stepCounter.color = color;
                        updateTool(t);
                    } }))),
        React.createElement(EditorAccordion, { id: "_test_hotspot-editor-accordion", exclusiveOpenId: openId, setExclusiveOpenId: setOpenId, icon: tool.trigger.triggerComponent === TooltipTriggerComponent.HOTSPOT ? HotspotIcon : ClickzoneIcon, title: tool.trigger.triggerComponent === TooltipTriggerComponent.HOTSPOT ? "Hotspot" : "Clickzone", isEnabled: true, isHidden: tool.trigger.triggerComponent === TooltipTriggerComponent.NONE },
            tool.trigger.triggerComponent !== TooltipTriggerComponent.NONE && React.createElement(TriggerInteractionSelector, { tool: tool }),
            tool.trigger.triggerComponent === TooltipTriggerComponent.HOTSPOT && (React.createElement(React.Fragment, null,
                React.createElement(ControlContainer, null,
                    React.createElement(ControlLabel, null, "Color"),
                    React.createElement(ColorPicker, { color: tool.trigger.hotspot.color, onChange: (color) => {
                            const t = cloneDeep(tool);
                            t.trigger.hotspot.color = color;
                            updateTool(t);
                        } })),
                React.createElement(ControlContainer, null,
                    React.createElement(ControlLabel, null, "Dot Size"),
                    React.createElement(ShirtSizeSelector, { size: tool.trigger.hotspot.size, onChange: (size) => {
                            const t = cloneDeep(tool);
                            t.trigger.hotspot.size = size;
                            updateTool(t);
                        } })))),
            tool.trigger.triggerComponent === TooltipTriggerComponent.CLICKZONE && (React.createElement("div", null,
                React.createElement(ControlContainer, null,
                    React.createElement(ControlLabel, null, "Color"),
                    React.createElement(ColorPicker, { color: tool.trigger.clickzone.color, onChange: (color) => {
                            const t = cloneDeep(tool);
                            t.trigger.clickzone.color = color;
                            updateTool(t);
                        } })),
                React.createElement(ControlContainer, null,
                    React.createElement(ControlLabel, null, "Shape"),
                    React.createElement(ShapeSelector, { shape: tool.trigger.clickzone.shape, onChange: (shape) => {
                            const t = cloneDeep(tool);
                            t.trigger.clickzone.shape = shape;
                            updateTool(t);
                        } })),
                React.createElement(ControlContainer, null,
                    React.createElement(ControlLabel, null, "Border"),
                    React.createElement(TCheckbox, { checked: tool.trigger.clickzone.isBorderEnabled, onChange: (isChecked) => {
                            const t = cloneDeep(tool);
                            t.trigger.clickzone.isBorderEnabled = isChecked;
                            updateTool(t);
                        } })),
                React.createElement(ControlContainer, null,
                    React.createElement(ControlLabel, null, "Pulse Effect"),
                    React.createElement(TCheckbox, { checked: tool.trigger.clickzone.isPulseEnabled === undefined ? true : tool.trigger.clickzone.isPulseEnabled, onChange: (isChecked) => {
                            const t = cloneDeep(tool);
                            t.trigger.clickzone.isPulseEnabled = isChecked;
                            updateTool(t);
                        } }))))),
        React.createElement(EditorAccordion, { id: "bubble-spotlight-accordion", exclusiveOpenId: openId, setExclusiveOpenId: setOpenId, icon: SpotlightIcon, title: "Spotlight style", isEnabled: tool.spotlight.isEnabled, isHidden: !tool.spotlight.isEnabled },
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Shape"),
                React.createElement(ShapeSelector, { shape: tool.spotlight.shape, onChange: (shape) => {
                        const t = cloneDeep(tool);
                        t.spotlight.shape = shape;
                        updateTool(t);
                    } })),
            React.createElement(ControlContainer, null,
                React.createElement(ControlLabel, null, "Opacity"),
                React.createElement(OpacitySlider, { value: tool.spotlight.opacity, onChange: (e) => {
                        const t = cloneDeep(tool);
                        t.spotlight.opacity = e;
                        updateTool(t);
                    } })))));
}
const LineSpacer = styled.div `
  border-top: 1px solid ${newTheme.colors.grey300};
  margin-bottom: 20px;
`;
export const RichTextEditorContainer = styled.div `
  margin-bottom: 8px;
  .ql-toolbar {
    border-radius: 4px 4px 0 0 !important;
    border-color: ${newTheme.colors.grey600} !important;
  }
  .ql-container {
    border-radius: 0 0 4px 4px !important;
    border-color: ${newTheme.colors.grey600} !important;
  }
`;
const Section = styled.div `
  height: calc(100% - 31px);
  overflow: auto;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;
