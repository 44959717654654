import React from "react";
import { Tooltip2 } from "@blueprintjs/popover2";
const TTooltip = (props) => {
    const { children, secondaryContent, content, disabled } = props;
    if (disabled)
        return children;
    if (secondaryContent) {
        return (React.createElement(Tooltip2, Object.assign({}, props, { content: React.createElement(React.Fragment, null,
                React.createElement("div", { style: { fontWeight: "bold", textAlign: "center" } }, content),
                React.createElement("div", { style: { textAlign: "center" } }, secondaryContent)) }), children));
    }
    return (React.createElement(Tooltip2, Object.assign({}, props, { content: React.createElement("span", { className: "_test_helper-tooltip" }, content) }), children));
};
export default TTooltip;
