import React from "react";
import styled from "styled-components";
import { DropdownOption } from "../home-page/tourial-list";
import { useHistory } from "react-router-dom";
import { MainAppPagePaths } from "../../../../../shared/types/app-events";
import { newTheme } from "../../styled/theme";
import { useWorkspaceUserData } from "../../../hooks/use-fetch-current-workspace";
import { useSetWorkspaceId } from "../../../legacy/selectors";
import PublicIcon from "../../../../assets/images/v3/public-workspace-grey.svg";
import PrivateIcon from "../../../../assets/images/v3/private-workspace-grey.svg";
export default function SideBarWorkspaceItem({ workspace, setIsWorkspaceSectionOpen, }) {
    const history = useHistory();
    const { userCount } = useWorkspaceUserData(workspace);
    const setWorkspaceId = useSetWorkspaceId();
    const label = workspace.name;
    return (React.createElement(WorkspaceItem, { key: label, onClick: () => {
            if (window.location.pathname !== MainAppPagePaths.HOMEPAGE) {
                history.push(MainAppPagePaths.HOMEPAGE);
            }
            if (workspace._id) {
                setWorkspaceId(workspace._id);
            }
            else {
                setWorkspaceId(null);
            }
            setIsWorkspaceSectionOpen(false);
        } },
        React.createElement("div", { style: { display: "flex", width: "100%", justifyContent: "start" } },
            React.createElement("span", { className: "text-ellipsis", style: { maxWidth: "85%" } }, label),
            React.createElement("img", { src: workspace.isPublic ? PublicIcon : PrivateIcon, style: { paddingLeft: workspace.isPublic ? 4 : 0 } })),
        React.createElement("span", { style: { font: newTheme.fonts.extraSmall } }, workspace === null || workspace === void 0 ? void 0 :
            workspace.projectCount,
            " projects | ",
            workspace.isPublic ? "All members" : userCount + " members")));
}
const WorkspaceItem = styled(DropdownOption) `
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-left: 37px;
`;
