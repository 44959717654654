import React, { useEffect, useState } from "react";
import TModal from "../../../styled/t-modal";
import { useSetViewportMode, useTourId, useVersionWasModified, useViewportMode, } from "../../../../redux/selectors/redux-selectors";
import { TourialApiCalls } from "../../../../helpers/tourial-api-calls";
import TButton, { TButtonVariants } from "../../../styled/t-button";
import { useCheckedOutVersion, useSetCheckedOutVersion } from "../../../../redux/selectors/builder-selectors";
import styled from "styled-components";
import { newTheme } from "../../../styled/theme";
import { getDateStringWithTime } from "../../../../../../shared/functions/helpers";
import { useAddToast } from "../../../../legacy/selectors";
import { ToastModes, ViewportMode } from "../../../../types/definitions";
import Spinner from "../../../../../assets/images/v3/spinner.svg";
export function VersionsModal({ onClose }) {
    const viewportMode = useViewportMode();
    const setViewportMode = useSetViewportMode();
    const setCheckedOutVersion = useSetCheckedOutVersion();
    const versionWasModified = useVersionWasModified();
    const tourId = useTourId();
    const checkedOutVersion = useCheckedOutVersion();
    const [versions, setVersions] = useState(null);
    const [isErrored, setIsErrored] = useState(false);
    const addToast = useAddToast();
    useEffect(() => {
        TourialApiCalls.Versions.getVersions(tourId)
            .then(r => {
            r.sort((a, b) => (a.savedAt > b.savedAt ? -1 : 1));
            setVersions(r);
        })
            .catch(e => {
            console.error("failed to fetch tourial versions", e);
            setIsErrored(true);
        });
    }, []);
    return (React.createElement(TModal, { title: "Version history", onClose: onClose, style: { width: 388, minWidth: 388 } },
        React.createElement(BodyContainer, null,
            !versions && (React.createElement(LoadingText, null,
                React.createElement("img", { src: Spinner, className: "_test_loading_spinner" }),
                React.createElement("br", null),
                "Fetching versions.")),
            isErrored && (React.createElement(EmptyText, null, "An error occurred. Please try again and contact support if the error persists.")),
            versions && versions.length === 0 && React.createElement(EmptyText, null, "There are no versions available for this Tourial."),
            versions &&
                versions.map(v => {
                    const customDateString = getDateStringWithTime(new Date(v.savedAt).toLocaleString());
                    return (React.createElement(VersionItemContainer, { key: v._id },
                        React.createElement(TimeAndEmail, null,
                            React.createElement("div", null, customDateString),
                            React.createElement(SmallText, null, v.userName)),
                        v._id === (checkedOutVersion === null || checkedOutVersion === void 0 ? void 0 : checkedOutVersion._id) ? (React.createElement(SmallText, { style: { fontStyle: "italic" } }, "Selected")) : (React.createElement(TButton, { size: "small", onClick: () => {
                                setCheckedOutVersion(v);
                                if (viewportMode === ViewportMode.EDIT) {
                                    setViewportMode(ViewportMode.PREVIEW);
                                }
                                addToast({
                                    message: `Viewing version from ${customDateString}`,
                                    mode: ToastModes.SUCCESS,
                                });
                                onClose();
                            }, variant: TButtonVariants.SECONDARY, disabled: versionWasModified }, "View"))));
                }))));
}
const SmallText = styled.div `
  font: ${newTheme.fonts.small};
  color: ${newTheme.colors.grey500};
`;
const EmptyText = styled.div `
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 237px;
`;
const LoadingText = styled.div `
  width: 100%;
  height: 100%;
  text-align: center;
  margin-top: 75px;
`;
const TimeAndEmail = styled.div `
  display: flex;
  flex-direction: column;
`;
const BodyContainer = styled.div `
  padding: 16px;
  width: 100%;
  display: flex;
  gap: 7px;
  height: 269px;
  flex-direction: column;
  font: ${newTheme.fonts.mediumBold};
  overflow: scroll;
`;
const VersionItemContainer = styled.div `
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 3px;
  &:hover {
    background: ${newTheme.colors.grey100};
    border-radius: 4px;
  }
`;
