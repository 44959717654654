var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { SketchPicker } from "react-color";
import { Popover2 } from "@blueprintjs/popover2";
import EyedropperIcon from "../../../../../../assets/images/eye-dropper-icon.svg";
import TransparentBackground from "../../../../../../assets/images/transparent-checker-background.png";
import { newTheme } from "../../../../styled/theme";
import { useBrandColors } from "../../../../../redux/selectors/redux-selectors";
export const ColorPicker = ({ onChange, onOpened, onClosed, color, disabled, style, disableEyedropper, position, }) => {
    const brandColors = useBrandColors();
    const [selectedColor, setSelectedColor] = useState(newTheme.colors.blue);
    const [eyedropperAPIEnabled, setEyedropperAPIEnabled] = useState(false);
    useEffect(() => {
        setSelectedColor(color);
    }, [color]);
    useEffect(() => {
        if ("EyeDropper" in window && !disableEyedropper) {
            setEyedropperAPIEnabled(true);
        }
    }, []);
    const activateEyedropperAPI = () => __awaiter(void 0, void 0, void 0, function* () {
        // @ts-expect-error
        const eyeDropper = new EyeDropper();
        try {
            const { sRGBHex } = yield eyeDropper.open();
            if (sRGBHex) {
                setSelectedColor(sRGBHex);
                onChange(sRGBHex);
            }
        }
        catch (err) {
            console.log("User Cancelled Color Select");
        }
    });
    return (React.createElement(ColorPickerContainer, { style: style },
        React.createElement(Popover2, { minimal: true, disabled: disabled, position: position, portalClassName: "custom-popover-portal", content: React.createElement(SketchPicker, { disableAlpha: true, color: selectedColor, presetColors: [...brandColors, "transparent"], onChange: color => {
                    setSelectedColor(color.hex);
                    onChange(color.hex);
                } }), onOpened: onOpened, onClosed: onClosed },
            React.createElement(ColorPickerButton, { disabled: disabled, style: disableEyedropper ? { borderRadius: "4px" } : null },
                React.createElement(SelectedColorCircle, { color: selectedColor }))),
        eyedropperAPIEnabled && (React.createElement(CustomEyeDropperButton, { disabled: disabled, onClick: () => {
                !disabled && void activateEyedropperAPI();
            } }))));
};
const CustomEyeDropperButton = ({ onClick, disabled }) => {
    return (React.createElement(EyedropperButton, { disabled: disabled, onClick: onClick },
        React.createElement("img", { src: EyedropperIcon })));
};
const ColorPickerContainer = styled.div `
  display: flex;
`;
const SelectedColorCircle = styled.div `
  width: 16px;
  height: 16px;
  border-radius: 16px;
  background-color: ${props => props.color};
  background-image: ${p => (p.color === "transparent" ? `url(${TransparentBackground})` : "none")};
  border: 1px solid ${newTheme.colors.grey300};
  margin: 3px;
`;
const ColorPickerButton = styled.button `
  border-color: ${newTheme.colors.grey600};
  background-color: ${p => (p.disabled ? newTheme.colors.grey200 : newTheme.colors.white)};
  border-style: solid;
  border-width: 1px;
  width: 32px;
  height: 32px;
  padding: 4px;
  border-radius: 4px 0 0 4px;
  cursor: pointer;
  &:hover:not(:disabled) {
    background-color: ${newTheme.colors.grey200};
  }
  &:disabled {
    > div {
      opacity: 0.5;
    }
    cursor: not-allowed;
  }
`;
export const EyedropperButton = styled.button `
  border-color: ${newTheme.colors.grey600};
  background-color: ${p => (p.disabled ? newTheme.colors.grey200 : newTheme.colors.white)};
  border-style: solid;
  border-width: 1px 1px 1px 0;
  width: 32px;
  height: 32px;
  padding: 4px;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  &:hover:not(:disabled) {
    background-color: ${newTheme.colors.grey200};
  }
  &:disabled {
    img {
      opacity: 0.5;
    }
    cursor: not-allowed;
  }
`;
