import { useState } from "react";
import { useAsync } from "react-async";
import { TourialApiCalls } from "../helpers/tourial-api-calls";
import { defaultTheme } from "../../../shared/objects/default-theme";
export const useFetchTheme = () => {
    const [theme, setTheme] = useState(defaultTheme);
    const { error, isLoading } = useAsync({
        promiseFn: TourialApiCalls.Themes.getDefaultTheme,
        onResolve: data => {
            if (data) {
                setTheme(data);
            }
        },
    });
    return { theme, error, isLoading };
};
