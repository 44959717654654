import React from "react";
import styled from "styled-components";
import parse from "html-react-parser";
import { TLoadingSpinner } from "../../../../styled/t-loading-spinner";
import { useHTDimensions, useHTGotoNextStep, useHTIsMobile, useHTMode, useHTScale, useHTStep, useHTTheme, } from "../../../../../redux/selectors/ht-selectors";
import { HTMode } from "../../../../../../../shared/types/dom-tour";
import { isMarkupTextEmpty } from "../../../../../helpers/dom-helpers";
import { useHTFetchCaptureThumbnail, useHTHandleClickEvent } from "../../hooks/ht-hooks";
import { HTModal, ModalHeader, ModalBody, HTButton, BaseOverlay } from "../shared";
import { TourialEditorFocusFields } from "../../../../../types/definitions";
import { useSetBuilderFocusField } from "../../../../../redux/selectors/redux-selectors";
import { MIN_SCALE_WIDTH } from "../../config";
export function Intro() {
    const setBuilderFocusField = useSetBuilderFocusField();
    const gotoNextStep = useHTGotoNextStep();
    const step = useHTStep();
    const theme = useHTTheme();
    const thumbnail = useHTFetchCaptureThumbnail();
    const mode = useHTMode();
    const htHandleClickEvent = useHTHandleClickEvent();
    const { width } = useHTDimensions();
    const scale = useHTScale();
    const isMobile = useHTIsMobile();
    const scaleOffset = isMobile ? Math.max(MIN_SCALE_WIDTH, width * scale) / (width * scale) : 1;
    if (thumbnail.error) {
        console.error("error fetching dom capture thumbnail", thumbnail.error);
        return React.createElement("div", null, thumbnail.error);
    }
    if (!thumbnail.data) {
        return React.createElement(TLoadingSpinner, null);
    }
    return (React.createElement(Root, null,
        React.createElement(BaseOverlay, { theme: theme }),
        React.createElement(HTModal, { style: { transform: `scale(${scaleOffset})` } },
            !!step.intro.header && (React.createElement(ModalHeader, { theme: theme, onDoubleClick: () => setBuilderFocusField(TourialEditorFocusFields.HT_OVERLAY_HEADLINE_TEXT) }, step.intro.header)),
            !isMarkupTextEmpty(step.intro.body) && (React.createElement(ModalBody, { onDoubleClick: () => setBuilderFocusField(TourialEditorFocusFields.HT_OVERLAY_BODY_TEXT), theme: theme }, parse(step.intro.body))),
            React.createElement(HTButton, { onDoubleClick: () => setBuilderFocusField(TourialEditorFocusFields.HT_OVERLAY_BUTTON_1_TEXT), theme: theme, onClick: handleClick }, step.intro.buttonText)),
        React.createElement(Img, { src: thumbnail.data })));
    function handleClick() {
        if (mode === HTMode.EDIT)
            return;
        htHandleClickEvent({ clickAction: "NEXT_STEP" });
        gotoNextStep();
    }
}
const Root = styled.div `
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
export const Img = styled.img `
  width: 100%;
  height: 100%;
  z-index: 2;
  filter: blur(8px);
`;
