import React from "react";
import styled from "styled-components";
import TModal, { TModalButtonsContainer } from "../../../../styled/t-modal";
import TButton, { TButtonVariants } from "../../../../styled/t-button";
import { newTheme } from "../../../../styled/theme";
export const DCConfirmDeleteModal = ({ title, message, confirmText, close, confirm }) => {
    return (React.createElement(TModal, { title: title, onClose: close },
        React.createElement(React.Fragment, null,
            React.createElement(Span, null, message),
            React.createElement(TModalButtonsContainer, null,
                React.createElement(TButton, { variant: TButtonVariants.SECONDARY, onClick: close }, "Cancel"),
                React.createElement(TButton, { variant: TButtonVariants.DANGER, onClick: confirm }, confirmText || "Delete")))));
};
const Span = styled.div `
  padding: 24px;
  font: ${newTheme.fonts.medium};
  color: ${newTheme.colors.grey800};
  width: 100%;
  height: 68px;
`;
