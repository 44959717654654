var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useRef, useState } from "react";
import styled from "styled-components";
import { newTheme } from "../../styled/theme";
import { TourialApiCalls } from "../../../helpers/tourial-api-calls";
import TButton, { TButtonVariants } from "../../styled/t-button";
import { ToastModes } from "../../../types/definitions";
import { useAddToast } from "../../../legacy/selectors";
import { DropdownContainer, DropdownOption } from "../../pages/home-page/tourial-list";
import VerticalDots from "../../../../assets/images/v3/vertical-dots.svg";
import { Popover2 } from "@blueprintjs/popover2";
import RedTrashcanIcon from "../../../../assets/images/v3/red-trashcan.svg";
import PencilIcon from "../../../../assets/images/pencil.svg";
import { useOutsideClick } from "../../../hooks/use-outside-click";
import EditShareLinkModal from "../../pages/home-page/sharelink-edit-modal";
import CreateShareLinkModal from "../../pages/home-page/sharelink-create-modal";
import { IContentType } from "../../../../../shared/types/events";
import DeleteShareLinkModal from "../../pages/home-page/sharelink-delete-modal";
import { useAsync } from "react-async";
import { DC_SHARE_LINK_ROOT } from "../../../../../shared/types/demo-center";
const getShareLinksByDemoCenter = ({ democenterId }) => __awaiter(void 0, void 0, void 0, function* () {
    if (!democenterId)
        return null;
    const data = yield TourialApiCalls.ShareLinks.fetchShareLinksByDemoCenter(democenterId);
    return data;
});
export function DCShareLinkViewTab({ democenterId, baseUrlWithSubdomain }) {
    const addToast = useAddToast();
    const [isCreateShareLinkOpen, setIsCreateShareLinkOpen] = useState(false);
    const [isDeleteShareLinkOpen, setIsDeleteShareLinkOpen] = useState(false);
    const [isEditShareLinkOpen, setIsEditShareLinkOpen] = useState(false);
    const [shareLinkToEdit, setShareLinkToEdit] = useState();
    const [shareLinkToDelete, setShareLinkToDelete] = useState("");
    const [links, setLinks] = useState([]);
    const { reload } = useAsync({
        promiseFn: getShareLinksByDemoCenter,
        democenterId,
        onResolve(data) {
            if (data)
                setLinks(data);
        },
    });
    const deleteShareLink = () => {
        TourialApiCalls.ShareLinks.deleteShareLink(shareLinkToDelete)
            .then(() => {
            setIsDeleteShareLinkOpen(false);
            reload();
        })
            .catch(console.error);
    };
    const copyLink = (linkSlug) => {
        var _a;
        (_a = navigator === null || navigator === void 0 ? void 0 : navigator.clipboard) === null || _a === void 0 ? void 0 : _a.writeText(`${baseUrlWithSubdomain}${DC_SHARE_LINK_ROOT}/${linkSlug}`).then(function () {
            addToast({ message: "Copied link to clipboard!", mode: ToastModes.SUCCESS });
        }, function (err) {
            console.error("Failed to copy text to clipboard: ", err);
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Div, null,
            !!links.length && (React.createElement(LinksContainer, null, links.map(l => {
                return (React.createElement(ShareLinkItem, { key: l._id, l: l, setIsDeleteModalOpen: () => {
                        setShareLinkToDelete(l._id);
                        setIsDeleteShareLinkOpen(true);
                    }, copyLink: copyLink, setIsEditModalOpen: () => {
                        setShareLinkToEdit(l);
                        setIsEditShareLinkOpen(true);
                    } }));
            }))),
            React.createElement(TButton, { size: "medium", variant: TButtonVariants.SECONDARY, style: { width: "100%" }, onClick: () => setIsCreateShareLinkOpen(true) }, "Create new link")),
        isDeleteShareLinkOpen && (React.createElement(DeleteShareLinkModal, { setIsDeleteModalOpen: setIsDeleteShareLinkOpen, onConfirmation: deleteShareLink })),
        isCreateShareLinkOpen && (React.createElement(CreateShareLinkModal, { setIsCreateShareLinkModalOpen: setIsCreateShareLinkOpen, contentType: IContentType.DEMOCENTER, demoCenterId: democenterId, reload: reload })),
        isEditShareLinkOpen && (React.createElement(EditShareLinkModal, { setIsEditShareLinkModalOpen: setIsEditShareLinkOpen, sharelinkData: shareLinkToEdit, reload: reload }))));
}
function ShareLinkItem({ l, copyLink, setIsDeleteModalOpen, setIsEditModalOpen }) {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef();
    useOutsideClick([dropdownRef], () => {
        if (isDropdownOpen) {
            setIsDropdownOpen(false);
        }
    });
    if (!l.isVisible)
        return null;
    return (React.createElement(Row, { key: l.slug },
        React.createElement(Title, { className: "text-ellipsis" },
            l.name,
            l.sfdcAccountName ? ` [${l.sfdcAccountName}` : "",
            l.sfdcOpportunityName ? `, ${l.sfdcOpportunityName}]` : l.sfdcAccountName ? "]" : ""),
        React.createElement(TButton, { size: "medium", variant: TButtonVariants.SECONDARY, style: { width: 52 }, onClick: () => copyLink(l.slug) }, "Copy"),
        React.createElement(Popover2, { minimal: true, isOpen: isDropdownOpen, position: "bottom-right", popoverClassName: "tourial-card-dropdown", content: React.createElement(DropdownContainer, { className: "_test_tourial-card-dropdown", onClick: () => setIsDropdownOpen(!isDropdownOpen), style: { width: 180, marginTop: 0 } },
                React.createElement(DropdownOption, { key: "Edit", onClick: () => {
                        setIsEditModalOpen(l);
                    } },
                    React.createElement("img", { src: PencilIcon, style: { margin: "8px 8px 8px 16px", display: "inline", width: 20 } }),
                    " Edit"),
                React.createElement(DropdownOption, { key: "Delete", style: { color: newTheme.colors.red500 }, onClick: () => {
                        setIsDeleteModalOpen(l._id);
                    } },
                    React.createElement("img", { src: RedTrashcanIcon, style: { margin: "8px 8px 8px 16px", display: "inline" } }),
                    " Delete")) },
            React.createElement("img", { style: {
                    minWidth: 24,
                    height: 24,
                    cursor: "pointer",
                }, src: VerticalDots, ref: dropdownRef, onClick: () => setIsDropdownOpen(!isDropdownOpen), className: "_test_vertical-dots" }))));
}
const Div = styled.div `
  padding: 24px 16px;
  background-color: ${newTheme.colors.grey100};
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;
const LinksContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 10px;
  background-color: white;
  max-height: 405px;
  overflow-y: scroll;
`;
const Row = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  background=color: white;
  gap: 12px;
`;
const Title = styled.div `
  font: ${newTheme.fonts.mediumBold};
  flex: 1;
`;
