import React, { useEffect, useState } from "react";
import MuteOn from "../../../../../../assets/images/v3/mute-on.svg";
import MuteOff from "../../../../../../assets/images/v3/mute-off.svg";
import Play from "../../../../../../assets/images/v3/play.svg";
import Pause from "../../../../../../assets/images/v3/pause.svg";
import styled from "styled-components";
import { THorizontalSlider } from "../../../../styled/t-horizontal-slider";
import { newTheme } from "../../../../styled/theme";
import { roundToHundredths } from "../../../../../../../shared/functions/helpers";
import { ViewportMode } from "../../../../../types/definitions";
import { useViewportMode } from "../../../../../redux/selectors/redux-selectors";
import TButton from "../../../../styled/t-button";
import { useSetIsVideoTrimModalOpen } from "../../../../../redux/selectors/builder-selectors";
export default function VideoControls(props) {
    const { progress, cloudinaryImperfection, isPlaying, isMuted, togglePlay, handleVideoProgress, toggleMute, duration, } = props;
    const setIsVideoTrimModalOpen = useSetIsVideoTrimModalOpen();
    const isEditViewport = useViewportMode() === ViewportMode.EDIT;
    const dotLabel = duration && isEditViewport ? roundToHundredths((progress / 100) * (duration - cloudinaryImperfection)) + "s" : "";
    const [isDragging, setIsDragging] = useState(false);
    const callback = mutationList => {
        for (const mutation of mutationList) {
            if (mutation.attributeName === "class" && mutation.target.className.includes("dragging"))
                setIsDragging(true);
        }
    };
    useEffect(() => {
        if (isDragging) {
            togglePlay(false);
            setIsDragging(false);
        }
    }, [isDragging]);
    useEffect(() => {
        const observer = new MutationObserver(callback);
        const targetNode = document.querySelector(`.video-controls .rc-slider-handle`);
        observer.observe(targetNode, { attributes: true });
        return () => observer.disconnect();
    }, []);
    return (React.createElement(VideoControl, { dotLabel: dotLabel },
        React.createElement(ControlContainer, null,
            isEditViewport && (React.createElement(TrimButton, { onClick: () => {
                    setIsVideoTrimModalOpen(true);
                } }, "Trim")),
            React.createElement(ControlButton, { onClick: () => togglePlay() }, !isPlaying ? React.createElement("img", { src: Play }) : React.createElement("img", { src: Pause })),
            React.createElement(THorizontalSlider, { min: 0, max: 100, value: progress, onChange: n => handleVideoProgress(n), style: { flexGrow: 1 }, className: "video-controls" }),
            React.createElement(ControlButton, { onClick: toggleMute }, !isMuted ? React.createElement("img", { src: MuteOff }) : React.createElement("img", { src: MuteOn })))));
}
const VideoControl = styled.div `
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: end;
  flex-direction: column;
  &:hover > div {
    visibility: visible;
  }
  .rc-slider-handle::before {
    content: "";
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
  }
  .rc-slider-handle-dragging {
    &:after {
      font: ${newTheme.fonts.medium};
      content: "${p => p.dotLabel}";
      background-color: white;
      position: absolute;
      bottom: 35px;
      width: auto;
      padding: 0px 4px;
      border-radius: 4px;
      text-align: center;
      left: -11px;
    }
  }
`;
const ControlContainer = styled.div `
  visibility: hidden;
  height: 40px;
  display: flex;
  align-items: center;
  width: calc(100% - 12px);
  background: rgba(255, 255, 255, 0.35);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  margin: 6px;
  opacity: 0.8;
`;
const ControlButton = styled.button `
  height: 25px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0px 8px;
`;
const TrimButton = styled(TButton) `
  position: absolute;
  top: 0px;
  right: 0px;
  margin: 10px;
`;
