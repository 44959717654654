import { ACTIVATE_CUSTOM_TOUR_LIMIT_BY_ACCOUNT_ID, GET_ALL_ACCOUNTS, GET_ALL_USERS_AND_ACCOUNTS, RENDER_SUPER_ADMIN, RESET_SUPER_ADMIN, SET_PROJECT_ROOM_LINK, UPDATE_CUSTOM_TOUR_LIMIT_BY_ACCOUNT_ID, UPDATE_SUBSCRIPTION_TIER, } from "../../../actions/legacy-action-types";
import axios from "axios";
const initialState = {
    allAccounts: [],
    allUsers: [],
    isLoading: true,
};
export default function (state = initialState, action) {
    switch (action.type) {
        case UPDATE_CUSTOM_TOUR_LIMIT_BY_ACCOUNT_ID: {
            let { limit } = action;
            const { accountId } = action;
            limit = parseFloat(limit);
            let { allAccounts } = state;
            let updatedAccountSubscription;
            for (const account of allAccounts) {
                if (account._id === accountId) {
                    updatedAccountSubscription = account.subscription;
                }
            }
            updatedAccountSubscription = Object.assign(Object.assign({}, updatedAccountSubscription), { customTourLimit: Object.assign(Object.assign({}, updatedAccountSubscription === null || updatedAccountSubscription === void 0 ? void 0 : updatedAccountSubscription.customTourLimit), { limit }) });
            // @ts-expect-error
            allAccounts = allAccounts.map(account => {
                if (account._id === accountId) {
                    return Object.assign(Object.assign({}, account), { subscription: updatedAccountSubscription });
                }
                else {
                    return account;
                }
            });
            const data = {
                updatedAccountSubscription,
                accountId,
            };
            axios
                .post(`/accounts/update/subscriptionById?tourLimit=${limit}`, data)
                .then(() => {
                console.log("updated custom tour limit bool");
            })
                .catch((e) => {
                console.error("error updating account subscription by id", e);
            });
            return Object.assign(Object.assign({}, state), { allAccounts });
        }
        case ACTIVATE_CUSTOM_TOUR_LIMIT_BY_ACCOUNT_ID: {
            const { accountId } = action;
            let { allAccounts } = state;
            let updatedAccountSubscription;
            for (const account of allAccounts) {
                if (account._id === accountId) {
                    updatedAccountSubscription = account.subscription;
                }
            }
            updatedAccountSubscription = Object.assign(Object.assign({}, updatedAccountSubscription), { customTourLimit: Object.assign(Object.assign({}, updatedAccountSubscription === null || updatedAccountSubscription === void 0 ? void 0 : updatedAccountSubscription.customTourLimit), { isActive: !(updatedAccountSubscription === null || updatedAccountSubscription === void 0 ? void 0 : updatedAccountSubscription.customTourLimit.isActive) }) });
            // @ts-expect-error
            allAccounts = allAccounts.map(account => {
                if (account._id === accountId) {
                    return Object.assign(Object.assign({}, account), { subscription: updatedAccountSubscription });
                }
                else {
                    return account;
                }
            });
            const data = {
                updatedAccountSubscription,
                accountId,
            };
            axios
                .post(`/accounts/update/subscriptionById`, data)
                .then(() => {
                console.log("updated custom tour limit bool");
            })
                .catch((e) => {
                console.error("error updating account subscription by id", e);
            });
            return Object.assign(Object.assign({}, state), { allAccounts });
        }
        case UPDATE_SUBSCRIPTION_TIER: {
            const { tier, accountId } = action;
            let { allAccounts } = state;
            let updatedAccountSubscription;
            for (const account of allAccounts) {
                if (account._id === accountId) {
                    updatedAccountSubscription = account.subscription;
                }
            }
            if (updatedAccountSubscription === "essentials") {
                updatedAccountSubscription = {
                    tier,
                    campaignTracking: false,
                    customTourLimit: {
                        isActive: false,
                        limit: 1,
                    },
                };
            }
            else {
                updatedAccountSubscription = Object.assign(Object.assign({}, updatedAccountSubscription), { tier });
            }
            // @ts-expect-error
            allAccounts = allAccounts.map(account => {
                if (account._id === accountId) {
                    return Object.assign(Object.assign({}, account), { subscription: updatedAccountSubscription });
                }
                else {
                    return account;
                }
            });
            const data = {
                updatedAccountSubscription,
                accountId,
            };
            axios.post(`/accounts/update/subscriptionById`, data).catch((e) => {
                console.error("error updating account subscription by id", e);
            });
            return Object.assign(Object.assign({}, state), { allAccounts });
        }
        case GET_ALL_ACCOUNTS: {
            const accounts = action.allAccounts.slice();
            return Object.assign(Object.assign({}, state), { allAccounts: accounts, isLoading: false });
        }
        case GET_ALL_USERS_AND_ACCOUNTS:
            return Object.assign(Object.assign({}, state), { allUsers: action.allUsers, allAccounts: action.allAccounts, isLoading: false });
        case RENDER_SUPER_ADMIN:
            return Object.assign(Object.assign({}, state), { isLoading: false });
        case RESET_SUPER_ADMIN:
            return Object.assign(Object.assign({}, state), { allAccounts: [], allUsers: [], isLoading: true });
        case SET_PROJECT_ROOM_LINK:
            const { projectRoomLink, accountId } = action;
            const data = {
                projectRoomLink,
                accountId,
            };
            axios.post(`/accounts/update/projectRoomLink`, data).catch((e) => {
                console.error("error updating account project room link", e);
            });
            return Object.assign(Object.assign({}, state), { projectRoomLink });
        default:
            return state;
    }
}
