// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js!../../../node_modules/@blueprintjs/popover2/lib/css/blueprint-popover2.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Blueprint Overrides */
.tourial-card-dropdown.bp4-popover2 {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
`, "",{"version":3,"sources":["webpack://./client/src/styles/main-app.css"],"names":[],"mappings":"AAEA,wBAAwB;AACxB;EACE,mCAAmC;EACnC,2BAA2B;AAC7B","sourcesContent":["@import \"~@blueprintjs/popover2/lib/css/blueprint-popover2.css\";\n\n/* Blueprint Overrides */\n.tourial-card-dropdown.bp4-popover2 {\n  -webkit-box-shadow: none !important;\n  box-shadow: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
