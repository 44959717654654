import React, { useEffect, useState } from "react";
import TButton from "../../styled/t-button";
import { TourialApiCalls } from "../../../helpers/tourial-api-calls";
import TModal from "../../styled/t-modal";
import styled from "styled-components";
import { newTheme } from "../../styled/theme";
import TSearchableDropdown from "../../styled/t-searchable-dropdown";
import { alphabetizeArrayByKey } from "../../../helpers/misc";
import { ToastModes } from "../../../types/definitions";
export default function ExternalCloneModal({ originalId, setIsDropdownOpen, setIsExternalCloneModalOpen, reload, addToast, cloneType, }) {
    const [accountId, setAccountId] = useState("");
    const [accounts, setAccounts] = useState(null);
    useEffect(() => {
        TourialApiCalls.Admin.getAllAccounts()
            .then(data => {
            const processedData = alphabetizeArrayByKey(data.map(accountInfo => {
                return {
                    display: accountInfo.companyName,
                    value: `${accountInfo._id}`,
                };
            }), "display");
            setAccounts(processedData);
        })
            .catch((err) => console.log(`Oops! Failed to get all accounts: ${err}`));
    }, []);
    return (React.createElement(TModal, { onClose: () => setIsExternalCloneModalOpen(false), title: "Clone to external account", postfixTitle: "(Super Admin)", postfixTitleStyle: { color: newTheme.colors.purple500 } },
        React.createElement(React.Fragment, null,
            React.createElement(Span, null, "Select an external account to clone this tourial into:"),
            React.createElement(InputContainer, null,
                React.createElement(TSearchableDropdown, { width: 325, list: accounts, parentSetter: setAccountId, selectedValue: accountId }),
                React.createElement(TButton, { disabled: !accountId, onClick: () => {
                        const api = cloneType === "tourial"
                            ? TourialApiCalls.Tourials.postTourialClone
                            : cloneType === "microsite"
                                ? TourialApiCalls.Microsites.copyMicrosite
                                : TourialApiCalls.DomTours.postHTClone;
                        api(originalId, accountId)
                            .then(() => {
                            setIsExternalCloneModalOpen(false);
                            setIsDropdownOpen(false);
                            addToast({ message: "External clone successful", mode: ToastModes.SUCCESS });
                            reload();
                        })
                            .catch(e => {
                            console.error(e);
                            addToast({ message: "External clone failed", mode: ToastModes.ERROR });
                        });
                    } }, "Clone")))));
}
const Span = styled.div `
  padding: 24px 24px 0px 24px;
  font: ${newTheme.fonts.medium};
  color: ${newTheme.colors.grey800};
  width: 100%;
  height: 44px;
`;
const InputContainer = styled.div `
  height: 96px;
  width: 100%;
  font: ${newTheme.fonts.medium};
  display: flex;
  gap: 24px;
  padding: 24px;
  align-items: center;
`;
